import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { changeNoteState } from "../../state/atoms/changeNoteState";

export const useChangeNote = () => {
  const [changeNote, setChangeNote] = useRecoilState(changeNoteState);

  useEffect(() => {
    const changeNote = localStorage.getItem("changeNote");
    if (changeNote) {
      setChangeNote(changeNote);
    }
  }, [setChangeNote]);

  useEffect(() => {
    localStorage.setItem("changeNote", changeNote);
  }, [changeNote]);

  const fetchChangeNote = () => {
    return localStorage.getItem("changeNote") ?? "";
  };

  const updateChangeNote = useCallback(
    (value: string) => {
      if (!value) {
        setChangeNote(" ");
      } else {
        setChangeNote(value);
      }
    },
    [setChangeNote]
  );

  return {
    changeNote,
    fetchChangeNote,
    updateChangeNote,
  };
};
