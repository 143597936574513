import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { ModelStatus, SourceTableRow } from "../../../api/apiClient";
import { useContextMenu } from "../../../hooks/useContextMenu/useContextMenu";
import { GovernanceColumns } from "../../../utils/types/governanceColumns";
import { EditorContextMenu } from "../../form/editorContextMenu/EditorContextMenu";
import { TableRowProps } from "./TableRow.props";

export const TableRow = (props: TableRowProps) => {
  const { index, style, data } = props;
  const ref = useRef<HTMLDivElement>(null);
  const { openContextMenu } = useContextMenu();
  const [highlight, setHighlight] = useState(false);
  const [highlightedRow, setHighlightedRow] = useState<number>();
  const [excludedRow, setExcludedRow] = useState(false);
  const [clickedCellValue, setClickedCellValue] = useState("");
  const [lastClickedColumnId, setLastClickedColumnId] = useState("");
  const [pasteEnabled, setPasteEnabled] = useState(false);
  const timer1Ref: { current: NodeJS.Timeout | null } = useRef(null);
  const timer2Ref: { current: NodeJS.Timeout | null } = useRef(null);
  const timer3Ref: { current: NodeJS.Timeout | null } = useRef(null);
  const timer4Ref: { current: NodeJS.Timeout | null } = useRef(null);

  const row = data.rows[index];
  const callback = data.highlightCallback;
  data.prepareRow(row);

  const classes = classNames(
    "border-gray-600 border-b even:bg-gray-300 ",
    "dark:border-gray-750 dark:bg-dark dark:even:bg-darkTertiary dark:text-gray-600",
    row.isSelected
      ? "bg-blue-700 even:bg-blue-700 dark:bg-blue-900 dark:even:bg-blue-900 bg-opacity-[0.08] even:bg-opacity-[0.08]"
      : null
  );

  useEffect(() => {
    if (
      data.isFromExternal &&
      row.allCells.some(
        (cell) =>
          cell.column.id === GovernanceColumns.ModelStatus &&
          (cell.value === ModelStatus.Deprecate ||
            cell.value === ModelStatus.Withdrawn)
      )
    ) {
      setExcludedRow(true);
    } else {
      setExcludedRow(false);
    }
  }, [data.isFromExternal, row]);

  // useEffect to cleanup unfinished timers
  useEffect(() => {
    return () => {
      if (timer1Ref.current) {
        clearTimeout(timer1Ref.current);
      }

      if (timer2Ref.current) {
        clearTimeout(timer2Ref.current);
      }

      if (timer3Ref.current) {
        clearTimeout(timer3Ref.current);
      }
      if (timer4Ref.current) {
        clearTimeout(timer4Ref.current);
      }
    };
  }, []);

  // checks if current row is a newly added row that should run an animation
  useEffect(() => {
    if (data.newlyAddedRowsIndexes?.includes(row.index)) {
      HighlightRow(row.index);
      callback?.(row.index);
    }
  }, [callback, data.newlyAddedRowsIndexes, row.index]);

  const HighlightRow = (index: number) => {
    timer1Ref.current = setTimeout(() => {
      setHighlight(true);
      setHighlightedRow(index);
    }, 600);
    timer2Ref.current = setTimeout(() => {
      setHighlight(false);
      setHighlightedRow(0);
    }, 900);
    timer3Ref.current = setTimeout(() => {
      setHighlight(true);
      setHighlightedRow(index);
    }, 1200);
    timer4Ref.current = setTimeout(() => {
      setHighlight(false);
      setHighlightedRow(0);
    }, 1500);
  };

  useEffect(() => {
    if (
      data.sourceTableColumns &&
      data.sourceTableColumns.some(
        (col) => col.id === lastClickedColumnId && !col.readOnly
      )
    ) {
      setPasteEnabled(true);
    } else {
      setPasteEnabled(false);
    }
  }, [data.sourceTableColumns, lastClickedColumnId]);

  return (
    <div
      ref={ref}
      {...row.getRowProps({ style })}
      className={classes}
      onContextMenu={(e) => {
        if (!data.enableCustomContextMenu) {
          return null;
        }

        const menuEstimatedWidth = 210;
        const menuEstimatedHeight = 160;

        openContextMenu({
          variant: "tableContext",
          posY:
            e.clientY + menuEstimatedHeight < window.innerHeight
              ? e.clientY + "px"
              : e.clientY - menuEstimatedHeight + "px",
          posX:
            e.clientX + menuEstimatedWidth < window.innerWidth
              ? e.clientX + "px"
              : e.clientX - menuEstimatedWidth + "px",
          menu: (
            <EditorContextMenu
              rowId={row.id}
              rowIdOriginal={(row.original as SourceTableRow).id}
              cellValue={clickedCellValue}
              addRow={(index) => {
                data.addNewRow?.(index);
                HighlightRow(index ?? 0);
              }}
              duplicateRow={(index) => {
                const oldValues = (row.original as SourceTableRow).values;
                const newValues: { [key: string]: string } = {};
                if (oldValues) {
                  Object.keys(oldValues).forEach((key) => {
                    newValues[key] = oldValues[key];
                  });
                }

                data.duplicateRow?.(index, newValues);
                HighlightRow(index ?? 0);
              }}
              deleteRow={() =>
                data.deleteRow?.((row.original as SourceTableRow).id) ?? null
              }
              moveRow={() => data.moveRow?.(row.original)}
              updateIri={() =>
                data.updateIri?.((row.original as SourceTableRow).id)
              }
              pasteCellValue={
                pasteEnabled
                  ? (newValue) => {
                      const rowId = (row.original as SourceTableRow).id;
                      const values = (row.original as SourceTableRow).values;
                      if (values) {
                        values[lastClickedColumnId] = newValue;
                      }

                      const newRow = new SourceTableRow({
                        id: rowId,
                        values: values,
                      });
                      data.updateRow?.(rowId, newRow);
                    }
                  : undefined
              }
            />
          ),
        });
        e.preventDefault();
      }}
    >
      {row.cells.map((cell) => {
        return (
          <div
            className="border-gray-600 dark:border-gray-750 border-r"
            {...cell.getCellProps()}
          >
            <div
              className={`px-2 flex justify-center flex-col h-full  ${
                highlight && highlightedRow === cell.row.index
                  ? "bg-blue-600 bg-opacity-10 dark:bg-opacity-40"
                  : ""
              } ${excludedRow ? "bg-red-600 bg-opacity-10" : ""}`}
              onMouseDown={() => {
                setClickedCellValue(cell.value);
                setLastClickedColumnId(cell.column.id);
              }}
            >
              {cell.render("Cell")}
            </div>
          </div>
        );
      })}
    </div>
  );
};
