import { atom } from "recoil";
import { NotificationVariant } from "../../utils/types/notification";

export interface NotificationState {
  id: number;
  message: string;
  variant?: NotificationVariant;
  created: Date;
  delay: number;
}

export const notificationsState = atom<NotificationState[]>({
  key: "notificationsState",
  default: [],
});
