import { useMsal } from "@azure/msal-react";
import { Logout, Person, Settings } from "@mui/icons-material";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useContextMenu } from "../../../hooks/useContextMenu/useContextMenu";

export const AccountContextMenu = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { closeContextMenu } = useContextMenu();
  const { instance } = useMsal();
  const navigate = useNavigate();

  const itemClass =
    "w-48 p-2 pr-10 font-semibold uppercase flex gap-2 items-center";

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        closeContextMenu();
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [closeContextMenu]);

  return (
    <div
      className="bg-white dark:bg-darkSecondary dark:border dark:border-gray-750 flex flex-col shadow-lg"
      ref={ref}
    >
      <div className="m-2 bg-white">
        <button
          className={`${itemClass} group text-gray-900 dark:bg-darkSecondary dark:text-white hover:text-white hover:bg-gray-700 dark:hover:bg-gray-700`}
        >
          <Person className="text-blue-700 group-hover:text-white" />
          Account
        </button>
        <button
          onClick={() => navigate("settings")}
          className={`${itemClass} group text-gray-900 dark:bg-darkSecondary dark:text-white hover:text-white hover:bg-blue-700 dark:hover:bg-blue-700`}
        >
          <Settings className="text-blue-700 group-hover:text-white" />
          Settings
        </button>
      </div>
      <hr className="border-gray-600 dark:border-gray-750 my-1" />
      <div className="m-2 bg-white">
        <button
          onClick={() =>
            instance.logoutRedirect({
              account: instance.getActiveAccount(),
              postLogoutRedirectUri: "/",
            })
          }
          className={`${itemClass} text-red-700 dark:bg-darkSecondary  hover:text-white hover:bg-red-700 dark:hover:bg-red-700`}
        >
          <Logout />
          Log Out
        </button>
      </div>
    </div>
  );
};
