import { ChevronRight, ExpandMore } from "@mui/icons-material";
import classNames from "classnames";
import { useState } from "react";
import { DropPanelProps } from "./DropPanel.props";

export const DropPanel = (props: DropPanelProps) => {
  const { title, className, onClick } = props;
  const [dropped, setDropped] = useState(false);
  const classes = classNames(
    "mb-2 p-2 border-t border-b border-gray-600 dark:border-gray-750 bg-gray-500 dark:bg-dark dark:text-white cursor-pointer font-bold",
    "flex justify-between items-center",
    className
  );

  return (
    <div
      className={classes}
      onClick={() => {
        setDropped((prev) => !prev);
        onClick?.();
      }}
    >
      {title}
      {dropped ? (
        <ExpandMore data-testid="dp-down" />
      ) : (
        <ChevronRight data-testid="dp-up" />
      )}
    </div>
  );
};
