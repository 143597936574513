// @ts-nocheck
// TODO: update once react-table v8 released

import { ArrowBackIosNew } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { format } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Cell, Column } from "react-table";
import { Snapshot } from "../../api/apiClient";
import { EditorToolbar } from "../../components/editor";
import { Illustration } from "../../components/form/illustration/Illustration";
import { Table } from "../../components/table/Table";
import { EditorReadonlyCell } from "../../components/table/table-cell/editor-readonly-cell/EditorReadonlyCell";
import { Title } from "../../components/title/Title";
import { useDialog } from "../../hooks/useDialog/useDialog";
import { useSnapshots } from "../../hooks/useSnapshots/useSnapshots";
import { useWorkspaces } from "../../hooks/useWorkspaces/useWorkspaces";
import { workspaceState } from "../../state/atoms/workspacesState";
import { useRecoilValueOrDefault } from "../../state/recoil";

export const Snapshots = () => {
  const [selectedRows, setSelectedRows] = useState<Snapshot[]>([]);
  const params = useParams<{ workspaceId: string }>();
  const {
    loading,
    snapshots,
    loadingError: snapshotError,
    loadSnapshots,
    deleteSnapshot,
    deleteSnapshots,
  } = useSnapshots();
  const { openDialog, closeDialog } = useDialog();
  const { setWorkspacesFromApi } = useWorkspaces();
  const workspace = useRecoilValueOrDefault(
    workspaceState(params.workspaceId ?? ""),
    null
  );

  const renderButton = useCallback(
    (id: string | undefined) => {
      return (
        <div className="flex justify-center">
          {loading ? (
            <CircularProgress color="inherit" size="16px" />
          ) : (
            <button
              className="text-red-700 underline"
              onClick={() => {
                if (params.workspaceId && id) {
                  openDialog({
                    variant: "confirm",
                    text: `Do you really want to delete this snapshot`,
                    primaryButtonText: "Delete",
                    secondaryButtonText: "Cancel",
                    onSecondaryButtonClick: () => closeDialog(),
                    onPrimaryButtonClick: () =>
                      deleteSnapshot(params.workspaceId!, id),
                  });
                }
              }}
            >
              Delete
            </button>
          )}
        </div>
      );
    },
    [closeDialog, deleteSnapshot, loading, openDialog, params.workspaceId]
  );

  const columns = useMemo<Column<any>[]>(
    () => [
      {
        Header: "Date",
        width: 200,
        minWidth: 100,
        Cell: (cell: Cell<Snapshot>) => <EditorReadonlyCell cell={cell} />,
        accessor: (x) => format(x.createdTimestamp ?? 0, "dd.MM.yyyy - HH:mm"),
      },
      {
        Header: "Description",
        width: 500,
        minWidth: 200,
        Cell: (cell: Cell<Snapshot>) => <EditorReadonlyCell cell={cell} />,
        accessor: (x) => x.description,
      },
      {
        Header: "Creator",
        width: 200,
        Cell: (cell: Cell<Snapshot>) => <EditorReadonlyCell cell={cell} />,
        accessor: (x) => x.owner,
      },
      {
        id: "build",
        width: 150,
        minWidth: 100,
        Cell: (cell: Cell<Snapshot>) => (
          <EditorReadonlyCell cell={cell} customTitle="" />
        ),
        accessor: (x) => renderButton(x.id),
      },
    ],
    [renderButton]
  );

  const handleDeleteMultiple = () => {
    if (params.workspaceId) {
      const ids = selectedRows.map((row) => row.id);
      deleteSnapshots(params.workspaceId, ids);
    }
  };

  const renderSnapshots = () => {
    if (snapshotError) {
      return (
        <Illustration
          className="h-full"
          variant="error"
          text="Loading Snapshots caused an error to occur"
          onButtonClick={() => loadSnapshots(params.workspaceId ?? "")}
        />
      );
    }

    if (snapshots?.length === 0) {
      return (
        <Illustration
          className="h-full"
          variant="information"
          text="You have no Snapshots listed"
        />
      );
    }

    return (
      <div className="overflow-auto h-full">
        <Table
          rows={snapshots}
          selectable={true}
          onRowSelected={(rows) => setSelectedRows(rows)}
          columns={columns}
        />
      </div>
    );
  };

  useEffect(() => {
    const fetch = async () => {
      await setWorkspacesFromApi();
    };

    fetch();
  }, [setWorkspacesFromApi]);

  useEffect(() => {
    if (params.workspaceId && !snapshots) {
      loadSnapshots(params.workspaceId);
    }
  }, [loadSnapshots, params.workspaceId, snapshots]);

  return (
    <div className="flex h-full flex-1 flex-col">
      <Link to="/workspaces" className=" w-14 ml-4 mt-4 text-blue-700">
        <div className="flex gap-1 items-center">
          <ArrowBackIosNew fontSize="inherit" />
          Back
        </div>
      </Link>
      <Title
        text={`Snapshots - ${workspace?.title}`}
        className="pl-4 pt-2 pb-6"
      />
      {renderSnapshots()}
      <EditorToolbar
        itemsCount={(snapshots ?? []).length}
        itemsSelected={selectedRows.length}
        selectedRows={[]}
        onDelete={() =>
          openDialog({
            variant: "confirm",
            text: `Do you really want to delete ${selectedRows.length} snapshots`,
            primaryButtonText: "Delete",
            secondaryButtonText: "Cancel",
            onSecondaryButtonClick: () => closeDialog(),
            onPrimaryButtonClick: () => handleDeleteMultiple(),
          })
        }
      />
    </div>
  );
};
