import { Person } from "@mui/icons-material";
import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { useRecoilValue } from "recoil";
import { PenToSquareIcon } from "../../assets/images";
import { Button } from "../../components/form/button/Button";
import { Illustration } from "../../components/form/illustration/Illustration";
import { MultiSelectInput } from "../../components/form/inputboxes/multiSelectInput/MultiSelectInput";
import { Loader } from "../../components/loader/Loader";
import { Title } from "../../components/title/Title";
import { WorkspacesList } from "../../components/workspaces-list/WorkspacesList";
import { useModal } from "../../hooks/useModal/useModal";
import { useWorkspaces } from "../../hooks/useWorkspaces/useWorkspaces";
import {
  workspaceOwnerState,
  workspacesState,
} from "../../state/atoms/workspacesState";

export const Workspaces = () => {
  const { openModal } = useModal();
  const [visibleOwners, setVisibleOwners] = useState<string[]>([]);
  const workspaceOwners = useRecoilValue(workspaceOwnerState);
  const workspaces = useRecoilValue(workspacesState);
  const { setWorkspacesFromApi, error, loading } = useWorkspaces();

  useEffect(() => {
    setWorkspacesFromApi();
  }, [setWorkspacesFromApi]);

  useEffect(() => {
    // delay to allow workspaceButtons to fully render before rebuilding tooltip
    setTimeout(() => ReactTooltip.rebuild(), 500);
  }, []);

  if (error) {
    return (
      <Illustration
        className="mt-6"
        variant="error"
        text="Loading Workspaces caused an error to occur"
        onButtonClick={() => setWorkspacesFromApi()}
      />
    );
  }

  return (
    <div className="m-6">
      <div className="flex justify-between">
        <div className="flex gap-4">
          <Title text="Workspaces" />
          <MultiSelectInput
            title="Owner"
            options={workspaceOwners}
            searchText={(x) => x}
            display={(x) => x}
            icon={<Person />}
            onChange={(owners) => setVisibleOwners(owners)}
          />
        </div>
        <span className="float-right">
          <Button
            onClick={() => openModal({ variant: "createWorkspace" })}
            variant="secondary"
            className="group"
          >
            <div className="flex items-center">
              <PenToSquareIcon className="mr-2 text-blue-700 group-hover:text-white" />
              Create New Workspace
            </div>
          </Button>
        </span>
      </div>
      {loading ? (
        <Loader size="10em" />
      ) : (
        <div className="mx-4 mt-4 flex flex-wrap gap-6 justify-start">
          <WorkspacesList
            visibleOwners={visibleOwners}
            workspaces={workspaces}
          />
        </div>
      )}
    </div>
  );
};
