import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { SourceTable } from "../../../api/apiClient";
import { useDialog } from "../../../hooks/useDialog/useDialog";
import { useExternalTables } from "../../../hooks/useExternalTables/useExternalTables";
import { useWorkspaces } from "../../../hooks/useWorkspaces/useWorkspaces";
import { workspacesState } from "../../../state/atoms/workspacesState";
import { Button } from "../../form";
import { SelectInput } from "../../form/inputboxes/selectInput/SelectInput";
import Loader from "../../loader/Loader";
import { RemoveExternalTableSettingsProps } from "./RemoveExternalTableSettings.props";

export const RemoveExternalTablesSettings = (
  props: RemoveExternalTableSettingsProps
) => {
  const { loading: loadingSourceTables, fetchSourceTablesForWorkspace } = props;
  const workspaces = useRecoilValue(workspacesState);
  const { setWorkspacesFromApi, loading: loadingWorkspaces } = useWorkspaces();
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<
    string | undefined
  >(workspaces[0]?.id ?? undefined);
  const [checkedTables, setCheckedTables] = useState<string[]>([]);
  const [externalTables, setExternalTables] = useState<SourceTable[]>([]);
  const [invalidNoSelection, setInvalidNoSelection] = useState(false);
  const { openDialog, closeDialog } = useDialog();
  const { loading, deleteExternalTables } = useExternalTables();

  const removeTables = async () => {
    let invalid = false;
    if (checkedTables.length === 0) {
      setInvalidNoSelection(true);
      invalid = true;
    }

    if (invalid) {
      return;
    }

    if (selectedWorkspaceId === undefined) {
      openDialog({
        variant: "error",
        text: "Could not determine Workspace ID",
        primaryButtonText: "retry",
        secondaryButtonText: "cancel",
        onPrimaryButtonClick: () => removeTables,
        onSecondaryButtonClick: () => closeDialog(),
      });
    } else {
      const ownerId = workspaces.find(
        (w) => w.id === selectedWorkspaceId
      )?.ownerId;
      if (ownerId) {
        await deleteExternalTables(ownerId, selectedWorkspaceId, checkedTables);
        await fetchExternalTables();
      }
    }
  };

  const handleChecked = useCallback((tableName: string) => {
    setCheckedTables((prevValue) =>
      prevValue.includes(tableName)
        ? prevValue.filter((x) => x !== tableName)
        : [...prevValue, tableName]
    );
  }, []);

  const fetchExternalTables = useCallback(async () => {
    if (selectedWorkspaceId) {
      const tables = await fetchSourceTablesForWorkspace(selectedWorkspaceId);
      if (tables) {
        setExternalTables(tables.filter((table) => table.isExternal));
      }
    }
  }, [fetchSourceTablesForWorkspace, selectedWorkspaceId]);

  useEffect(() => {
    const fetchData = async () => {
      await setWorkspacesFromApi();
    };

    if (workspaces.length === 0) {
      fetchData();
    }
  }, [setWorkspacesFromApi, workspaces.length]);

  useEffect(() => {
    fetchExternalTables();
  }, [fetchExternalTables, fetchSourceTablesForWorkspace, selectedWorkspaceId]);

  return (
    <div className="p-12">
      {loadingWorkspaces ? (
        <div className="mb-4 w-40">
          <Loader size="4rem" />
          <p className="text-center mt-2 font-bold dark:text-white">
            Loading Workspaces
          </p>
        </div>
      ) : (
        <div className="mb-4 w-80">
          <SelectInput
            data-testid="ws-cb"
            label="Choose Workspace"
            onChange={(e) =>
              setSelectedWorkspaceId(
                workspaces.find((x) => x.title === e.target.value)?.id
              )
            }
          >
            {workspaces.map((ws) => (
              <option key={ws.id}>{ws.title}</option>
            ))}
          </SelectInput>
        </div>
      )}

      {externalTables.length > 0 ? (
        <div>
          <p className="font-bold mb-2">
            Choose table(s) to remove from workspace
          </p>
          {loadingSourceTables ? (
            <div className="w-52 my-6">
              <Loader size="5rem" />
              <p className="text-center mt-6 font-bold dark:text-white">
                Loading External Tables
              </p>
            </div>
          ) : (
            <div
              className={`p-4 border h-fit max-h-[300px] w-[500px] overflow-auto ${
                invalidNoSelection ? "border-red-700" : ""
              }`}
            >
              {externalTables
                .filter((table) => table.isExternal)
                .map((table) => (
                  <div
                    className="pb-1 flex gap-2 items-center"
                    key={table.name}
                  >
                    <input
                      type="checkbox"
                      key={table.name}
                      checked={checkedTables.includes(table.name)}
                      onChange={() => {
                        handleChecked(table.name);
                        setInvalidNoSelection(false);
                      }}
                    />
                    <p
                      className={`${
                        checkedTables.includes(table.name)
                          ? "text-blue-600"
                          : ""
                      }`}
                    >
                      {table.name}
                    </p>
                  </div>
                ))}
            </div>
          )}
          {invalidNoSelection && (
            <p className="text-red-700 italic">Select a table to remove</p>
          )}
          <div className="pt-5">
            {loading ? (
              <div className="flex flex-col w-fit justify-center content-center">
                <Loader size="5rem" />
                <p className="text-center mt-2 font-bold dark:text-white">
                  Removing Tables
                </p>
              </div>
            ) : (
              <Button
                data-testid="btn_remove"
                variant="primary"
                onClick={() => removeTables()}
              >
                Remove
              </Button>
            )}
          </div>
        </div>
      ) : (
        <p>No External Tables in Workspace</p>
      )}
    </div>
  );
};
