import { Edit, ErrorOutline, LockOpen } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import format from "date-fns/format";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { ProjectMetadata, WorkspaceState } from "../../api/apiClient";
import { GroupIcon, TrashCanIcon } from "../../assets/images";
import { useDialog } from "../../hooks/useDialog/useDialog";
import { useModal } from "../../hooks/useModal/useModal";
import { useWorkspaces } from "../../hooks/useWorkspaces/useWorkspaces";
import ContextMenu from "../contextMenu/ContextMenu";

export interface WorkspaceButtonProps {
  id: string;
  ontologyProject: string | undefined;
  ownerId: string;
  state?: WorkspaceState;
  lastUpdated?: Date;
  deletionDate?: Date;
  disabled?: boolean;
  caption?: string;
  owner?: string;
  onReloadWorkspaces?: () => void;
  buildCount?: number;
  snapshotCount?: number;
  templateVersion?: string;
  projectMetaData?: ProjectMetadata;
}

const ColorTableBackground: Record<string, string> = {
  [WorkspaceState.Draft]: "bg-yellow-900",
  [WorkspaceState.Published]: "bg-blue-700",
  [WorkspaceState.Publishing]: "bg-green-700",
};

const ColorTableText: Record<string, string> = {
  [WorkspaceState.Draft]: "hover:text-yellow-900",
  [WorkspaceState.Published]: "hover:text-blue-700",
  [WorkspaceState.Publishing]: "hover:text-green-700",
};

export const WorkspaceButton = (props: WorkspaceButtonProps) => {
  const { openDialog, closeDialog } = useDialog();
  const { openModal } = useModal();
  const navigate = useNavigate();
  const { deleteWorkspace, updateWorkspace, unlockWorkspace, loading } =
    useWorkspaces();

  const lastUpdated: string =
    props.lastUpdated && props.lastUpdated != null
      ? format(props.lastUpdated, "dd-MM-yyyy")
      : "Never";

  const state: WorkspaceState = props.state ?? WorkspaceState.Draft;
  const backGroundColor = ColorTableBackground[state];
  const textHoverColor = ColorTableText[state];
  const templateVersion = props.templateVersion ?? "v1.0.0";

  useEffect(() => {
    // delay to allow table to fully render before rebuilding tooltip
    setTimeout(() => ReactTooltip.rebuild(), 500);
  }, []);

  const commonItems = [
    {
      label: "Rename",
      icon: (
        <Edit
          style={{ width: 16, height: 16 }}
          className="text-blue-700 group-hover:text-white"
        />
      ),
      onClick: () =>
        openModal({
          variant: "updateWorkspace",
          onEditComplete: async (workspaceName) => {
            await updateWorkspace(props.id, workspaceName ?? "");
            window.location.reload();
          },
          workspaceName: props.caption,
        }),
    },
    // commented out since workspace duplication is currently out of scope
    // {
    //   label: "Duplicate",
    //   icon: (
    //     <ContentDuplicateIcon className="text-blue-700 group-hover:text-blue-600" />
    //   ),
    // },
    {
      label: "Delete",
      icon: (
        <TrashCanIcon
          style={{ width: 16, height: 16 }}
          className="text-red-700 group-hover:text-white"
        />
      ),
      textColor: "text-red-700 hover:bg-red-700 hover:text-white",
      onClick: () =>
        openDialog({
          variant: "confirm",
          primaryButtonText: "Delete",
          secondaryButtonText: "Cancel",
          text: "Do you really wish to delete this workspace?",
          onPrimaryButtonClick: () => {
            closeDialog();
            deleteWorkspace(props.id);
          },
          onSecondaryButtonClick: () => closeDialog(),
        }),
    },
  ];

  return (
    <div
      className={`w-80 h-80 p-6 ${backGroundColor} text-white shadow-md flex flex-col justify-between`}
    >
      <div className="inline-flex w-full">
        <div className="w-full h-[41px] ">
          {loading ? (
            <CircularProgress color="inherit" size={41} thickness={5} />
          ) : (
            <GroupIcon />
          )}
        </div>
        {(state === WorkspaceState.Published ||
          state === WorkspaceState.Publishing) && (
          <ErrorOutline
            fontSize="small"
            data-tip="Published workspace is Read Only"
          />
        )}

        <ContextMenu
          items={
            state === WorkspaceState.Published ||
            state === WorkspaceState.Publishing
              ? [
                  {
                    label: "Unlock",
                    icon: (
                      <LockOpen
                        style={{ width: 16, height: 16 }}
                        className="text-blue-700 group-hover:text-white"
                      />
                    ),
                    onClick: () =>
                      openDialog({
                        variant: "confirm",
                        primaryButtonText: "Unlock",
                        secondaryButtonText: "Cancel",
                        text: "Are you sure you want to unlock this workspace? This will abandon the Pull Request currently in review for this workspace.",
                        onPrimaryButtonClick: async () => {
                          const success = await unlockWorkspace(props.id);
                          if (success) {
                            window.location.reload();
                          }
                        },
                        onSecondaryButtonClick: () => closeDialog(),
                      }),
                  },
                  ...commonItems,
                ]
              : commonItems
          }
          disabled={loading || props.disabled}
        />
      </div>
      <div className="h-20">
        <div className="text-sm truncate">{props.owner}</div>
        <div
          data-testid="headline"
          className="text-xl leading-6 font-bold line-clamp-3"
          title={props.caption}
        >
          <Link className="hover:underline" to={`/editor/${props.id}`}>
            {props.caption}
          </Link>
        </div>
        {props.projectMetaData && props.projectMetaData.version && (
          <p>
            {props.projectMetaData.version.major}.
            {props.projectMetaData.version.minor}.
            {props.projectMetaData.version.build}
          </p>
        )}
      </div>
      <div className="text-md flex justify-between">
        <div className=" align-start">
          <div className="font-semibold uppercase">{WorkspaceState[state]}</div>
          <div>{lastUpdated}</div>
        </div>
        <div className="text-right">
          <div className="font-semibold uppercase">Template</div>
          <div>{templateVersion}</div>
        </div>
      </div>
      <div className="text-md text-center flex justify-center gap-3">
        <button
          data-testid={"btn-build"}
          className={`uppercase font-semibold px-2 py-1 border whitespace-nowrap flex-1 hover:bg-white hover:border-white ${textHoverColor}`}
          onClick={() => navigate(`editor/${props.id}/builds`)}
          disabled={loading || props.disabled}
        >
          Builds {`(${props.buildCount ?? 0})`}
        </button>
        <button
          data-testid={"btn-snapshot"}
          className={`uppercase font-semibold px-2 py-1 border whitespace-nowrap flex-1 hover:bg-white hover:border-white ${textHoverColor}`}
          onClick={() => navigate(`editor/${props.id}/snapshots`)}
          disabled={loading || props.disabled}
        >
          Snapshots {`(${props.snapshotCount ?? 0})`}
        </button>
      </div>
    </div>
  );
};
