import { Build, Publish } from "@mui/icons-material";
import classNames from "classnames";
import { useUserRolesContext } from "../../../context/UserRolesContext/UserRolesContext";
import { useModal } from "../../../hooks/useModal/useModal";
import { UserRoles } from "../../../utils/types/userRoles";
import { Button } from "../../form";
import { EditorSidebarButtonsProps } from "./EditorSidebarButtons.props";

export const EditorSidebarButtons = (props: EditorSidebarButtonsProps) => {
  const { openModal } = useModal();
  const {
    className,
    workspaceId,
    ontologyProject,
    ownerId,
    disabled: readOnlyWorkspace,
    projectVersion,
    ...rest
  } = props;
  const classes = classNames(
    "m-2 h-8 grid gap-2 grid-cols-2 grid-rows-1",
    props.className
  );
  const userRoles = useUserRolesContext();
  const disabled =
    readOnlyWorkspace || !userRoles.includes(UserRoles.WorkspacesPublish);

  return (
    <div className={classes} {...rest}>
      <Button
        className={`${disabled ? "opacity-60" : ""} `}
        disabled={disabled}
        variant="success"
        onClick={() => {
          openModal({
            variant: "publishWorkspace",
            workspaceId: workspaceId,
            ownerId: ownerId,
            ontologyProject: ontologyProject,
            projectVersion: projectVersion,
          });
        }}
      >
        <div
          data-tip={`${
            !userRoles.includes(UserRoles.WorkspacesPublish)
              ? "You do not <br> have authorization <br> to perform <br> publish action"
              : readOnlyWorkspace
              ? "Publish is disabled <br> while workspace is <br> read-only"
              : "Submit changes <br> for manual review"
          }`}
          className=" flex items-center"
        >
          <Publish />
          <span className="ml-2">PUBLISH</span>
        </div>
      </Button>
      <Button
        className={`${readOnlyWorkspace ? "opacity-60" : ""}`}
        disabled={readOnlyWorkspace}
        data-tip="Start new build <br> with current changes"
        onClick={() =>
          openModal({
            variant: "buildWorkspace",
            workspaceId: workspaceId,
            ontologyProject: ontologyProject,
            ownerId: ownerId,
          })
        }
      >
        <Build fontSize="small" />
        <span className="ml-2">BUILD</span>
      </Button>
    </div>
  );
};
