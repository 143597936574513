import axios from "axios";
import { acquireAccessToken } from "../utils/msal/accessToken";
import { ApiClient } from "./apiClient";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

instance.interceptors.request.use(async (request) => {
  request.headers = request.headers ?? {};

  const token = await acquireAccessToken();

  if (token) {
    request.headers.authorization = `Bearer ${token}`;
  }

  return request;
});

const apiClient = new ApiClient(undefined, instance);

export const api = apiClient;
