import { SourceTableDataType } from "../../api/apiClient";

const isDecimalType = (dataType?: SourceTableDataType) => {
  switch (dataType) {
    case SourceTableDataType.XsdDecimal:
    case SourceTableDataType.XsdFloat:
      return true;
    default:
      return false;
  }
};

export const DecimalValidator = (
  input: string,
  dataType?: SourceTableDataType
) => {
  if (isDecimalType(dataType) && !Number.isFinite(Number(input))) {
    return false;
  }

  return true;
};
