import {
  Check,
  Close,
  PriorityHigh,
  SvgIconComponent,
} from "@mui/icons-material";

export type DialogType = "confirm" | "error" | "success";

type DefaultSettingsType = {
  [key in DialogType]: {
    icon: SvgIconComponent;
    iconColor: string;
    borderColor: string;
  };
};

export const DialogDefaultSettings: DefaultSettingsType = {
  confirm: {
    icon: PriorityHigh,
    iconColor: "text-yellow-900",
    borderColor: "border-yellow-900",
  },
  error: {
    icon: Close,
    iconColor: "text-red-700",
    borderColor: "border-red-700",
  },
  success: {
    icon: Check,
    iconColor: "text-green-700",
    borderColor: "border-green-700",
  },
};

type DialogCommonSettings = {
  text: string;
  primaryButtonText: string;
  onPrimaryButtonClick?: () => void;
};

type ConfirmDialogSettings = DialogCommonSettings & {
  variant: "confirm";
  secondaryButtonText: string;
  onSecondaryButtonClick?: () => void;
};

type ErrorDialogSettings = DialogCommonSettings & {
  variant: "error";
  secondaryButtonText?: string;
  onSecondaryButtonClick?: () => void;
};

type SuccessDialogSettings = DialogCommonSettings & {
  variant: "success";
};

export type DialogSettings =
  | ConfirmDialogSettings
  | ErrorDialogSettings
  | SuccessDialogSettings;
