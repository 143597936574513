import classNames from "classnames";
import { HTMLAttributes } from "react";
import {
  ModelStatus,
  SourceTable,
  SourceTableRow,
  Workspace
} from "../../../api/apiClient";
import {
  ContentDuplicateIcon,
  MinusCircleIcon,
  MoveIcon,
  TrashCanIcon
} from "../../../assets/images";
import { useUserRolesContext } from "../../../context/UserRolesContext/UserRolesContext";
import { useDialog } from "../../../hooks/useDialog/useDialog";
import { useModal } from "../../../hooks/useModal/useModal";
import { GovernanceColumns } from "../../../utils/types/governanceColumns";
import { UserRoles } from "../../../utils/types/userRoles";

type Props = {
  itemsCount: number;
  itemsSelected?: number;
  selectedRows: SourceTableRow[];
  workspace?: Workspace;
  sourceTable?: SourceTable;
  sourceTables?: SourceTable[];
  disabled?: boolean;
  selectedIndexes?: number[];
  onDelete?: () => void;
  onInclude?: (rowId: string) => void;
  onExclude?: (rowId: string) => void;
  addTableRow?: (
    index?: number | undefined,
    values?:
      | {
          [key: string]: string;
        }
      | undefined
  ) => Promise<void>;
  updateTableRow?: (
    id: string,
    row: SourceTableRow,
    isExternal?: boolean,
    skipFetch?: boolean
  ) => void;
  setRowIndexes?: (indexes: number[]) => void;
  moveRows?: (
    sourceTableNameSource: string,
    sourceTableNameDestination: string,
    rows: SourceTableRow[]
  ) => void;
} & HTMLAttributes<HTMLDivElement>;

export const EditorToolbar = (props: Props) => {
  const {
    className,
    workspace,
    sourceTable,
    sourceTables,
    itemsCount,
    selectedRows,
    itemsSelected,
    selectedIndexes,
    onDelete,
    onInclude,
    addTableRow,
    updateTableRow,
    setRowIndexes,
    onExclude,
    moveRows,
    disabled,
    ...rest
  } = props;
  const userRoles = useUserRolesContext();
  const userNotInRole = !userRoles.includes(UserRoles.WorkspacesReadWrite);
  const { openDialog, closeDialog } = useDialog();
  const { openModal } = useModal();

  const classes = classNames(
    props.className,
    "bg-gray-500 h-12 border-t border-t-gray-600 dark:border-t-gray-750"
  );

  const handleDuplicate = () =>
    openDialog({
      variant: "confirm",
      primaryButtonText: "Duplicate",
      secondaryButtonText: "Cancel",
      text: `Do you really wish to duplicate ${
        selectedRows.length === 1 ? "this row" : "these rows"
      }?`,
      onPrimaryButtonClick: async () => {
        let newRowIndexes: number[] = [];
        let index = 0;
        for (const row of selectedRows) {
          const newValues: { [key: string]: string } = {};
          if (row.values) {
            Object.keys(row.values).forEach((key) => {
              newValues[key] = row.values![key];
            });
          }
          if (selectedIndexes) {
            await props.addTableRow?.(
              selectedIndexes[index] + index,
              newValues
            );
            newRowIndexes = [selectedIndexes[index] + index, ...newRowIndexes];
            index++;
          }
        }
        setRowIndexes?.(newRowIndexes);
        closeDialog();
      },
      onSecondaryButtonClick: () => closeDialog(),
    });

  const handleMoveRow = () =>
    openModal({
      variant: "moveRow",
      workspaceId: workspace?.id,
      sourceTableNameSource: props.sourceTable?.displayName,
      sourceTables: sourceTables,
      rows: selectedRows,
      MoveTableRows: (source: string, dest: string, rows: SourceTableRow[]) =>
        moveRows?.(source, dest, rows),
    });

  return (
    <div className={classes} {...rest}>
      <div className="flex px-4 h-full justify-between items-center dark:bg-darkSecondary">
        <div className="flex gap-6 dark:text-gray-600">
          {(itemsSelected || selectedRows.length > 0) && (
            <>
              <div className="flex gap-1 ">
                <div className="font-bold ">
                  {itemsSelected ?? selectedRows.length}
                </div>
                <div>
                  {(itemsSelected && itemsSelected > 1) ||
                  selectedRows.length > 1
                    ? "items selected"
                    : "item selected"}
                </div>
              </div>
              {!sourceTable?.isExternal && addTableRow !== undefined && (
                <button
                  data-testid="btn_duplicate"
                  disabled={disabled}
                  className={`flex gap-2 group items-center ${
                    disabled ? "opacity-60" : ""
                  }`}
                  onClick={handleDuplicate}
                >
                  <ContentDuplicateIcon
                    className={`text-blue-700 ${
                      disabled ? "" : "group-hover:text-blue-600"
                    }`}
                  />
                  <div className="font-bold uppercase">duplicate</div>
                </button>
              )}
              {!sourceTable?.isExternal && moveRows !== undefined && (
                <button
                  data-testid="btn_move"
                  disabled={disabled}
                  className={`flex gap-2 group items-center ${
                    disabled ? "opacity-60" : ""
                  }`}
                  onClick={handleMoveRow}
                >
                  <MoveIcon
                    className={`text-blue-700 ${
                      disabled ? "" : "group-hover:text-blue-600"
                    }`}
                  />
                  <div className="font-bold uppercase">move</div>
                </button>
              )}
              {onDelete && (
                <button
                  data-testid="btn_delete"
                  disabled={disabled}
                  className={`flex gap-2 group font-bold text-red-700 ${
                    disabled ? "opacity-60" : "hover:text-red-600"
                  } uppercase items-center`}
                  onClick={() => onDelete()}
                >
                  <TrashCanIcon />
                  delete
                </button>
              )}
              {sourceTable?.isExternal &&
                selectedRows.some(
                  (row) =>
                    row.values &&
                    row.values[GovernanceColumns.ModelStatus] !==
                      ModelStatus.Deprecate &&
                    row.values[GovernanceColumns.ModelStatus] !==
                      ModelStatus.Withdrawn
                ) && (
                  <button
                    data-testid="btn_exclude"
                    disabled={userNotInRole}
                    className={`flex gap-2 group font-bold text-red-700 uppercase items-center ${
                      userNotInRole ? "opacity-60" : "hover:text-red-600"
                    }`}
                    onClick={async () => {
                      let i = 0;
                      for (const row of selectedRows) {
                        i = i + 1;
                        if (
                          row.values &&
                          row.values[GovernanceColumns.ModelStatus] !==
                            ModelStatus.Deprecate &&
                          row.values[GovernanceColumns.ModelStatus] !==
                            ModelStatus.Withdrawn
                        ) {
                          row.values[GovernanceColumns.ModelStatus] =
                            ModelStatus.Deprecate;
                          await props.updateTableRow?.(
                            row.id,
                            row,
                            true,
                            i !== selectedRows.length
                          );
                          onExclude?.(row.id);
                        }
                      }
                    }}
                  >
                    <MinusCircleIcon />
                    Exclude From Build
                  </button>
                )}
              {sourceTable?.isExternal &&
                selectedRows.some(
                  (row) =>
                    row.values &&
                    (row.values[GovernanceColumns.ModelStatus] ===
                      ModelStatus.Deprecate ||
                      row.values[GovernanceColumns.ModelStatus] ===
                        ModelStatus.Withdrawn)
                ) && (
                  <button
                    data-testid="btn_include"
                    disabled={userNotInRole}
                    className={`flex gap-2 group font-bold text-blue-700 uppercase items-center ${
                      userNotInRole ? "opacity-60" : "hover:text-blue-600"
                    }`}
                    onClick={async () => {
                      let i = 0;
                      for (const row of selectedRows) {
                        i = i + 1;
                        if (
                          row.values &&
                          (row.values[GovernanceColumns.ModelStatus] ===
                            ModelStatus.Deprecate ||
                            row.values[GovernanceColumns.ModelStatus] ===
                              ModelStatus.Withdrawn)
                        ) {
                          row.values[GovernanceColumns.ModelStatus] =
                            ModelStatus.Development;
                          await props.updateTableRow?.(
                            row.id,
                            row,
                            true,
                            i !== selectedRows.length
                          );
                          onInclude?.(row.id);
                        }
                      }
                    }}
                  >
                    <MinusCircleIcon />
                    Include In Build
                  </button>
                )}
            </>
          )}
        </div>
        <div className="flex">
          <div className="text-gray-800 dark:text-gray-700">
            {props.itemsCount} rows
          </div>
        </div>
      </div>
    </div>
  );
};
