import { useEffect, useState } from "react";
import { api } from "../../api";
import { useOntologyContext } from "../../context/ontologyContext/OntologyContext";

export const InfoBar = () => {
  const [daysTillExpiry, setDaysTillExpiry] = useState<number>(
    Number.MAX_SAFE_INTEGER
  );
  const { projectName } = useOntologyContext();
  const informationThreshold = 30;
  const warningThreshold = 14;
  const criticalThreshold = 7;

  useEffect(() => {
    const fetchData = async () => {
      if (projectName === undefined || projectName.length === 0) {
        // No project name loaded yet :)
        return;
      }
      const expiryDate = (await api.getSettingsV2(projectName))
        .azureDevopsTokenExpiry;

      if (expiryDate) {
        const now = new Date();
        const now_utc = Date.UTC(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate(),
          now.getUTCHours(),
          now.getUTCMinutes(),
          now.getUTCSeconds()
        );

        const msBetween = Math.abs(now_utc - expiryDate.getTime());
        const daysBetween = msBetween / (24 * 60 * 60 * 1000);
        setDaysTillExpiry(daysBetween);
      }
    };

    fetchData();
  }, [projectName]);

  if (daysTillExpiry > informationThreshold) {
    return null;
  }

  return (
    <div
      className={`text-white flex items-center h-12 px-4 ${
        daysTillExpiry < warningThreshold ? "justify-between" : "justify-center"
      } ${daysTillExpiry > warningThreshold ? "bg-yellow-900" : "bg-red-700"} ${
        daysTillExpiry > criticalThreshold ? "" : "animate-pulse"
      }`}
    >
      {daysTillExpiry < warningThreshold && <div>WARNING</div>}
      <div className="flex gap-4">
        <div>
          SYSTEM ACCESS TOKEN (PAT) TO {projectName} EXPIRES IN{" "}
          {Math.round(daysTillExpiry)} DAYS.
        </div>
        <div>CONTACT ADMINISTRATOR TO RENEW TOKEN.</div>
      </div>
      {daysTillExpiry < warningThreshold && <div>WARNING</div>}
    </div>
  );
};
