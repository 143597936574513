import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "../../state/atoms/notificationsState";
import { AddNotificationParams } from "../../utils/types/notification";

export const useNotifications = () => {
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  const addNotification = useCallback(
    (options: AddNotificationParams) => {
      const date = new Date();
      const { variant = "information", delay = 4000 } = options;

      const newNotification = {
        id: date.getTime(),
        created: date,
        variant,
        delay,
        ...options,
        onClose: () => {},
      };

      setNotifications((prevValue) => [...prevValue, newNotification]);
    },
    [setNotifications]
  );

  const clear = useCallback(
    (id: number) => {
      setNotifications(notifications.filter((x) => x.id !== id));
    },
    [notifications, setNotifications]
  );

  return { addNotification, clear, notifications };
};
