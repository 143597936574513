import { useCallback, useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";
import { api } from "../../api";
import {
  SourceTable,
  SourceTableColumn,
  SourceTableRow,
  UpdateIriRequest,
} from "../../api/apiClient";
import { useOntologyContext } from "../../context/ontologyContext/OntologyContext";
import { workspacesState } from "../../state/atoms/workspacesState";
import { EditorDataState } from "../../utils/types/editor";
import { GovernanceColumns } from "../../utils/types/governanceColumns";
import { useChangeNote } from "../useChangeNote/useChangeNote";
import { useDialog } from "../useDialog/useDialog";
import { useNotifications } from "../useNotifications/useNotifications";
import { useWorkspaces } from "../useWorkspaces/useWorkspaces";
import { useEditorActiveData } from "./useEditorActiveData/useEditorActiveData";

const reducer = (
  state: EditorDataState,
  action: {
    state: "loading" | "saving" | "error" | "done" | "saved";
    item: keyof EditorDataState;
  }
) => {
  switch (action.state) {
    case "loading":
      state[action.item] = {
        loading: true,
        error: false,
        saving: false,
        saved: false,
      };
      break;
    case "saving":
      state[action.item] = {
        loading: false,
        error: false,
        saving: true,
        saved: false,
      };
      break;
    case "error":
      state[action.item] = {
        loading: false,
        error: true,
        saving: false,
        saved: false,
      };
      break;
    case "done":
      state[action.item] = {
        loading: false,
        error: false,
        saving: false,
        saved: false,
      };
      break;
    case "saved":
      state[action.item] = {
        loading: false,
        error: false,
        saving: false,
        saved: true,
      };
      break;
    default:
      throw new Error();
  }

  return { ...state };
};

const initialValue = {
  sourceTables: {
    loading: false,
    error: false,
    saving: false,
    saved: false,
  },
  sourceTableColumns: {
    loading: false,
    error: false,
    saving: false,
    saved: false,
  },
  sourceTableRows: {
    loading: false,
    error: false,
    saving: false,
    saved: false,
  },
};

const externalTablesDataRoot = "external_tables";

export const useEditor = () => {
  const [state, dispatch] = useReducer(reducer, initialValue);
  const navigate = useNavigate();
  const workspaces = useRecoilValueLoadable(workspacesState);
  const params = useParams<{ workspaceId: string; sourceTable: string }>();
  const [sourceTableRowError, setSourceTableRowError] = useState(false);
  const [sourceTableColumnError, setSourceTableColumnError] = useState(false);
  const [sourceTableError, setSourceTableError] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [sourceTables, setSourceTables] = useState<SourceTable[]>([]);
  const [sourceTableColumns, setSourceTableColumns] = useState<
    SourceTableColumn[]
  >([]);
  const [sourceTableRows, setSourceTableRows] = useState<SourceTableRow[]>([]);
  const { activeSourceTable, activeWorkspace } =
    useEditorActiveData(sourceTables);
  const { addNotification } = useNotifications();
  const { openDialog, closeDialog } = useDialog();
  const { setWorkspacesFromApi } = useWorkspaces();
  const { changeNote } = useChangeNote();
  const { projectName } = useOntologyContext();

  const renderDialog = useCallback(
    (text: string, retryFunction: () => void) => {
      openDialog({
        variant: "error",
        onPrimaryButtonClick: retryFunction,
        onSecondaryButtonClick: () => closeDialog(),
        text: text,
        primaryButtonText: "retry",
        secondaryButtonText: "close",
      });
    },
    [closeDialog, openDialog]
  );

  const fetchSourceTables = useCallback(async () => {
    if (!activeWorkspace) {
      return;
    }
    try {
      setSourceTableError(false);
      dispatch({ state: "loading", item: "sourceTables" });
      setSourceTables(await api.getSourceTables(activeWorkspace.id));
    } catch (error) {
      dispatch({ state: "error", item: "sourceTables" });
      addNotification({
        message: `Error occurred while loading source tables. Error message: ${
          (error as Error).message
        }.`,
        variant: "error",
      });
      setSourceTableError(true);
    } finally {
      dispatch({ state: "done", item: "sourceTables" });
    }
  }, [activeWorkspace, addNotification]);

  const fetchSourceTablesForWorkspace = useCallback(
    async (workspaceId: string) => {
      try {
        setSourceTableError(false);
        dispatch({ state: "loading", item: "sourceTables" });
        return await api.getSourceTables(workspaceId);
      } catch (error) {
        dispatch({ state: "error", item: "sourceTables" });
        addNotification({
          message: `Error occurred while loading source tables. Error message: ${
            (error as Error).message
          }.`,
          variant: "error",
        });
        setSourceTableError(true);
      } finally {
        dispatch({ state: "done", item: "sourceTables" });
      }
    },
    [addNotification]
  );

  const addTableRow = async (
    index: number = 0,
    values?: { [key: string]: string }
  ) => {
    if (!activeWorkspace || !activeSourceTable) {
      return;
    }

    let hasError = false;

    if (values === undefined) {
      values = {};
    }

    if (values[GovernanceColumns.ChangeNote] === undefined) {
      values[GovernanceColumns.ChangeNote] = changeNote;
    }

    const newRow = new SourceTableRow({
      id: `temp-${Math.random()}`,
      values: values,
    });
    let response: SourceTableRow = newRow;

    try {
      dispatch({ state: "saving", item: "sourceTableRows" });
      response = await api.createSourceTableRow(
        activeWorkspace.id,
        activeSourceTable.name,
        index,
        newRow
      );
    } catch (e) {
      hasError = true;
      dispatch({ state: "error", item: "sourceTableRows" });
      renderDialog(
        `Error occured while adding row. Error Message: ${
          (e as Error).message
        }`,
        () => addTableRow(index, values)
      );
    } finally {
      dispatch({ state: "saved", item: "sourceTableRows" });
      dispatch({ state: "done", item: "sourceTableRows" });
    }

    if (!hasError) {
      setSourceTableRows((prevValue) => [
        ...prevValue.slice(0, index),
        response,
        ...prevValue.slice(index),
      ]);
    }
  };

  const updateTableRow = async (
    rowId: string,
    newRow: SourceTableRow,
    isExternal: boolean = false,
    skipFetch: boolean = false
  ) => {
    if (!activeWorkspace || !activeSourceTable) {
      return;
    }

    try {
      dispatch({ state: "saving", item: "sourceTableRows" });
      await api.modifySourceTableRowV2(
        projectName,
        activeWorkspace.id,
        isExternal
          ? "external_tables|" + activeSourceTable.name
          : activeSourceTable.name,
        rowId,
        newRow
      );
      if (!skipFetch) {
        await fetchTableRows();
      }
    } catch (e) {
      dispatch({ state: "error", item: "sourceTableRows" });
      addNotification({
        variant: "error",
        message: `Error while updating source table row. Changes are not saved while errors occur. Error message: ${
          (e as Error).message
        }.`,
      });
    } finally {
      dispatch({ state: "done", item: "sourceTableRows" });
      dispatch({ state: "saved", item: "sourceTableRows" });
    }
  };

  const updateRowIri = async (
    rowId: string,
    newRowId: string,
    skipFetch: boolean = false
  ) => {
    if (!activeWorkspace || !activeSourceTable) {
      return;
    }

    const request: UpdateIriRequest = new UpdateIriRequest({
      newId: newRowId,
    });

    try {
      dispatch({ state: "saving", item: "sourceTableRows" });
      await api.modifySourceTableRowId(
        projectName,
        activeWorkspace?.id,
        activeSourceTable?.name,
        rowId,
        request
      );
      if (!skipFetch) {
        await fetchTableRows();
      }
    } catch (e) {
      dispatch({ state: "error", item: "sourceTableRows" });
      renderDialog(
        `Error occured while updating row id. Error Message: ${
          (e as Error).message
        }`,
        () => updateRowIri(rowId, newRowId)
      );
    } finally {
      dispatch({ state: "done", item: "sourceTableRows" });
      dispatch({ state: "saved", item: "sourceTableRows" });
    }
  };

  const fetchTableColumns = useCallback(async () => {
    if (!activeWorkspace || !activeSourceTable) {
      return;
    }

    try {
      dispatch({ state: "loading", item: "sourceTableColumns" });
      setSourceTableColumnError(false);
      setSourceTableColumns(
        await api.getSourceTableColumns(
          activeWorkspace.id,
          activeSourceTable.isExternal
            ? externalTablesDataRoot + "|" + activeSourceTable.name
            : activeSourceTable.name
        )
      );
    } catch (error) {
      dispatch({ state: "error", item: "sourceTableColumns" });
      addNotification({
        message: `Error occurred while loading source table columns. Error message: ${
          (error as Error).message
        }.`,
        variant: "error",
      });
      setSourceTableColumnError(true);
    } finally {
      dispatch({ state: "done", item: "sourceTableColumns" });
    }
  }, [activeSourceTable, activeWorkspace, addNotification]);

  const fetchTableRows = useCallback(async () => {
    if (!activeWorkspace || !activeSourceTable) {
      return;
    }

    try {
      dispatch({ state: "loading", item: "sourceTableRows" });
      setSourceTableRowError(false);
      setSourceTableRows(
        await api.getSourceTableRows(
          activeWorkspace.id,
          activeSourceTable.isExternal
            ? externalTablesDataRoot + "|" + activeSourceTable.name
            : activeSourceTable.name
        )
      );
    } catch (error) {
      dispatch({ state: "error", item: "sourceTableRows" });
      addNotification({
        message: `Error occurred while loading source table rows. Error message: ${
          (error as Error).message
        }.`,
        variant: "error",
      });
      setSourceTableRowError(true);
    } finally {
      dispatch({ state: "done", item: "sourceTableRows" });
    }
  }, [activeSourceTable, activeWorkspace, addNotification]);

  const deleteTableRow = useCallback(
    async (rowId: string) => {
      if (!activeWorkspace || !activeSourceTable) {
        return;
      }

      try {
        dispatch({ state: "saving", item: "sourceTableRows" });
        await api.deleteSourceTableRowV2(
          projectName,
          activeWorkspace.id,
          activeSourceTable.name,
          rowId
        );

        await fetchTableRows();
      } catch (e) {
        dispatch({ state: "error", item: "sourceTableRows" });
        renderDialog(
          `Error occured while deleting row. Error Message: ${
            (e as Error).message
          }`,
          () => deleteTableRow(rowId)
        );
      } finally {
        dispatch({ state: "saved", item: "sourceTableRows" });
        dispatch({ state: "done", item: "sourceTableRows" });
      }
    },
    [
      activeSourceTable,
      activeWorkspace,
      fetchTableRows,
      projectName,
      renderDialog,
    ]
  );

  const deleteTableRows = useCallback(
    async (rowIds: string[]) => {
      if (!activeWorkspace || !activeSourceTable) {
        return;
      }

      try {
        dispatch({ state: "saving", item: "sourceTableRows" });
        for (const id of rowIds) {
          await api.deleteSourceTableRowV2(
            projectName,
            activeWorkspace.id,
            activeSourceTable.name,
            id
          );
        }
        fetchTableRows();
      } catch (e) {
        dispatch({ state: "error", item: "sourceTableRows" });
        renderDialog(
          `Error occured while deleting row. Error Message: ${
            (e as Error).message
          }`,
          () => deleteTableRows(rowIds)
        );
      } finally {
        dispatch({ state: "saved", item: "sourceTableRows" });
        dispatch({ state: "done", item: "sourceTableRows" });
      }
    },
    [
      activeSourceTable,
      activeWorkspace,
      fetchTableRows,
      projectName,
      renderDialog,
    ]
  );

  const moveTableRows = useCallback(
    async (
      sourceTableNameSource: string,
      sourceTableNameDestination: string,
      rows: SourceTableRow[]
    ) => {
      if (!activeWorkspace) {
        return;
      }

      try {
        dispatch({ state: "loading", item: "sourceTableRows" });
        for (let i = 0; i < rows.length; i++) {
          await api.moveSourceTableRowV2(
            projectName,
            activeWorkspace.id,
            sourceTableNameSource,
            sourceTableNameDestination,
            rows[i]
          );
        }
        fetchTableRows();
      } catch (e) {
        dispatch({ state: "error", item: "sourceTableRows" });
        renderDialog(
          `Error occured while moving row. Error Message: ${
            (e as Error).message
          }`,
          () =>
            moveTableRows(
              sourceTableNameSource,
              sourceTableNameDestination,
              rows
            )
        );
      } finally {
        addNotification({
          message: `Successfully Moved Row(s) From Table ${sourceTableNameSource} To Table ${sourceTableNameDestination}`,
          variant: "success",
        });
        dispatch({ state: "done", item: "sourceTableRows" });
      }
    },
    [
      activeWorkspace,
      addNotification,
      fetchTableRows,
      projectName,
      renderDialog,
    ]
  );

  const handleLiteralEscapes = (searchString: string) => {
    let characters = Array.from(searchString);
    characters = characters.map((c) => {
      switch (c) {
        case "/":
          return "$F2";
        case "\\":
          return "$5C";
        case "+":
          return "$2B";
        default:
          return c;
      }
    });
    return characters.join("");
  };

  const searchInSourceTables = useCallback(
    async (searchString: string) => {
      if (!activeWorkspace) {
        return;
      }

      try {
        const formattedSearchString = handleLiteralEscapes(searchString);
        dispatch({ state: "loading", item: "sourceTables" });
        const result = await api.searchInSourceTables(
          activeWorkspace.id,
          formattedSearchString
        );
        return result;
      } catch (e) {
        dispatch({ state: "error", item: "sourceTables" });
        addNotification({
          message: `Error occurred while searching in source tables. Error message: ${
            (e as Error).message
          }.`,
          variant: "error",
        });
      } finally {
        dispatch({ state: "done", item: "sourceTables" });
      }
    },
    [activeWorkspace, addNotification]
  );

  const synchronizeSourceTable = useCallback(async () => {
    if (
      !activeWorkspace ||
      !activeWorkspace.ontologyProject ||
      !activeSourceTable
    ) {
      return;
    }
    try {
      dispatch({ state: "loading", item: "sourceTables" });
      await api.syncSourceTable(
        activeWorkspace.ontologyProject,
        activeWorkspace.id,
        activeSourceTable.name
      );
      fetchTableRows();
    } catch (error) {
      dispatch({ state: "error", item: "sourceTables" });
      addNotification({
        message: `Error occured while synchronizing source table. Error message: ${
          (error as Error).message
        }.`,
        variant: "error",
      });
    } finally {
      dispatch({ state: "done", item: "sourceTables" });
    }
  }, [activeSourceTable, activeWorkspace, addNotification, fetchTableRows]);

  /* Fetch source tables when active workspace changes */
  useEffect(() => {
    if (!activeWorkspace) {
      return;
    }

    fetchSourceTables();
  }, [fetchSourceTables, activeWorkspace]);

  /* Fetch table columns and rows when source table changes */
  useEffect(() => {
    if (!activeWorkspace || !activeSourceTable) {
      return;
    }

    localStorage.setItem(
      "lastAccessedSourceTable",
      activeSourceTable.displayName
    );
    fetchTableColumns();
    fetchTableRows();
  }, [activeSourceTable, activeWorkspace, fetchTableColumns, fetchTableRows]);

  /* Navigate to first source table */
  useEffect(() => {
    if (activeWorkspace && !activeSourceTable && sourceTables?.length) {
      const sourceTableFromStorage = localStorage.getItem(
        "lastAccessedSourceTable"
      );
      const containsSouceTable = sourceTables.some(
        (table) => table.displayName === sourceTableFromStorage
      );
      navigate(
        `/editor/${activeWorkspace.id}/${
          containsSouceTable
            ? sourceTableFromStorage
            : sourceTables[0].displayName
        }`,
        {
          replace: true,
        }
      );
    }
  }, [activeSourceTable, activeWorkspace, navigate, sourceTables]);

  /* fetch workspaces if none available (typically on reload) */
  useEffect(() => {
    const fetchWorkspaces = async () => {
      await setWorkspacesFromApi();
    };

    if (
      params.workspaceId &&
      workspaces.state === "hasValue" &&
      !workspaces.contents.find((x) => x.id === params.workspaceId)
    ) {
      fetchWorkspaces();
      navigate(`/editor/${params.workspaceId}` ?? "/");
    }
  }, [
    navigate,
    params.workspaceId,
    setWorkspacesFromApi,
    workspaces.contents,
    workspaces.state,
  ]);

  useEffect(() => {
    setLoadingError(
      sourceTableError || sourceTableColumnError || sourceTableRowError
    );
  }, [sourceTableColumnError, sourceTableError, sourceTableRowError]);

  return {
    sourceTables,
    activeWorkspace,
    activeSourceTable,
    sourceTableColumns,
    sourceTableRows,
    state,
    loadingError,
    fetchSourceTables,
    fetchSourceTablesForWorkspace,
    fetchTableRows,
    addTableRow,
    deleteTableRow,
    deleteTableRows,
    updateTableRow,
    searchInSourceTables,
    moveTableRows,
    updateRowIri,
    synchronizeSourceTable,
  };
};
