import { atom, selector } from "recoil";
import { DialogSettings } from "../../utils/types/dialog";

export const dialogState = atom<DialogSettings | null>({
  key: "dialogState",
  default: null,
});

export const dialogOpenState = selector<boolean>({
  key: "dialogOpenState",
  get: ({ get }) => !!get(dialogState),
});
