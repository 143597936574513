import { ErrorOutline } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProjectMetadata, ProjectVersion } from "../../../api/apiClient";
import { useOntologyContext } from "../../../context/ontologyContext/OntologyContext";
import { useDialog } from "../../../hooks/useDialog/useDialog";
import { useWorkspaces } from "../../../hooks/useWorkspaces/useWorkspaces";
import { ModalComponentProps } from "../../../utils/types/modal";
import { Button } from "../../form";
import { AreaInput } from "../../form/inputboxes/areaInput/AreaInput";
import { VersionNumber } from "../../form/inputboxes/versionNumber/VersionNumber";
import Loader from "../../loader/Loader";
import { Modal } from "../Modal";

export const PublishWorkspaceModal = (props: ModalComponentProps) => {
  const {
    show,
    onClose,
    workspaceId,
    ownerId,
    effect,
    effectDuration,
    projectVersion,
  } = props;
  const [releaseNote, setReleaseNote] = useState("");
  const [validationFailed, setValidationFailed] = useState(false);
  const { publishWorkspace, loading } = useWorkspaces();
  const { openDialog, closeDialog } = useDialog();
  const [publishStarted, setPublishStarted] = useState(false);
  const navigate = useNavigate();
  const { projectName } = useOntologyContext();
  const [changeVersion, setChangeVersion] = useState(false);
  const [majorVersion, setMajorVersion] = useState(projectVersion?.major ?? 0);
  const [minorVersion, setMinorVersion] = useState(projectVersion?.minor ?? 0);
  const buildVersion = projectVersion?.build;

  const publish = async () => {
    if (publishStarted) {
      return;
    }

    if (!!releaseNote && releaseNote.trim().length > 0) {
      if (workspaceId && ownerId) {
        setPublishStarted(true);

        const success = await publishWorkspace(
          workspaceId,
          releaseNote,
          projectVersion
            ? new ProjectMetadata({
                version: new ProjectVersion({
                  major: majorVersion,
                  minor: minorVersion,
                  build: buildVersion ? buildVersion + 1 : 0,
                }),
              })
            : undefined
        );
        if (success) {
          setReleaseNote("");
          navigate("/");
        }
      } else {
        openDialog({
          variant: "error",
          onPrimaryButtonClick: () => publish(),
          onSecondaryButtonClick: () => closeDialog(),
          primaryButtonText: "retry",
          secondaryButtonText: "cancel",
          text: workspaceId
            ? "Could not determine Workspace owner ID"
            : "Could not determine Workspace ID",
        });
      }

      onClose?.();
    } else {
      setValidationFailed(true);
      setReleaseNote("");
    }
  };

  useEffect(() => {
    if (
      projectVersion?.major &&
      projectVersion?.minor &&
      majorVersion === projectVersion.major &&
      minorVersion < projectVersion.minor
    ) {
      setMinorVersion(projectVersion.minor ?? 0);
    }
  }, [
    majorVersion,
    minorVersion,
    projectVersion?.major,
    projectVersion?.minor,
  ]);

  useEffect(() => {
    if (
      projectVersion?.major &&
      projectVersion?.minor &&
      majorVersion > projectVersion.major
    ) {
      setMinorVersion(0);
    }
  }, [majorVersion, projectVersion?.major, projectVersion?.minor]);

  const renderContent = () => {
    return (
      <div className=" px-12 pb-12 pt-4 flex flex-col">
        <div className="pb-4 flex gap-2 items-center">
          <ErrorOutline className="text-yellow-900" fontSize="large" />
          <span className="text-sx italic dark:text-gray-700">
            <span className="font-bold not-italic">Tip: </span>
            <span className="">
              it is preferred to have completed a successful build with current
              changes, before publishing. Publishing a workspace will put it
              into a locked state.
            </span>
          </span>
        </div>

        <AreaInput
          autoFocus={true}
          className="w-full"
          label="Release Note"
          value={releaseNote}
          innerClassName={`${
            validationFailed
              ? "outline-red-700 border-red-700 dark:border-red-700"
              : ""
          }`}
          onChange={(e) => {
            setValidationFailed(false);
            setReleaseNote(e.target.value);
          }}
        />
        {validationFailed && (
          <div className="text-red-700 italic">
            Publish request must have a release note
          </div>
        )}

        {projectVersion && (
          <div className="flex flex-row mt-5 gap-2">
            <input
              type="checkbox"
              checked={changeVersion}
              onChange={() => setChangeVersion((prev) => !prev)}
            ></input>
            <p className="dark:text-white">
              Change Version for Ontology: {projectName}
            </p>
          </div>
        )}

        {changeVersion && projectVersion ? (
          <div className="flex flex-row py-4">
            <VersionNumber
              title="Major"
              value={majorVersion}
              minValue={projectVersion.major ?? 0}
              setValue={setMajorVersion}
            />
            <p className="dark:text-white flex align-bottom items-end px-1">
              .
            </p>
            <VersionNumber
              title="Minor"
              value={minorVersion}
              minValue={
                majorVersion > (projectVersion.major ?? 0)
                  ? 0
                  : projectVersion.minor ?? 0
              }
              setValue={setMinorVersion}
            />
            <p className="dark:text-white flex align-bottom items-end px-1">
              .
            </p>
            <div>
              <div className="flex gap-2">
                <p className="pb-2 dark:text-white">Build Version</p>
                <div title="Build version automatically updates when workspace is published">
                  <ErrorOutline
                    className="text-gray-700 mb-1"
                    fontSize="small"
                  />
                </div>
              </div>
              <p className="h-6 bg-gray-500 w-fit px-2 text-gray-700">
                {buildVersion}
              </p>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="flex flex-row mt-5">
          <Button
            data-testid="Publish button"
            variant="success"
            className="w-44"
            onClick={publish}
          >
            PUBLISH
          </Button>
          <Button
            variant="secondary"
            className="ml-2"
            onClick={() => {
              setValidationFailed(false);
              onClose?.();
            }}
          >
            CANCEL
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      sideModal={false}
      title={"Publish Workspace"}
      onClose={onClose}
      show={show}
      effect={effect}
      effectDuration={effectDuration}
    >
      {loading ? (
        <div className="my-4 flex flex-col gap-6 items-center font-bold dark:text-white">
          <Loader size="5em" />
          Publising Workspace
        </div>
      ) : (
        renderContent()
      )}
    </Modal>
  );
};
