import { useState } from "react";
import {
  SourceTable,
  SourceTableColumn,
  SourceTableRow,
} from "../../../api/apiClient";
import { useInvalidSourceTables } from "../../../hooks/useInvalidSourceTables/useInvalidSourceTables";
import { useSourceTableColumns } from "../../../hooks/useSourceTableColumns/useSourceTableColumns";
import { ModalComponentProps } from "../../../utils/types/modal";
import { Button } from "../../form";
import Loader from "../../loader/Loader";
import { Modal } from "../Modal";

export const MoveRowModal = (props: ModalComponentProps) => {
  const {
    show,
    onClose,
    MoveTableRows,
    rows,
    effect,
    effectDuration,
    workspaceId,
    sourceTableNameSource,
    sourceTables,
  } = props;
  const [destinationTable, setDestinationTable] = useState<SourceTable>();
  const [sourceTable, setSourceTable] = useState<SourceTable>();
  const [dataLossColumns, setDataLossColumns] = useState<string[]>([]);
  const [idColumnIsInconsistent, setIdColumnIsInconsistent] =
    useState<boolean>(false);
  const [destinationColumns, setDestinationColumns] = useState<
    SourceTableColumn[]
  >([]);
  const [checkedSourceTable, setCheckedSourceTable] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const {
    invalidSourceTables,
    updateInvalidSourceTables,
    unknownIris,
    updateUnknownIris,
  } = useInvalidSourceTables();
  const { getSourceTableColumns } = useSourceTableColumns();

  const move = async () => {
    if (
      MoveTableRows &&
      rows &&
      sourceTable &&
      destinationTable &&
      sourceTables
    ) {
      if (workspaceId) {
        setLoading(true);
        let rowsToMove: SourceTableRow[] = [];
        rows.forEach((row) => {
          let rowToMove = new SourceTableRow();

          if (idColumnIsInconsistent) {
            let keys = Object.keys(row.values!);
            const values = Object.values(row.values!);
            keys = keys.slice(1, -1);
            keys = [destinationColumns[0].id, ...keys];

            let alteredRowValues: { [key: string]: string } = {};
            for (let i = 0; i < keys.length; i++) {
              alteredRowValues[keys[i]] = values[i];
            }

            rowToMove.id = row.id;
            rowToMove.values = alteredRowValues;
          } else {
            rowToMove = row;
          }
          rowsToMove = [...rowsToMove, rowToMove];
        });

        await MoveTableRows(
          sourceTable.name,
          destinationTable.name,
          rowsToMove
        );

        updateSourceTableValidation(rowsToMove);
      }
    }

    setLoading(false);
    onClose?.();
  };

  const updateSourceTableValidation = (rows: SourceTableRow[]) => {
    if (sourceTable && destinationTable) {
      rows.forEach((row) => {
        let newInvalids: string[] = [];
        for (let i = 0; i < invalidSourceTables.length; i++) {
          if (invalidSourceTables[i].includes(row.id)) {
            newInvalids = [
              invalidSourceTables[i].replace(
                sourceTable.displayName,
                destinationTable.displayName
              ),
              ...newInvalids,
            ];
          } else {
            newInvalids = [invalidSourceTables[i], ...newInvalids];
          }
        }
        updateInvalidSourceTables(newInvalids);

        let newUnknowns: string[] = [];
        for (let i = 0; i < unknownIris.length; i++) {
          if (unknownIris[i].includes(row.id)) {
            newUnknowns = [
              unknownIris[i].replace(
                sourceTable.displayName,
                destinationTable.displayName
              ),
              ...newUnknowns,
            ];
          } else {
            newUnknowns = [unknownIris[i], ...newUnknowns];
          }
        }
        updateUnknownIris(newUnknowns);
      });
    }
  };

  const determineDataLossColumns = async (destinationTable: string) => {
    if (sourceTables && workspaceId && sourceTableNameSource) {
      setDataLossColumns([]);
      var source = sourceTables.find(
        (table) => table.displayName === sourceTableNameSource
      );
      setSourceTable(source);

      var dest = sourceTables.find(
        (table) => table.displayName === destinationTable
      );
      var sourceColumns = await getSourceTableColumns(
        workspaceId,
        source?.name ?? ""
      );

      var destinationColumns = await getSourceTableColumns(
        workspaceId,
        dest?.name ?? ""
      );

      if (sourceColumns !== undefined && destinationColumns !== undefined) {
        sourceColumns.slice(1, -1).forEach((sourceCol) => {
          const columnExists = destinationColumns!.some(
            (destCol) => destCol.id === sourceCol.id
          );
          if (!columnExists) {
            setDataLossColumns((prev) => [...prev, sourceCol.id]);
          }
        });

        if (sourceColumns[0].id !== destinationColumns[0].id) {
          setIdColumnIsInconsistent(true);
          setDestinationColumns(destinationColumns);
        } else {
          setIdColumnIsInconsistent(false);
        }
      }
    }
  };

  const renderContent = () => {
    return (
      <div className="p-12 pt-6 flex flex-col gap-2">
        <div className="flex">
          <div className="dark:text-white font-bold capitalize">
            From sourcetable:
          </div>
          <div className="pl-2 dark:text-white">{sourceTableNameSource}</div>
        </div>

        <div className="dark:text-white font-bold">To SourceTable:</div>
        <div className="dark:text-white border h-[500px] p-4 overflow-auto">
          {sourceTables
            ?.filter(
              (table) =>
                table.displayName !== sourceTableNameSource && table.hasMetaData
            )
            .map((table) => (
              <div
                key={table.displayName}
                className={`flex gap-2 pb-2 ${
                  checkedSourceTable === table.displayName
                    ? "text-blue-600"
                    : ""
                }`}
              >
                <input
                  type="radio"
                  value={table.displayName}
                  name="sourceTables"
                  key={table.displayName}
                  onChange={() => {
                    setCheckedSourceTable(table.displayName);
                    setDestinationTable(table);
                    determineDataLossColumns(table.displayName);
                  }}
                />
                {table.displayName}
              </div>
            ))}
        </div>

        {dataLossColumns.length > 0 && (
          <div>
            <div className="pt-4 font-bold text-red-700 flex">
              Column Inconsistency Detected
            </div>
            <div className="text-red-700 flex">
              Data in the following columns will be lost in the move:
            </div>
            <div className="pt-2 text-red-700 font-bold flex">
              {dataLossColumns.join(", ")}
            </div>
          </div>
        )}

        <div className="flex flex-row pt-5 gap-2">
          <Button
            data-testid="move_btn"
            className={`w-32 ${
              checkedSourceTable === ""
                ? "bg-gray-700 border-gray-700 hover:bg-gray-700 hover:border-gray-700"
                : ""
            }`}
            disabled={checkedSourceTable === ""}
            onClick={() => move()}
          >
            MOVE
          </Button>
          <Button variant="secondary" onClick={() => onClose?.()}>
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      sideModal={false}
      title="Move Row"
      onClose={() => onClose?.()}
      show={show}
      effect={effect}
      effectDuration={effectDuration}
    >
      {loading ? (
        <div className="my-4 flex flex-col gap-6 items-center font-bold dark:text-white">
          <Loader size="5em" />
          Moving Rows
        </div>
      ) : (
        renderContent()
      )}
    </Modal>
  );
};
