import { Check, Close, PriorityHigh } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { useDialog } from "../../../hooks/useDialog/useDialog";
import { DialogDefaultSettings, DialogType } from "../../../utils/types/dialog";
import { Button } from "../../form";
import { Modal } from "../Modal";
import { DialogProps } from "./Dialog.props";

export const Dialog = (props: DialogProps) => {
  const { closeDialog } = useDialog();
  const [effect, setEffect] = useState(false);
  const effectDuration = 200;

  const defaults = useMemo(
    () => DialogDefaultSettings[props.variant],
    [props.variant]
  );

  const handleClose = async () => {
    setEffect(false);
    await setTimeout(() => closeDialog(), effectDuration);
  };

  useEffect(() => {
    if (props.open) {
      setEffect(true);
    } else {
      setEffect(false);
    }
  }, [props.open]);

  const onPrimaryButtonClick = async () => {
    setEffect(false);
    await setTimeout(() => {
      closeDialog();
      props.onPrimaryButtonClick?.();
    }, effectDuration);
  };

  const onSecondaryButtonClick = async () => {
    if (props.variant !== "success") {
      setEffect(false);
      await setTimeout(() => {
        closeDialog();
        props.onSecondaryButtonClick?.();
      }, effectDuration);
    }
  };

  const renderIcon = (variant: DialogType) => {
    switch (variant) {
      case "confirm":
        return <PriorityHigh fontSize="large" />;
      case "error":
        return <Close fontSize="large" />;
      default:
        return <Check fontSize="large" />;
    }
  };

  return (
    <Modal
      closable={false}
      onClose={handleClose}
      show={props.open}
      effect={effect}
      effectDuration={effectDuration}
    >
      <div className="flex gap-8 items-center flex-col mx-32 my-8 text-gray-900 dark:text-white">
        <div
          className={`flex justify-center items-center h-14 w-14 rounded-full border-[6px] ${defaults.borderColor}`}
        >
          <div className={`${defaults.iconColor}`}>
            {renderIcon(props.variant)}
          </div>
        </div>
        <div className="flex text-lg text-center">{props.text}</div>
        <div className="flex">
          <Button
            autoFocus={true}
            className="min-w-[80px]"
            onClick={() => onPrimaryButtonClick()}
          >
            {props.primaryButtonText}
          </Button>

          {props.variant !== "success" && props.secondaryButtonText ? (
            <Button
              variant="secondary"
              className="ml-2 min-w-[80px]"
              onClick={() => onSecondaryButtonClick()}
            >
              {props.secondaryButtonText}
            </Button>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};
