import { SaveOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { StateIndicatorProps } from "./StateIndicator.props";

export const StateIndicator = (props: StateIndicatorProps) => {
  const { state, saveDate, className, timeout, ...rest } = props;
  const [renderSaveDate, setRenderSaveDate] = useState(true);

  useEffect(() => {
    if (state === "saved") {
      setRenderSaveDate(true);
      setTimeout(() => setRenderSaveDate(false), timeout ?? 2000);
    }
    return () => {
      setRenderSaveDate(false);
    };
  }, [state, timeout]);

  return (
    <div className={`flex items-center ${className}`} {...rest}>
      {state === "saved" && renderSaveDate && (
        <div className="flex gap-1 items-center">
          <SaveOutlined className="text-gray-700" />
          {saveDate && (
            <span className="text-gray-700">
              {format(saveDate, "dd/MM HH:mm:ss")}
            </span>
          )}
        </div>
      )}
      {state === "loading" && (
        <div className="flex text-gray-700 items-center gap-1">
          <div>Loading</div>
          <CircularProgress size={12} color="inherit" />
        </div>
      )}
      {state === "saving" && (
        <div className="flex text-gray-700 items-center gap-1">
          <SaveOutlined className="text-gray-700 " />
          <div>Saving</div>
          <CircularProgress size={12} color="inherit" />
        </div>
      )}
    </div>
  );
};
