import { AreaInputProps } from "./AreaInput.props";

export const AreaInput = (props: AreaInputProps) => {
  const { label, className, innerClassName, labelComponent, ...rest } = props;

  return (
    <div
      className={`grid auto-rows-auto grid-cols-1 items-center justify-start ${className}`}
    >
      {(label || labelComponent) && (
        <div className="flex justify-between">
          <span className="font-bold pb-2 dark:text-white">{label}</span>
          <div>{labelComponent}</div>
        </div>
      )}
      <textarea
        placeholder={props.placeholder}
        className={`px-2 h-32 pt-2 resize-none border disabled:bg-gray-500 placeholder:text-gray-700 dark:bg-dark dark:border-gray-750 dark:text-gray-600 ${innerClassName}`}
        {...rest}
      ></textarea>
    </div>
  );
};
