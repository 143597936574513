import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { InfoBar } from "../../components/InfoBar/InfoBar";
import Loader from "../../components/loader/Loader";
import { Navbar } from "../../components/navbar/Navbar";
import { NotificationList } from "../../components/notifications/notification-list/NotificationList";
import { OntologyContextProvider } from "../../context/ontologyContext/OntologyContext";
import { UserRolesContextProvider } from "../../context/UserRolesContext/UserRolesContext";
import { useAuthentication } from "../../hooks/useAuthentication/useAuthentication";
import { useContextMenu } from "../../hooks/useContextMenu/useContextMenu";
import { useDialog } from "../../hooks/useDialog/useDialog";
import { useEditor } from "../../hooks/useEditor/useEditor";
import { useModal } from "../../hooks/useModal/useModal";
import { useTheme } from "../../hooks/useTheme/useTheme";
import { Builds } from "../builds/Builds";
import { Editor } from "../editor/Editor";
import { Ontologies } from "../ontologies/Ontologies";
import { SettingsPage } from "../settings/SettingsPage";
import { Snapshots } from "../snapshots/Snapshots";
import { Workspaces } from "../workspaces/Workspaces";

export const App = () => {
  const { Dialog, dialogProps } = useDialog();
  const { ContextMenu, contextMenuProps } = useContextMenu();
  const { GenericModal, modalProps } = useModal();
  const { isAuthenticated, loading, error, account } = useAuthentication();
  const { theme } = useTheme();
  const { fetchSourceTablesForWorkspace, state } = useEditor();

  if (!isAuthenticated || loading || error) {
    return (
      <div className="h-screen flex items-center justify-center">
        {error ? <div>{error.errorMessage}</div> : <Loader size="3rem" />}
      </div>
    );
  }

  return (
    <UserRolesContextProvider>
      <OntologyContextProvider>
        <div className={`${theme}`}>
          <div className="text-[15px] text-gray-900 font-body flex flex-col h-screen dark:bg-dark">
            <InfoBar></InfoBar>
            <Navbar username={account?.name ?? "-"} />
            <ReactTooltip
              backgroundColor={`${theme === "dark" ? "#CFDCE6" : "black"}`}
              textColor={`${theme === "dark" ? "black" : "white"}`}
              effect="solid"
              place="top"
              delayShow={500}
              multiline={true}
            />
            <div className="relative h-full overflow-auto">
              <Routes>
                <Route path="/" element={<Ontologies />} />
                <Route
                  path="/workspaces"
                  element={
                    <Suspense
                      fallback={<Loader size="10em" className="mt-24" />}
                    >
                      <Workspaces />
                    </Suspense>
                  }
                />
                <Route path="/editor/:workspaceId" element={<Editor />} />
                <Route
                  path="/editor/:workspaceId/:sourceTable"
                  element={<Editor />}
                />
                <Route
                  path="/workspaces/editor/:workspaceId/snapshots"
                  element={<Snapshots />}
                />
                <Route
                  path="/workspaces/editor/:workspaceId/builds"
                  element={<Builds />}
                />
                <Route
                  path="settings"
                  element={
                    <SettingsPage
                      fetchSourceTablesForWorkspace={
                        fetchSourceTablesForWorkspace
                      }
                      loading={state.sourceTables.loading}
                    />
                  }
                />
              </Routes>

              <NotificationList />
              {dialogProps && <Dialog {...dialogProps} />}
              {contextMenuProps && <ContextMenu {...contextMenuProps} />}
              {modalProps && <GenericModal {...modalProps} />}
            </div>
          </div>
        </div>
      </OntologyContextProvider>
    </UserRolesContextProvider>
  );
};
