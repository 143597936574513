import { Close } from "@mui/icons-material";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { NotificationProps } from "./Notification.props";

export const Notification = (props: NotificationProps) => {
  const {
    className,
    variant = "information",
    message,
    onClose,
    expiredNotifications,
    id,
    expiredCallBack,
  } = props;
  const [effect, setEffect] = useState(false);

  useEffect(() => {
    setEffect(true);
  }, []);

  const handleExpired = useCallback(async () => {
    setEffect(false);
    await setTimeout(() => {
      expiredCallBack?.();
      onClose?.();
    }, 200);
  }, [expiredCallBack, onClose]);

  useEffect(() => {
    if (expiredNotifications?.includes(id)) {
      handleExpired();
    }
  }, [expiredNotifications, handleExpired, id]);

  const classes = classNames(
    className,
    "pl-4 pr-2 min-h-[5rem] min-w-[20rem] max-w-[25rem] w-min h-min rounded-md drop-shadow-lg text-white font-sans text-sm flex justify-between",
    "transform ease-in-out duration-200",
    `${effect ? "opacity-100" : "opacity-0"}`,
    `${variant === "information" ? "bg-blue-700" : ""}`,
    `${variant === "error" ? "bg-red-700" : ""}`,
    `${variant === "success" ? "bg-green-700" : ""}`,
    `${variant === "warning" ? "bg-yellow-900" : ""}`
  );

  return (
    <div className={classes}>
      <span className="py-7 flex items-center">{message}</span>
      <div className="py-2 flex items-start flex-shrink-0">
        <button
          onClick={async () => {
            setEffect(false);
            await setTimeout(() => onClose?.(), 200);
          }}
        >
          <Close />
        </button>
      </div>
    </div>
  );
};
