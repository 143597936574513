import classNames from "classnames";
import { useState } from "react";
import { SnapshotCardProps } from "./SnapshotCard.props";

export const SnapshotCard = (props: SnapshotCardProps) => {
  const { description, creationDate, onLoadSnapshot } = props;
  const [hoverCard, setHoverCard] = useState(false);

  const snapshotClasses = classNames(
    "w-full p-4  max-h-[13rem] cursor-pointer transform ease-in-out duration-200 relative border",
    hoverCard
      ? "text-white bg-blue-700"
      : "bg-gray-600 dark:bg-darkSecondary dark:text-white dark:border-gray-750 "
  );

  return (
    <div
      onMouseEnter={() => setHoverCard(true)}
      onMouseLeave={() => setHoverCard(false)}
      onClick={() => onLoadSnapshot()}
      className={snapshotClasses}
    >
      <div className="flex flex-col h-full">
        <p className="font-bold">{creationDate}</p>
        <p className="mt-1 whitespace-pre-line h-full overflow-auto">
          {description}
        </p>
      </div>
      {hoverCard && (
        <div
          className={`absolute h-6 w-14 top-0 right-0 bg-blue-800  items-center flex`}
        >
          <p className="text-white text-xs mx-auto font-semibold">LOAD</p>
        </div>
      )}
    </div>
  );
};
