import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useDialog } from "../../../hooks/useDialog/useDialog";
import { useSnapshots } from "../../../hooks/useSnapshots/useSnapshots";
import { ModalComponentProps } from "../../../utils/types/modal";
import { Illustration } from "../../form/illustration/Illustration";
import Loader from "../../loader/Loader";
import { SnapshotCard } from "../../snapshotCard/SnapshotCard";
import { Modal } from "../Modal";

export const LoadSnapshotModal = (props: ModalComponentProps) => {
  const { show, onClose, workspaceId, effect, effectDuration, FetchData } =
    props;
  const { snapshots, loadingError, loadSnapshots, applySnapshot } =
    useSnapshots();
  const { openDialog, closeDialog } = useDialog();
  const [loading, setLoading] = useState(false);

  const onLoadSnapshot = async (snapshotId: string) => {
    if (workspaceId) {
      setLoading(true);
      await applySnapshot(workspaceId, snapshotId);
      await FetchData?.();
      setLoading(false);
      onClose?.();
    } else {
      openDialog({
        variant: "error",
        text: "Could not determine Workspace ID",
        primaryButtonText: "retry",
        secondaryButtonText: "cancel",
        onPrimaryButtonClick: () => onLoadSnapshot(snapshotId),
        onSecondaryButtonClick: () => closeDialog(),
      });
    }
  };

  const renderSnapshots = () => {
    if (loadingError) {
      return (
        <div className="h-full flex items-center justify-center">
          <Illustration
            variant="error"
            text="Oops, something went wrong"
            onButtonClick={() => loadSnapshots(workspaceId ?? "")}
          />
        </div>
      );
    }

    if (snapshots?.length !== 0) {
      return snapshots?.map((snapshot, number) => {
        return (
          <SnapshotCard
            onLoadSnapshot={() => onLoadSnapshot(snapshot.id)}
            description={snapshot.description}
            key={number}
            creationDate={format(
              snapshot.createdTimestamp ?? 0,
              "dd-MM-yyyy - HH:mm"
            )}
          />
        );
      });
    }

    return (
      <div className="h-full flex items-center justify-center">
        <Illustration
          variant="information"
          text="You have no Snapshots listed"
        />
      </div>
    );
  };

  useEffect(() => {
    if (workspaceId) {
      loadSnapshots(workspaceId);
    }
  }, [loadSnapshots, workspaceId]);

  return (
    <Modal
      sideModal={true}
      title="Load Snapshot"
      onClose={onClose}
      show={show}
      effect={effect}
      effectDuration={effectDuration}
    >
      <div className="flex flex-col h-full w-full justify-start mt-10 space-y-2 pr-1 overflow-auto">
        {loading ? (
          <div className="h-full flex flex-col justify-center">
            <div>
              <Loader size="5rem" />
            </div>
            <p className="text-center mt-6 font-bold dark:text-white">
              Loading Snapshot
            </p>
          </div>
        ) : (
          renderSnapshots()
        )}
      </div>
    </Modal>
  );
};
