import { Workspace } from "../../api/apiClient";
import { useModal } from "../../hooks/useModal/useModal";
import { Illustration } from "../form/illustration/Illustration";
import { WorkspaceButton } from "../workspaceButton/WorkspaceButton";

interface WorkspacesListProps {
  visibleOwners: string[];
  workspaces: Workspace[];
}

export const WorkspacesList = (props: WorkspacesListProps) => {
  const { workspaces } = props;
  const { openModal } = useModal();

  if (workspaces.length === 0) {
    return (
      <Illustration
        className="w-full"
        variant="interaction"
        text="You Have No Workspaces"
        onButtonClick={() => openModal({ variant: "createWorkspace" })}
      />
    );
  }

  return (
    <>
      {workspaces
        .filter((w) => props.visibleOwners.includes(w.owner))
        .map((w) => (
          <WorkspaceButton
            key={w.id}
            id={w.id}
            ontologyProject={w.ontologyProject}
            caption={w.title}
            state={w.state}
            disabled={false}
            lastUpdated={w.modifiedTimestamp}
            buildCount={w.numberOfBuilds}
            snapshotCount={w.numberOfSnapshots}
            owner={w.owner}
            ownerId={w.ownerId}
            templateVersion={w.templateVersion}
            projectMetaData={w.projectMetadata}
          />
        ))}
    </>
  );
};
