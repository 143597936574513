import { createContext, useContext, useEffect, useState } from "react";

interface OntologyContextProps {
  projectName: string;
  updateProjectName: (name: string) => void;
}

const OntologyContext = createContext<OntologyContextProps>({
  projectName: "",
  updateProjectName: () => {},
});

const useOntologyContext = () => useContext(OntologyContext);

type Props = {
  children?: React.ReactNode;
};

const OntologyContextProvider: React.FC<Props> = ({ children }) => {
  const [projectName, setProjectName] = useState<string>("");

  const updateProjectName = (name: string) => {
    localStorage.setItem("currentProject", name);
    setProjectName(name);
  };

  useEffect(() => {
    const currentProject = localStorage.getItem("currentProject");
    if (currentProject !== null) {
      setProjectName(currentProject);
    }
  }, []);

  return (
    <OntologyContext.Provider
      value={{
        projectName: projectName,
        updateProjectName,
      }}
    >
      {children}
    </OntologyContext.Provider>
  );
};

export { OntologyContext, OntologyContextProvider, useOntologyContext };
