import { useCallback, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { ContextMenu } from "../../components/modals/ContextMenu/ContextMenu";
import { ContextMenuProps } from "../../components/modals/ContextMenu/ContextMenu.props";
import {
  contextMenuOpenState,
  contextMenuState,
} from "../../state/atoms/contextMenuState";
import { ContextMenuSettings } from "../../utils/types/contextMenu";

export const useContextMenu = () => {
  const [contextMenu, setContextMenu] = useRecoilState(contextMenuState);
  const contextMenuOpen = useRecoilValue(contextMenuOpenState);

  const openContextMenu = useCallback(
    (settings: ContextMenuSettings) => setContextMenu(settings),
    [setContextMenu]
  );

  const closeContextMenu = useCallback(() => {
    setContextMenu(null);
  }, [setContextMenu]);

  const contextMenuProps = useMemo((): ContextMenuProps | null => {
    if (!contextMenu) {
      return null;
    }

    return { ...contextMenu, open: contextMenuOpen };
  }, [contextMenu, contextMenuOpen]);

  return {
    openContextMenu,
    closeContextMenu,
    contextMenuOpen,
    contextMenuProps,
    ContextMenu,
  };
};
