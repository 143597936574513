import { ModalComponentProps } from "../../../utils/types/modal";
import { Button } from "../../form";
import { Modal } from "../Modal";

export const DuplicateIdsModal = (props: ModalComponentProps) => {
  const { show, onClose, effect, effectDuration, duplicateIds } = props;

  return (
    <Modal
      sideModal={false}
      title="Detected Duplicate IDs"
      onClose={() => onClose?.()}
      show={show}
      effect={effect}
      effectDuration={effectDuration}
    >
      <div className="p-12 dark:text-white">
        <div className="flex flex-col text-center pb-4">
          <p>
            Duplicate IDs are caused by having identical values in the
            ID-Columns selected for a table.
          </p>
          <p>
            This issue can be resolved by re-configuring the table with a better
            set of ID-Columns.
          </p>
          <p className="text-red-700 font-bold">
            Continuing working while having duplicate IDs can cause unexpected
            errors in the editor.
          </p>
        </div>
        <div className="flex justify-between items-center font-bold border-b">
          <p className="flex justify-center w-1/3">ID</p>
          <p className="flex justify-center w-1/3">Occurrences</p>
          <p className="w-1/3"></p>
        </div>
        <div className=" h-96 overflow-auto">
          {Object.entries(duplicateIds ?? {})?.map(([id, occ]) => (
            <div
              key={id}
              className="p-2 even:bg-gray-500 dark:even:bg-gray-750"
            >
              <div className="flex justify-between items-center ">
                <p className="w-1/3 items-center break-words">{id}</p>
                <p className="flex justify-center w-1/3">{occ}</p>
                <div className="flex justify-center w-1/3">
                  <Button onClick={() => navigator.clipboard.writeText(id)}>
                    Copy ID
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};
