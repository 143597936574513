import { useEffect, useState } from "react";
import { useModal } from "../../../hooks/useModal/useModal";
import { BuildWorkspaceModal } from "../build-workspace/BuildWorkspaceModal";
import { CreateIdColumnsModal } from "../create-idcolumns/CreateIdColumnsModal";
import { CreateSnapshotModal } from "../create-snapshot/CreateSnapshopModal";
import { CreateWorkspaceModal } from "../create-workspace/CreateWorkspaceModal";
import { DuplicateIdsModal } from "../duplicate-ids/DuplicateIdsModal";
import { EditWorkspaceModal } from "../edit-workspace/EditWorkspaceModal";
import { LoadSnapshotModal } from "../load-snapshot/LoadSnapshotModal";
import { MoveRowModal } from "../move-row/MoveRowModal";
import { PublishWorkspaceModal } from "../publish-workspace/PublishWorkspaceModal";
import { RefreshTableModal } from "../refresh-table/RefreshTableModal";
import { SettingsModal } from "../settings-modal/SettingsModal";
import { UpdateIriModal } from "../update-iri/UpdateIriModal";
import { GenericModalComponentProps } from "./GenericModalComponent.props";

export const GenericModal = (props: GenericModalComponentProps) => {
  const [effect, setEffect] = useState(false);
  const effectDuration = 200;
  const { closeModal } = useModal();

  const handleClose = async () => {
    setEffect(false);
    await setTimeout(() => closeModal(), effectDuration);
  };

  useEffect(() => {
    if (props.open) {
      setEffect(true);
    } else {
      setEffect(false);
    }
  }, [props.open]);

  switch (props.variant) {
    case "updateWorkspace":
      return (
        <EditWorkspaceModal
          show={props.open}
          onClose={handleClose}
          workspaceName={props.workspaceName}
          onEditComplete={props.onEditComplete}
          effect={effect}
          effectDuration={effectDuration}
        />
      );
    case "buildWorkspace":
      return (
        <BuildWorkspaceModal
          show={props.open}
          onClose={handleClose}
          effect={effect}
          effectDuration={effectDuration}
          workspaceId={props.workspaceId}
          ownerId={props.ownerId}
        />
      );
    case "publishWorkspace":
      return (
        <PublishWorkspaceModal
          show={props.open}
          onClose={handleClose}
          effect={effect}
          effectDuration={effectDuration}
          workspaceId={props.workspaceId}
          ownerId={props.ownerId}
          projectVersion={props.projectVersion}
        />
      );
    case "createSnapshot":
      return (
        <CreateSnapshotModal
          show={props.open}
          onClose={handleClose}
          workspaceId={props.workspaceId}
          ownerId={props.ownerId}
          effect={effect}
          effectDuration={effectDuration}
        />
      );
    case "loadSnapshots":
      return (
        <LoadSnapshotModal
          show={props.open}
          onClose={handleClose}
          FetchData={props.FetchData}
          workspaceId={props.workspaceId}
          effect={effect}
          effectDuration={effectDuration}
        />
      );
    case "settings":
      return (
        <SettingsModal
          show={props.open}
          onClose={handleClose}
          effect={effect}
          effectDuration={effectDuration}
        />
      );
    case "moveRow":
      return (
        <MoveRowModal
          show={props.open}
          onClose={handleClose}
          effect={effect}
          effectDuration={effectDuration}
          workspaceId={props.workspaceId}
          sourceTableNameSource={props.sourceTableNameSource}
          sourceTables={props.sourceTables}
          rows={props.rows}
          MoveTableRows={props.MoveTableRows}
        />
      );
    case "refreshTable":
      return (
        <RefreshTableModal
          show={props.open}
          onClose={handleClose}
          effect={effect}
          effectDuration={effectDuration}
          workspaceId={props.workspaceId}
          sourceTableId={props.sourceTableId}
          fetchTableRows={props.fetchTableRows}
          fetchExternalTableMetadata={props.fetchExternalTableMetadata}
          refreshExternalTable={props.refreshExternalTable}
        />
      );
    case "duplicateIds":
      return (
        <DuplicateIdsModal
          show={props.open}
          onClose={handleClose}
          effect={effect}
          effectDuration={effectDuration}
          duplicateIds={props.duplicateIds}
        />
      );
    case "updateIri":
      return (
        <UpdateIriModal
          show={props.open}
          onClose={handleClose}
          effect={effect}
          effectDuration={effectDuration}
          rowId={props.rowId}
          UpdateIri={props.UpdateIri}
        />
      );
    case "createIdColumns":
      return (
        <CreateIdColumnsModal
          show={props.open}
          onClose={handleClose}
          effect={effect}
          effectDuration={effectDuration}
          tableColumns={props.tableColumns}
          setIriColumns={props.setIriColumns}
          iriColumns={props.iriColumns}
        />
      );
    default:
      return (
        <CreateWorkspaceModal
          show={props.open}
          onClose={handleClose}
          effect={effect}
          effectDuration={effectDuration}
        />
      );
  }
};
