import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  ApprovalStatus,
  IIriColumn,
  IriColumn,
  SourceTable,
} from "../../../api/apiClient";
import { useDialog } from "../../../hooks/useDialog/useDialog";
import { useExternalTables } from "../../../hooks/useExternalTables/useExternalTables";
import { useModal } from "../../../hooks/useModal/useModal";
import { useWorkspaces } from "../../../hooks/useWorkspaces/useWorkspaces";
import { workspacesState } from "../../../state/atoms/workspacesState";
import { Button } from "../../form";
import { TextInput } from "../../form/inputboxes/inputbox/TextInput";
import { SelectInput } from "../../form/inputboxes/selectInput/SelectInput";
import Loader from "../../loader/Loader";

export const ConfigureExternalTablesSettings = ({
  iriColumnsForTesting,
}: {
  iriColumnsForTesting?: IIriColumn[];
}) => {
  const {
    externalTables,
    loading,
    loadingTables,
    loadingColumns,
    fetchExternalTables,
    fetchExternalTableDataV2,
    fetchExternalTableColumns,
  } = useExternalTables();
  const [externalTableColumns, setExternalTableColumns] = useState<string[]>(
    []
  );
  const { setWorkspacesFromApi, loading: loadingWorkspaces } = useWorkspaces();
  const [checkedTable, setCheckedTable] = useState<SourceTable>();
  const { openDialog, closeDialog } = useDialog();
  const workspaces = useRecoilValue(workspacesState);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<
    string | undefined
  >(workspaces[0]?.id ?? undefined);
  const [idColumns, setIdColumns] = useState<string[]>([]);
  const [namespacePrefix, setNamespacePrefix] = useState<string>();
  const [approvalStatus, setApprovalStatus] = useState<string>(
    ApprovalStatus.Define
  );
  const [query, setQuery] = useState<string>(
    `SELECT * FROM ${checkedTable?.displayName ?? "<TABLE>"}`
  );
  const [invalidNoTable, setInvalidNoTable] = useState(false);
  const [invalidNoQuery, setInvalidNoQuery] = useState(false);
  const [changeNote, setChangeNote] = useState("Initial Load");
  const [iriColumns, setIriColumns] = useState<IIriColumn[]>([]);
  const { openModal } = useModal();

  useEffect(() => {
    if (iriColumnsForTesting && iriColumnsForTesting.length > 0) {
      setIriColumns(iriColumnsForTesting);
    }
  }, [iriColumnsForTesting]);

  const fetchTableData = async () => {
    let invalid = false;
    if (checkedTable === undefined) {
      setInvalidNoTable(true);
      invalid = true;
    }
    if (idColumns.length === 0) {
      invalid = true;
    }
    if (
      query.length === 0 ||
      (checkedTable && !query.includes(checkedTable?.displayName))
    ) {
      setInvalidNoQuery(true);
      invalid = true;
    }

    if (invalid) {
      return;
    }

    if (selectedWorkspaceId === undefined) {
      openDialog({
        variant: "error",
        text: "Could not determine Workspace ID",
        primaryButtonText: "retry",
        secondaryButtonText: "cancel",
        onPrimaryButtonClick: () => fetchTableData,
        onSecondaryButtonClick: () => closeDialog(),
      });
    } else {
      const id = selectedWorkspaceId;
      if (checkedTable && iriColumns) {
        await fetchExternalTableDataV2(
          id,
          checkedTable.name,
          namespacePrefix ?? "",
          approvalStatus,
          query,
          changeNote,
          iriColumns.map(
            (iri) =>
              new IriColumn({
                idColumns: iri.idColumns,
                labelColumn: iri.labelColumn,
              })
          )
        );
      }
    }
  };

  const handleCreateIds = () =>
    openModal({
      variant: "createIdColumns",
      tableColumns: externalTableColumns,
      setIriColumns: (iriColumns) => setIriColumns(iriColumns),
      iriColumns: iriColumns.length > 0 ? iriColumns : undefined,
    });

  const renderContent = () => {
    return (
      <div>
        {loadingWorkspaces ? (
          <div className="mb-4 w-40">
            <Loader size="4rem" />
            <p className="text-center mt-2 font-bold dark:text-white">
              Loading Workspaces
            </p>
          </div>
        ) : (
          <div className="mb-4 w-80">
            <SelectInput
              data-testid="ws-cb"
              label="Choose Workspace"
              onChange={(e) =>
                setSelectedWorkspaceId(
                  workspaces.find((x) => x.title === e.target.value)?.id
                )
              }
            >
              {workspaces.map((ws) => (
                <option key={ws.id}>{ws.title}</option>
              ))}
            </SelectInput>
          </div>
        )}
        <p className="font-bold">Choose table to load into workspace</p>
        <p className="mb-1 text-xs">Already loaded tables are overwritten</p>
        {loadingTables ? (
          <div className="w-52 my-6">
            <Loader size="5rem" />
            <p className="text-center mt-6 font-bold dark:text-white">
              Loading External Tables
            </p>
          </div>
        ) : (
          <div
            className={`p-4 border h-[300px] w-[500px] overflow-auto ${
              invalidNoTable ? "border-red-700" : ""
            }`}
          >
            {externalTables.map((table) => (
              <div className="pb-1 flex gap-2 items-center" key={table.name}>
                <input
                  type="radio"
                  name="externalTables"
                  value={table.name}
                  key={table.name}
                  onChange={async () => {
                    setCheckedTable(table);
                    const externalColumns =
                      (await fetchExternalTableColumns(table.name)) ?? [];
                    setExternalTableColumns(externalColumns);
                    setIdColumns(externalColumns);
                    setQuery(`SELECT * FROM ${table.displayName}`);
                    setInvalidNoTable(false);
                    setInvalidNoQuery(false);
                  }}
                />
                <p
                  className={`${
                    checkedTable?.name === table.name ? "text-blue-600" : ""
                  }`}
                >
                  {table.name}
                </p>
              </div>
            ))}
          </div>
        )}
        {invalidNoTable && (
          <p className="text-red-700 italic">Must select a table to load</p>
        )}

        <div className="mt-6">
          <Button
            data-testid="create_iri_btn"
            onClick={handleCreateIds}
            disabled={!checkedTable || loadingColumns}
            variant="primary"
            className={`${
              (!checkedTable || loadingColumns || loadingTables) &&
              "bg-gray-700 hover:bg-gray-700 border-gray-700 hover:border-gray-700 active:bg-gray-700 active:border-gray-700"
            }`}
          >
            {iriColumns.length > 0 ? "Edit Iri Colums" : "Create Iri columns"}
          </Button>
        </div>

        <TextInput
          label="Namespace Prefix"
          className="pt-5 w-56"
          onChange={(e) => setNamespacePrefix(e.target.value)}
        />

        <SelectInput
          label="Approval Status"
          className="pt-5 w-56"
          onChange={(e) => setApprovalStatus(e.target.value)}
        >
          {Object.keys(ApprovalStatus).map((option) => (
            <option key={option}>{option}</option>
          ))}
        </SelectInput>

        <TextInput
          className="pt-5 w-96 dark:text-white"
          label="Load Comment"
          value={changeNote}
          onChange={(e) => setChangeNote(e.target.value)}
        />

        <TextInput
          className="pt-5 dark:text-white w-96"
          innerClassName={`${
            invalidNoQuery
              ? "outline-red-700 border-red-700 dark:border-red-700"
              : ""
          }`}
          label="Data Fetching Query"
          onChange={(e) => {
            setInvalidNoQuery(false);
            setQuery(e.target.value);
          }}
          value={query}
        />
        {invalidNoQuery && (
          <p className="text-red-700 italic">
            Query should have value and should include selected table name
          </p>
        )}

        <div className="pt-5">
          {loading ? (
            <div className="flex flex-col w-fit justify-center content-center">
              <Loader size="5rem" />
              <p className="pt-2 flex justify-center font-bold dark:text-white">
                Loading Table Into Workspace
              </p>
            </div>
          ) : (
            <Button
              data-testid="load_ext_btn"
              className={`w-32 ${
                (!checkedTable ||
                  !iriColumns ||
                  (iriColumns && iriColumns.length <= 0)) &&
                "bg-gray-700 hover:bg-gray-700 border-gray-700 hover:border-gray-700 active:bg-gray-700 active:border-gray-700"
              }`}
              disabled={
                !checkedTable ||
                !iriColumns ||
                (iriColumns && iriColumns.length <= 0)
              }
              onClick={() => fetchTableData()}
            >
              Load
            </Button>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchExternalTables();
    };

    fetchData();
  }, [fetchExternalTables]);

  useEffect(() => {
    const fetchData = async () => {
      await setWorkspacesFromApi();
    };

    if (workspaces.length === 0) {
      fetchData();
    }
  }, [setWorkspacesFromApi, workspaces.length]);

  useEffect(() => {
    if (workspaces.length > 0) {
      setSelectedWorkspaceId(workspaces[0].id);
    }
  }, [workspaces]);

  return (
    <div className="p-12 flex flex-col dark:text-white">{renderContent()}</div>
  );
};
