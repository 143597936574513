import classNames from "classnames";
import { getButtonVariantClasses } from "../../../utils/buttonVariants";
import { ButtonProps } from "./Button.props";

export const Button = (props: ButtonProps) => {
  const { children, className, variant = "primary", ...rest } = props;

  const classes = classNames(
    className,
    "py-2 px-4 uppercase border font-bold outline-none focus:border-gray-900 text-sm flex items-center justify-center",
    getButtonVariantClasses(variant)
  );

  return (
    <button className={classes} {...rest}>
      {children}
    </button>
  );
};
