import { useCallback, useState } from "react";
import { api } from "../../api";
import {
  ApprovalStatus,
  Build,
  BuildRequest,
  ModelStatus,
} from "../../api/apiClient";
import { useOntologyContext } from "../../context/ontologyContext/OntologyContext";
import { useDialog } from "../useDialog/useDialog";
import { useNotifications } from "../useNotifications/useNotifications";

export const useBuilds = () => {
  const [builds, setBuilds] = useState<Build[]>();
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const { addNotification } = useNotifications();
  const { openDialog, closeDialog } = useDialog();
  const { projectName } = useOntologyContext();

  const renderDialog = useCallback(
    (text: string, retryFunction: () => void) => {
      openDialog({
        variant: "error",
        onPrimaryButtonClick: retryFunction,
        onSecondaryButtonClick: () => closeDialog(),
        text: text,
        primaryButtonText: "retry",
        secondaryButtonText: "close",
      });
    },
    [closeDialog, openDialog]
  );

  const loadBuilds = useCallback(
    async (workspaceId: string) => {
      try {
        setLoading(true);
        setLoadingError(false);
        const response = await api.getBuildsV2(projectName, workspaceId);
        setBuilds(response);
      } catch (e) {
        addNotification({
          message: `Error occured while loading builds. Error message: ${
            (e as Error).message
          }.`,
          variant: "error",
        });
        setLoadingError(true);
      } finally {
        setLoading(false);
      }
    },
    [projectName, addNotification]
  );

  const addBuild = useCallback(
    async (
      workspaceId: string,
      description: string,
      approvalStatuses: ApprovalStatus[],
      modelStatuses: ModelStatus[]
    ) => {
      try {
        const request: BuildRequest = new BuildRequest({
          description: description,
          approvalStatusFilters: approvalStatuses,
          modelStatusFilters: modelStatuses,
        });
        setLoading(true);

        await api.startBuildV2(projectName, workspaceId, request);
        loadBuilds(workspaceId);
        addNotification({
          variant: "success",
          message: "Successfully Scheduled Build",
        });
      } catch (e) {
        renderDialog(
          `Error occured while creating build. Error Message: ${
            (e as Error).message
          }`,
          () =>
            addBuild(workspaceId, description, approvalStatuses, modelStatuses)
        );
      } finally {
        setLoading(false);
      }
    },
    [addNotification, loadBuilds, projectName, renderDialog]
  );

  const downloadBuildLog = useCallback(
    async (workspaceId: string, buildId: string) => {
      try {
        const response = await api.getBuildsLogV2(
          projectName,
          workspaceId,
          buildId
        );
        downloadFileFromApp(response.data, `buildlog-${buildId}.zip`);
      } catch (e) {
        renderDialog(
          `Error occured while downloading build. Error Message: ${
            (e as Error).message
          }`,
          () => downloadBuildLog(workspaceId, buildId)
        );
      }
    },
    [projectName, renderDialog]
  );

  const downloadBuildDrop = useCallback(
    async (workspaceId: string, buildId: string) => {
      try {
        const response = await api.getBuildsDropV2(
          projectName,
          workspaceId,
          buildId
        );
        downloadFileFromApp(response.data, `buildDrop-${buildId}.zip`);
      } catch (e) {
        renderDialog(
          `Error occured while downloading build. Error Message: ${
            (e as Error).message
          }`,
          () => downloadBuildDrop(workspaceId, buildId)
        );
      }
    },
    [projectName, renderDialog]
  );

  const downloadFileFromApp = (data: Blob, endFileName: string) => {
    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", endFileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  return {
    builds,
    loading,
    loadingError,
    loadBuilds,
    addBuild,
    downloadBuildLog,
    downloadBuildDrop,
  };
};
