import { AddBoxOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { FormatColumnsIcon, RefreshIcon } from "../../../assets/images";
import { useDialog } from "../../../hooks/useDialog/useDialog";
import { useModal } from "../../../hooks/useModal/useModal";
import { SearchTermState } from "../../../state/atoms/searchTermState";
import { SourceTablesWithFilterState } from "../../../state/atoms/sourceTablesWithFilter";
import { TextInput } from "../../form/inputboxes/inputbox/TextInput";
import { MultiSelectInput } from "../../form/inputboxes/multiSelectInput/MultiSelectInput";
import { EditorContentToolsProps } from "./EditorContentTools.props";

export const EditorContentTools = (props: EditorContentToolsProps) => {
  const {
    onSearchTextChanged,
    fetchTableRows,
    fetchMetadata,
    refreshExternalTable,
    synchronizeIris,
    sourceTableColumns,
    activeSourceTable,
    activeWorkspace,
  } = props;
  const [filterSearchValue] = useRecoilState(SearchTermState);
  const [filterDefaultValue, setFilterDefaultValue] = useState("");
  const [sourceTablesWithFilter] = useRecoilState(SourceTablesWithFilterState);
  const [highlight, setHighlight] = useState(false);
  const [addingRow, setAddingRow] = useState(false);
  const { openModal } = useModal();
  const { openDialog, closeDialog } = useDialog();

  const addTableRow = async () => {
    setAddingRow(true);
    await props.onAddNewRow();
    setAddingRow(false);
  };

  useEffect(() => {
    if (sourceTablesWithFilter.includes(activeSourceTable?.displayName ?? "")) {
      setFilterDefaultValue(filterSearchValue);
      onSearchTextChanged(filterSearchValue);
      setHighlight(true);
    } else {
      setFilterDefaultValue("");
      onSearchTextChanged("");
      setHighlight(false);
    }
  }, [
    activeSourceTable?.displayName,
    filterSearchValue,
    onSearchTextChanged,
    sourceTableColumns,
    sourceTablesWithFilter,
  ]);

  const sync = async () => {
    if (
      activeWorkspace?.ontologyProject &&
      activeWorkspace?.id &&
      activeSourceTable?.name
    ) {
      synchronizeIris?.(
        activeWorkspace?.ontologyProject,
        activeWorkspace?.id,
        activeSourceTable?.name
      );
    } else {
      openDialog({
        variant: "error",
        text: "Could not determine WorkspaceID or SourceTableID",
        primaryButtonText: "retry",
        secondaryButtonText: "cancel",
        onPrimaryButtonClick: () => sync,
        onSecondaryButtonClick: () => closeDialog(),
      });
    }
  };

  return (
    <div className="mx-4 flex justify-between">
      {props.isExternal ? (
        <button
          disabled={props.disabled}
          className="flex gap-2 items-center group"
          onClick={async () => {
            openModal({
              variant: "refreshTable",
              sourceTableId: activeSourceTable?.name,
              workspaceId: activeWorkspace?.id,
              fetchTableRows: fetchTableRows,
              fetchExternalTableMetadata: fetchMetadata,
              refreshExternalTable: refreshExternalTable,
            });
          }}
        >
          <RefreshIcon className="text-blue-700 group-hover:text-blue-600" />
          <p
            className={`font-bold uppercase dark:text-white ${
              props.disabled ? "text-gray-700 dark:text-gray-700" : ""
            }`}
          >
            Refresh
          </p>
        </button>
      ) : (
        <div className="flex gap-6">
          <button
            disabled={props.disabled}
            onClick={() => {
              if (!addingRow) {
                addTableRow();
              }
            }}
            className="flex items-center gap-1 group"
          >
            <AddBoxOutlined
              className={`text-blue-700 ${
                props.disabled ? "opacity-60" : "group-hover:text-blue-600"
              }`}
            />
            <div
              className={`font-bold uppercase dark:text-white ${
                props.disabled ? "text-gray-700 dark:text-gray-700" : ""
              }`}
            >
              Add New Row
            </div>
          </button>
          {synchronizeIris && (
            <button className="flex items-center gap-2 group" onClick={sync}>
              <RefreshIcon className="text-blue-700 group-hover:text-blue-600" />
              <p
                className={`font-bold uppercase dark:text-white ${
                  props.disabled ? "text-gray-700 dark:text-gray-700" : ""
                }`}
              >
                Synchronize
              </p>
            </button>
          )}
        </div>
      )}

      <div className="flex gap-2 items-center">
        <MultiSelectInput
          growLeft={true}
          display={(x) => x.id}
          searchText={(x) => x.id}
          title="Columns"
          icon={<FormatColumnsIcon className="text-black dark:text-blue-700" />}
          options={props.sourceTableColumns}
          onChange={(columns) => props.onColumnFilterChanged(columns)}
        />
        <TextInput
          innerClassName={`${
            highlight
              ? "bg-blue-700 dark:bg-blue-700 bg-opacity-20 dark:bg-opacity-20"
              : "bg-opacity-0"
          }`}
          value={filterDefaultValue}
          type="search"
          placeholder="Filter table"
          onChange={(e) => {
            setHighlight(false);
            setFilterDefaultValue(e.target.value);
            onSearchTextChanged(e.target.value);
          }}
        />
      </div>
    </div>
  );
};
