import { Menu, Transition } from "@headlessui/react";
import { MoreHoriz } from "@mui/icons-material";
import { Fragment, ReactNode } from "react";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export interface MenuItem {
  icon?: ReactNode;
  textColor?: string;
  label?: String;
  disabled?: Boolean;
  onClick?: () => void;
}

export interface MenuItemProps {
  keyPrefix?: string;
  items?: MenuItem[];
  disabled?: boolean;
}

export default function ContextMenu(props: MenuItemProps) {
  return (
    <Menu
      as="div"
      data-testid="btn-context-menu"
      className="relative align-top"
    >
      <div>
        <Menu.Button>
          <MoreHoriz className="-mr-1 ml-2 -mt-2" fontSize="large" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 -mt-1 shadow-lg bg-white dark:bg-dark ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="">
            {props.items?.map((item, index) => (
              <Menu.Item key={(props?.keyPrefix ?? "") + index}>
                {({ active }) => {
                  const textColor =
                    item.textColor ??
                    `text-gray-900 dark:text-white ${
                      item.disabled ? "hover:bg-gray-700" : "hover:bg-blue-700"
                    } hover:text-white`;
                  return (
                    <button
                      onClick={() => (item?.onClick ? item.onClick() : null)}
                      className={classNames(
                        active ? "text-gray-900" : "text-gray-700",
                        "w-48 block px-2",
                        index === 0
                          ? "pt-2"
                          : props.items && index === props.items?.length - 1
                          ? "pb-2"
                          : ""
                      )}
                    >
                      <span
                        className={`${textColor} p-2 pr-10 font-semibold uppercase flex gap-2 items-center group`}
                      >
                        {item.icon}
                        {item.label}
                      </span>
                    </button>
                  );
                }}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
