import classNames from "classnames";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ProjectVersion, SourceTable } from "../../../api/apiClient";
import { CameraPlusIcon, LookupListIcon } from "../../../assets/images";
import { useEditorActiveData } from "../../../hooks/useEditor/useEditorActiveData/useEditorActiveData";
import { useExternalTables } from "../../../hooks/useExternalTables/useExternalTables";
import { useInvalidSourceTables } from "../../../hooks/useInvalidSourceTables/useInvalidSourceTables";
import { useModal } from "../../../hooks/useModal/useModal";
import { SearchTermState } from "../../../state/atoms/searchTermState";
import { SourceTablesWithFilterState } from "../../../state/atoms/sourceTablesWithFilter";
import { workspacesState } from "../../../state/atoms/workspacesState";
import { useRecoilValueOrDefault } from "../../../state/recoil";
import { DropPanel } from "../../form/dropPanel/DropPanel";
import { TextInput } from "../../form/inputboxes/inputbox/TextInput";
import { SelectInput } from "../../form/inputboxes/selectInput/SelectInput";
import { EditorSidebarButtons } from "../editor-sidebar-buttons/EditorSidebarButtons";
import { EditorSidebarExternalList } from "../editor-sidebar-external-list/EditorSidebarExternalList";
import { EditorSidebarList } from "../editor-sidebar-list/EditorSidebarList";
import { EditorSidebarListItem } from "../editor-sidebar-list/EditorSidebarList.props";
import { EditorSidebarProps } from "./EditorSidebar.props";

export const EditorSidebar = (props: EditorSidebarProps) => {
  const {
    className,
    sourceTables,
    sourceTable,
    workspace,
    fetchTableRows,
    search,
    isReadOnlyWorkspace,
    ...rest
  } = props;
  const { activeWorkspace } = useEditorActiveData(sourceTables);
  const workspaces = useRecoilValueOrDefault(workspacesState, []);
  const navigate = useNavigate();
  const { invalidSourceTables, unknownIris } = useInvalidSourceTables();
  const setSearchTermState = useSetRecoilState(SearchTermState);
  const [sourceTablesWithFilter, setSourceTablesWithFilter] = useRecoilState(
    SourceTablesWithFilterState
  );
  const { openModal } = useModal();
  const [showExternalTables, setShowExternalTables] = useState(false);
  const { externalsEnabled } = useExternalTables();
  const [projectVersion, setProjectVersion] = useState<string>();

  const switchWorkspace = (title: string) => {
    const workspace = workspaces.find((x) => x.title === title);

    if (!workspace) {
      return;
    }

    navigate(`/editor/${workspace.id}`);
  };

  const getProjectVersionLabel = (version?: ProjectVersion) => {
    if (!version || !version.major || !version.minor || !version.build) {
      return undefined;
    }

    return (
      version.major.toString() +
      "." +
      version.minor.toString() +
      "." +
      version.build.toString()
    );
  };

  useEffect(() => {
    setProjectVersion(
      getProjectVersionLabel(activeWorkspace?.projectMetadata?.version)
    );
  }, [activeWorkspace?.projectMetadata?.version]);

  const handleSearch = async (searchString: string) => {
    searchString = searchString.trim();
    if (searchString) {
      const filteredSourceTables = await search(searchString);
      setSourceTablesWithFilter(
        sourceTables
          .filter((s) => filteredSourceTables?.includes(s.displayName))
          .map((fs) => fs.displayName)
      );
      setSearchTermState(searchString);
    } else {
      setSourceTablesWithFilter([]);
      setSearchTermState("");
    }
  };

  const classes = classNames(
    className,
    "bg-gray-400 dark:bg-darkSecondary border-r border-r-gray-600 dark:border-r-gray-750 h-full flex flex-col"
  );

  const getItemState = (sourceTable: SourceTable) => {
    return invalidSourceTables.some(
      (e) =>
        activeWorkspace?.id &&
        e.includes(activeWorkspace?.id + sourceTable.displayName)
    )
      ? "error"
      : unknownIris.some(
          (e) =>
            activeWorkspace?.id &&
            e.includes(activeWorkspace?.id + sourceTable.displayName)
        )
      ? "modified"
      : "saved";
  };

  const listItems: EditorSidebarListItem[] =
    sourceTablesWithFilter.length > 0
      ? sourceTables
          .filter(
            (s) =>
              !s.isExternal && sourceTablesWithFilter.includes(s.displayName)
          )
          .map((x) => ({
            name: x.displayName!,
            state: getItemState(x),
          }))
      : sourceTables
          .filter((s) => !s.isExternal)
          .map((x) => ({
            name: x.displayName!,
            state: invalidSourceTables.some(
              (e) =>
                activeWorkspace?.id &&
                e.includes(activeWorkspace?.id + x.displayName)
            )
              ? "error"
              : unknownIris.some(
                  (e) =>
                    activeWorkspace?.id &&
                    e.includes(activeWorkspace?.id + x.displayName)
                )
              ? "modified"
              : "saved",
          }));

  const renderExternalsPanel =
    sourceTablesWithFilter.length === 0 &&
    sourceTables.some((s) => s.isExternal) &&
    externalsEnabled;

  const externalTables =
    sourceTablesWithFilter.length > 0
      ? sourceTables.filter(
          (s) => s.isExternal && sourceTablesWithFilter.includes(s.displayName)
        )
      : sourceTables.filter((s) => s.isExternal);

  const isShowingExternalTables =
    !sourceTables.some((s) => s.isExternal) ||
    sourceTablesWithFilter.length > 0 ||
    !externalsEnabled;

  return (
    <section className={classes} {...rest}>
      <div className="mt-4 mx-4 space-y-4">
        <SelectInput
          label="Workspace"
          secondaryLabel={projectVersion}
          value={workspace?.title ?? ""}
          onChange={(e) => switchWorkspace(e.target.value)}
          labelComponent={
            <div className="flex gap-3">
              <button
                data-tip="Load Snapshot"
                disabled={isReadOnlyWorkspace}
                onClick={() =>
                  openModal({
                    variant: "loadSnapshots",
                    workspaceId: activeWorkspace?.id,
                    FetchData: fetchTableRows,
                  })
                }
              >
                <LookupListIcon
                  className={`text-blue-700  ${
                    isReadOnlyWorkspace
                      ? "opacity-60 text-gray-750"
                      : "hover:text-blue-600"
                  }`}
                />
              </button>
              <button
                data-tip="Create Snapshot"
                disabled={isReadOnlyWorkspace}
                onClick={() =>
                  openModal({
                    variant: "createSnapshot",
                    workspaceId: activeWorkspace?.id,
                    ownerId: activeWorkspace?.ownerId,
                  })
                }
              >
                <CameraPlusIcon
                  className={`text-blue-700 ${
                    isReadOnlyWorkspace
                      ? "opacity-60 text-gray-750"
                      : "hover:text-blue-600"
                  }`}
                />
              </button>
            </div>
          }
        >
          {workspaces.map((ws) => (
            <option key={ws.id}>{ws.title}</option>
          ))}
        </SelectInput>

        <TextInput
          data-testid="sourceTableSearchInput"
          onBlur={(e) => handleSearch(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSearch(e.currentTarget.value);
            }
          }}
          type="search"
          placeholder="Search"
          label="Search in Source Tables"
        />
      </div>
      <EditorSidebarList
        sourceTable={sourceTable}
        workspace={workspace}
        items={listItems}
        tablesInSearch={sourceTablesWithFilter.length}
      />

      {renderExternalsPanel && (
        <div className="mt-auto">
          <DropPanel
            title="External Tables"
            onClick={() => setShowExternalTables((prev) => !prev)}
          />
        </div>
      )}

      {(showExternalTables || sourceTablesWithFilter.length > 0) && (
        <EditorSidebarExternalList
          className={`${sourceTablesWithFilter.length > 0 ? "mt-12" : ""}`}
          sourceTable={sourceTable}
          tables={externalTables}
          workspaceId={workspace?.id}
        />
      )}

      <div className={`${isShowingExternalTables ? "mt-auto" : "-mt-2"}`}>
        <EditorSidebarButtons
          workspaceId={activeWorkspace?.id ?? ""}
          ontologyProject={activeWorkspace?.ontologyProject}
          ownerId={activeWorkspace?.ownerId ?? ""}
          disabled={isReadOnlyWorkspace}
          projectVersion={activeWorkspace?.projectMetadata?.version}
        />
      </div>
    </section>
  );
};
