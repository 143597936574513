import { EditOffOutlined, ErrorOutline, Link } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useState } from "react";
import ScrollBox from "react-responsive-scrollbox";
import { Cell } from "react-table";
import ReactTooltip from "react-tooltip";
import {
  ModelStatus,
  SourceTableColumn,
  SourceTableRow,
} from "../../../api/apiClient";
import { useDialog } from "../../../hooks/useDialog/useDialog";
import { useExternalTables } from "../../../hooks/useExternalTables/useExternalTables";
import { useInvalidSourceTables } from "../../../hooks/useInvalidSourceTables/useInvalidSourceTables";
import { useModal } from "../../../hooks/useModal/useModal";
import { useWorkspaces } from "../../../hooks/useWorkspaces/useWorkspaces";
import { GovernanceColumns } from "../../../utils/types/governanceColumns";
import { Illustration } from "../../form/illustration/Illustration";
import { InfoPanel } from "../../form/infoPanel/InfoPanel";
import { Table } from "../../table/Table";
import { EditorTableCell } from "../../table/table-cell/editor-table-cell/EditorTableCell";
import { Title } from "../../title/Title";
import { EditorContentTools } from "../editor-content-tools/EditorContentTools";
import { StateIndicator } from "../editor-sidebar-state-indicator/StateIndicator";
import { EditorToolbar } from "../editor-toolbar/EditorToolbar";
import { DuplicateIdsButton } from "./DuplicateIdsButton";
import { EditorContentProps } from "./EditorContent.props";
import { MetadataPanel } from "./MetadataPanel";
import "./ScrollBoxStyle.css";

export const EditorContent = (props: EditorContentProps) => {
  const [selectedRows, setSelectedRows] = useState<SourceTableRow[]>([]);
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
  const [visibleColumns, setVisibleColumns] = useState<SourceTableColumn[]>([]);
  const [newlyAddedRowIndexes, setNewlyAddedRowIndexes] = useState<number[]>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [lastSaveDate, setLastSaveDate] = useState<Date>();
  const { openDialog, closeDialog } = useDialog();
  const { openModal } = useModal();
  const { unlockWorkspace } = useWorkspaces();
  const [scrollToTop, setScrollToTop] = useState(false);
  const [excludedRows, setExcludedRows] = useState<string[]>([]);
  const [columnWidths, setColumnWidths] = useState<{ [key: string]: number }>(
    {}
  );
  const {
    invalidSourceTables,
    updateInvalidSourceTables,
    unknownIris,
    updateUnknownIris,
  } = useInvalidSourceTables();
  const {
    fetchExternalTableMetadata,
    refreshExternalTable,
    externalTableMetadata,
    loadingMetaData,
  } = useExternalTables();

  const isReadOnlyCell = useCallback(
    (columnId: string) =>
      props.isReadOnlyWorkspace ||
      ((!props.sourceTable?.hasMetaData ?? false) &&
        (!props.sourceTable?.isExternal ?? false)) ||
      ((props.sourceTable?.isExternal ?? false) &&
        columnId !== GovernanceColumns.ModelStatus),
    [
      props.isReadOnlyWorkspace,
      props.sourceTable?.hasMetaData,
      props.sourceTable?.isExternal,
    ]
  );

  const handleCellValueChanged = useCallback(
    (newValue: string, row: SourceTableRow, columnId: string) => {
      row.values = row.values ?? {};
      row.values[columnId] = newValue;
      props.updateTableRow(row.id, row, props.sourceTable?.isExternal);
    },
    [props]
  );

  const data = useMemo<SourceTableRow[]>(() => {
    const rows = props.sourceTableRows;
    setExcludedRows(
      rows
        .filter(
          (row) =>
            row.values !== undefined &&
            (row.values[GovernanceColumns.ModelStatus] ===
              ModelStatus.Deprecate ||
              row.values[GovernanceColumns.ModelStatus] ===
                ModelStatus.Withdrawn)
        )
        .map((row) => row.id)
    );
    return props.sourceTableRows;
  }, [props.sourceTableRows]);

  const columns = useMemo(() => {
    const labelForTargets = Object.assign(
      {},
      ...props.sourceTableColumns
        .filter((col) => col.labelFor)
        .map((col) => ({ [col.labelFor!]: col.id }))
    );
    return props.sourceTableColumns.map((column) => ({
      Header: column.id,
      id: column.id,
      width:
        columnWidths[column.id] ??
        // default width is estimated from (currentScreenSize - sidebar.minWidth + constant of 50px) / amount of columns
        Math.max(
          (window.innerWidth - 350) / props.sourceTableColumns.length,
          200
        ),
      minWidth: column.id.length * 10 + 70, // rough estimate for minimum size
      Description: () => (
        <div className="ml-auto flex items-center gap-1 text-gray-700">
          {(column.readOnly || isReadOnlyCell(column.id)) && (
            <EditOffOutlined
              fontSize="small"
              data-tip="Fields in this column is read-only"
            />
          )}
          {column.labelFor && (
            <Link
              fontSize="small"
              data-tip={`This column contains labels for column '${column.labelFor}'`}
            />
          )}
          {Object.keys(labelForTargets).some((key) => key === column.id) && (
            <Link
              fontSize="small"
              data-tip={`This column has labels in column '${
                labelForTargets[column.id]
              }'`}
            />
          )}
          {column.description && (
            <ErrorOutline fontSize="small" data-tip={column.description} />
          )}
        </div>
      ),
      Cell: (cell: Cell<SourceTableRow>) => (
        <EditorTableCell
          key={`${cell.row.original.id}-${cell.column.id}`}
          cell={cell}
          column={column}
          onCellChanged={handleCellValueChanged}
          readOnlyMode={isReadOnlyCell(cell.column.id)}
          labelValue={
            cell.row.original.values
              ? cell.row.original.values[labelForTargets[column.id]]
              : undefined
          }
          sourceTableName={props.sourceTable?.displayName ?? ""}
          rowId={cell.row.original.id}
          workspaceId={props.workspace?.id ?? ""}
          isExcluded={excludedRows.some((id) => id === cell.row.original.id)}
          isExternal={props.sourceTable?.isExternal ?? false}
        />
      ),
      accessor: (row: SourceTableRow) => row.values?.[column.id],
    }));
  }, [
    columnWidths,
    excludedRows,
    handleCellValueChanged,
    isReadOnlyCell,
    props.sourceTable?.displayName,
    props.sourceTable?.isExternal,
    props.sourceTableColumns,
    props.workspace?.id,
  ]);

  const handleColumnResize = (
    id: string,
    width: number | string | undefined
  ) => {
    if (!Number.isNaN(Number(width))) {
      const widths = columnWidths;
      widths[id] = Number(width);
      setColumnWidths(widths);
    }
  };

  const loading = useMemo(
    () =>
      props.state.sourceTables.loading ||
      props.state.sourceTableColumns.loading ||
      props.state.sourceTableRows.loading,
    [
      props.state.sourceTables.loading,
      props.state.sourceTableColumns.loading,
      props.state.sourceTableRows.loading,
    ]
  );

  const saving = useMemo(
    () =>
      props.state.sourceTableRows.saving ||
      props.state.sourceTableColumns.saving ||
      props.state.sourceTables.saving,
    [
      props.state.sourceTableColumns.saving,
      props.state.sourceTableRows.saving,
      props.state.sourceTables.saving,
    ]
  );

  const saved = useMemo(
    () =>
      props.state.sourceTableRows.saved ||
      props.state.sourceTableColumns.saved ||
      props.state.sourceTables.saved,
    [
      props.state.sourceTableColumns.saved,
      props.state.sourceTableRows.saved,
      props.state.sourceTables.saved,
    ]
  );

  const stateIndicatorState = useMemo(() => {
    if (loading) {
      return "loading";
    }

    if (saving) {
      return "saving";
    }

    if (lastSaveDate !== undefined) {
      return "saved";
    }

    return "none";
  }, [saving, loading, lastSaveDate]);

  useEffect(() => {
    if (saved) {
      setLastSaveDate(props.workspace?.modifiedTimestamp ?? new Date());
    }
  }, [props.workspace?.modifiedTimestamp, saved]);

  const handleColumnFilterChanged = useCallback(
    (visibleColumns: SourceTableColumn[]) => setVisibleColumns(visibleColumns),
    []
  );

  const handleSearchTextChanged = useCallback(
    (filter: any) => setGlobalFilter(filter),
    []
  );

  const handleInvalidIRIs = useCallback(
    (rows: SourceTableRow[]) => {
      rows.forEach((row) => {
        let newInvalids: string[] = [];
        invalidSourceTables.forEach((invalid) => {
          if (!invalid.includes(row.id)) {
            newInvalids = [invalid, ...newInvalids];
          }
        });
        updateInvalidSourceTables(newInvalids);

        let newUnknowns: string[] = [];
        unknownIris.forEach((unknown) => {
          if (!unknown.includes(row.id)) {
            newUnknowns = [unknown, ...newUnknowns];
          }
        });
        updateUnknownIris(newUnknowns);
      });
    },
    [
      invalidSourceTables,
      unknownIris,
      updateInvalidSourceTables,
      updateUnknownIris,
    ]
  );

  const handleMoveRow = (row: SourceTableRow) =>
    openModal({
      variant: "moveRow",
      workspaceId: props.workspace?.id,
      sourceTableNameSource: props.sourceTable?.displayName,
      sourceTables: props.sourceTables,
      rows: [row],
      MoveTableRows: props.moveTableRows,
    });

  const handleUpdateIri = (rowId: string) =>
    openModal({
      variant: "updateIri",
      rowId: rowId,
      UpdateIri: props.updateIri,
    });

  const handleDeleteRows = props.sourceTable?.isExternal
    ? undefined
    : () =>
        openDialog({
          variant: "confirm",
          primaryButtonText: "Delete",
          secondaryButtonText: "Cancel",
          text: `Do you really wish to delete ${
            selectedRows.length === 1 ? "this row" : "these rows"
          }?`,
          onPrimaryButtonClick: () => {
            selectedRows.length > 1
              ? props.deleteTableRows(selectedRows.map((row) => row.id))
              : props.deleteTableRow(selectedRows[0].id);
            handleInvalidIRIs(selectedRows);
          },
          onSecondaryButtonClick: () => closeDialog(),
        });

  useEffect(() => {
    const fetchData = async (workspaceId: string, sourceTableName: string) => {
      await fetchExternalTableMetadata(workspaceId, sourceTableName);
    };

    if (
      props.sourceTable &&
      props.sourceTable.isExternal &&
      props.workspace &&
      props.workspace.id
    ) {
      fetchData(props.workspace.id, props.sourceTable.name);
    }
  }, [fetchExternalTableMetadata, props.sourceTable, props.workspace]);

  useEffect(() => {
    // delay to allow table to fully render before rebuilding tooltip
    setTimeout(() => ReactTooltip.rebuild(), 500);
  }, [columns, visibleColumns]);

  return (
    <div className="flex flex-col flex-1">
      {props.isReadOnlyWorkspace && props.workspace !== undefined && (
        <div className="flex justify-center">
          <InfoPanel
            unlockWorkspace={unlockWorkspace}
            workspace={props.workspace}
          />
        </div>
      )}
      <div className={`p-5 ${props.isReadOnlyWorkspace ? "pt-0" : ""}`}>
        <div className="flex justify-between">
          <div className="flex gap-3 items-center">
            <Title
              text={props.sourceTable?.displayName ?? "Error in Source Table"}
            />
            {!props.isExternalTable && !props.sourceTable?.hasMetaData && (
              <ErrorOutline
                className="text-red-700"
                data-tip="Source table editing disabled.<br>This source table does not have an associated metadata file.<br>Please contact administrator"
              />
            )}
            <DuplicateIdsButton
              duplicateIds={externalTableMetadata?.duplicateIds}
              isExternal={props.sourceTable?.isExternal}
            />
            <StateIndicator
              state={stateIndicatorState}
              saveDate={lastSaveDate}
            />
          </div>
          {props.sourceTable?.isExternal && (
            <MetadataPanel
              isLoading={loadingMetaData}
              externalMetadata={externalTableMetadata}
            />
          )}
        </div>
        <hr className="mt-4 text-gray-600 dark:text-gray-750" />
      </div>

      <EditorContentTools
        sourceTableColumns={props.sourceTableColumns}
        onAddNewRow={async () => {
          await props.addTableRow();
          setNewlyAddedRowIndexes([0]);
          setScrollToTop(true);
        }}
        fetchTableRows={props.fetchTableRows}
        fetchMetadata={fetchExternalTableMetadata}
        refreshExternalTable={refreshExternalTable}
        onSearchTextChanged={handleSearchTextChanged}
        onColumnFilterChanged={handleColumnFilterChanged}
        synchronizeIris={async (ontologyProject, workspaceId, sourceTableId) =>
          await props.synchronizeSourceTable?.(
            ontologyProject,
            workspaceId,
            sourceTableId
          )
        }
        activeSourceTable={props.sourceTable}
        activeWorkspace={props.workspace}
        disabled={
          props.isReadOnlyWorkspace ||
          (!props.sourceTable?.hasMetaData && !props.sourceTable?.isExternal)
        }
        isExternal={props.sourceTable?.isExternal}
      />

      {props.hasError ? (
        <div className="h-full mt-10">
          <Illustration
            variant="error"
            text="Oops, something went wrong"
            onButtonClick={() => window.location.reload()}
          />
        </div>
      ) : (
        <div className="h-full mt-5 overflow-hidden">
          <ScrollBox className="scrollbar-style">
            <Table
              rows={data}
              selectable={true}
              onRowSelected={(rows) => setSelectedRows(rows)}
              onIndexSelected={(indexes) => setSelectedIndexes(indexes)}
              columns={columns as any as SourceTableRow[]}
              sourceTableColumns={props.sourceTableColumns}
              visibleColumns={visibleColumns.map((x) => x.id)}
              filter={globalFilter}
              enableCustomContextMenu={
                !props.isReadOnlyWorkspace && props.sourceTable?.hasMetaData
              }
              addNewRow={(index) => props.addTableRow(index)}
              duplicateRow={(index, values) => props.addTableRow(index, values)}
              updateRow={(rowId, values) => props.updateTableRow(rowId, values)}
              deleteRow={(id) => props.deleteTableRow(id)}
              moveRow={handleMoveRow}
              updateIri={handleUpdateIri}
              newlyAddedRowIndexes={newlyAddedRowIndexes}
              highlightingDoneCallback={() => setNewlyAddedRowIndexes([])}
              onColumnResize={(
                id: string,
                width: number | string | undefined
              ) => handleColumnResize(id, width)}
              useDynamicRowHeight={true}
              shouldScrollToTop={scrollToTop}
              scrollCallback={() => setScrollToTop(false)}
              isFromExternal={props.sourceTable?.isExternal}
            />
          </ScrollBox>
        </div>
      )}

      <EditorToolbar
        workspace={props.workspace}
        sourceTable={props.sourceTable}
        sourceTables={props.sourceTables}
        itemsCount={data.length}
        selectedRows={selectedRows}
        selectedIndexes={selectedIndexes}
        addTableRow={props.addTableRow}
        setRowIndexes={(indexes) => setNewlyAddedRowIndexes(indexes)}
        disabled={props.isReadOnlyWorkspace || !props.sourceTable?.hasMetaData}
        onDelete={handleDeleteRows}
        moveRows={props.moveTableRows}
        updateTableRow={props.updateTableRow}
        onExclude={(rowId) =>
          setExcludedRows((prev) => {
            if (!prev.some((id) => id === rowId)) {
              return [...prev, rowId];
            }

            return prev;
          })
        }
        onInclude={(rowId) =>
          setExcludedRows((prev) => prev.filter((id) => id !== rowId))
        }
        className="flex-shrink-0"
      />
    </div>
  );
};
