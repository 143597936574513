import { Close } from "@mui/icons-material";
import classNames from "classnames";
import { Title } from "../title/Title";
import { ModalProps } from "./Modal.props";

export const Modal = (props: ModalProps) => {
  const {
    children,
    show,
    closable = true,
    onClose,
    title,
    sideModal = false,
    effect,
    effectDuration,
  } = props;

  const modalClasses = classNames(
    "p-6 bg-white dark:bg-dark flex flex-col",
    sideModal
      ? `absolute inset-y-0 transform ease-in-out duration-${
          effectDuration ?? 0
        } right-0 ${
          effect ? "w-[27rem]" : effect === undefined ? "w-[27rem]" : "w-0"
        } `
      : "relative w-4/5 max-w-3xl"
  );

  if (!show) {
    return null;
  }

  return (
    <div
      className={`transform ease-in-out duration-${
        effectDuration ?? 200
      } bg-black bg-opacity-70 flex flex-col justify-center items-center fixed inset-0 z-50 outline-none focus:outline-none ${
        effect ? "opacity-100" : "opacity-0"
      } ${effect === undefined ? "opacity-100" : ""}`}
      onClick={() => onClose?.()}
    >
      <div className={modalClasses} onClick={(e) => e.stopPropagation()}>
        <div className="flex mb-2">
          {title && <Title text={title} />}
          {closable && (
            <button
              onClick={() => onClose?.()}
              className="ml-auto h-8 w-8 -mt-2 -mr-2 text-gray-800 dark:text-white flex justify-center items-center hover:bg-gray-600 dark:hover:bg-gray-700 cursor-pointer rounded-full"
            >
              <Close />
            </button>
          )}
        </div>

        {children}
      </div>
    </div>
  );
};
