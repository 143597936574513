import { TextInputProps } from "./TextInput.props";

export const TextInput = (props: TextInputProps) => {
  const { className, innerClassName, label, labelComponent, ...rest } = props;

  return (
    <div
      className={`grid auto-rows-auto grid-cols-1 items-center justify-start ${className} `}
    >
      {(label || labelComponent) && (
        <div className="flex justify-between">
          <span className="font-bold pb-2 dark:text-white">{label}</span>
          <div>{labelComponent}</div>
        </div>
      )}
      <input
        className={`
        px-2 h-8 
        border focus:rounded-none outline-offset-0 
        disabled:bg-gray-500 placeholder:text-gray-700 
        dark:text-gray-600 dark:bg-darkSecondary dark:border-gray-750
         ${innerClassName}`}
        {...rest}
      />
    </div>
  );
};
