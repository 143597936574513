import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CellProps,
  Row,
  useFlexLayout,
  useGlobalFilter,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from "react-window";
import { useRecoilValue } from "recoil";
import { SourceTableRow } from "../../api/apiClient";
import { ArrowDownIcon, ArrowUpIcon } from "../../assets/images";
import { SearchTermState } from "../../state/atoms/searchTermState";
import { IndeterminateCheckbox } from "../form/indeterminate-checkbox/IndeterminateCheckbox";
import { TableRow } from "./table-row/TableRow";
import { TableProps } from "./Table.props";
import "./TableVerticalScrollStyle.css";

export const Table = <T extends object = {}>(props: TableProps<T>) => {
  const onRowSelectedRef = useRef(props.onRowSelected);
  const onIndexesSelectedRef = useRef(props.onIndexSelected);
  const listRef = useRef<VariableSizeList>(null);
  const internalData = useMemo(() => props.rows ?? [], [props.rows]);
  const [rowsToHighlight, setRowsToHighlight] = useState<number[]>();
  const searchTerm = useRecoilValue(SearchTermState);
  const [columnWidths, setColumnWidths] = useState<{ [key: string]: number }>(
    {}
  );
  const defaultRowHeight = 35;

  const tableInstance = useTable(
    { columns: props.columns, data: internalData },
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    useResizeColumns,
    useFlexLayout,
    (hooks) => {
      if (props.selectable) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            width: 50,
            minWidth: 50,
            maxWidth: 50,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div className="flex items-center w-full justify-center">
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }: CellProps<T>) => (
              <div className="flex items-center w-full justify-center">
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    setHiddenColumns,
    setGlobalFilter,
  } = tableInstance;

  // Currently only works if row is of type SourceTableRow - returns defaultHeight otherwise
  const getRowHeight = (row: Row<T>) => {
    let rowHeight = defaultRowHeight;
    if (props.useDynamicRowHeight) {
      const rowValues = (row.original as SourceTableRow).values;
      headerGroups.forEach((headerGroup) =>
        headerGroup.headers.forEach((column, index) => {
          if (index > 0) {
            if (rowValues) {
              const newColumnWidths = columnWidths;
              newColumnWidths[column.id!] = Math.max(
                Number(column.width),
                Number(column.minWidth)
              );
              setColumnWidths(newColumnWidths);
              const text = rowValues[column.id as string];

              const hiddenElement = document.getElementById(
                `${column.id}-hiddenElement`
              );
              if (hiddenElement) {
                hiddenElement.textContent = text;
                const result = hiddenElement.scrollHeight + 30; // equivalent to content height + 15px of padding
                if (result > rowHeight) {
                  rowHeight = result;
                }
              }
            }
          }
        })
      );
    }

    return rowHeight;
  };

  useEffect(() => {
    setRowsToHighlight(props.newlyAddedRowIndexes);
  }, [props.newlyAddedRowIndexes]);

  useEffect(() => {
    if (!props.visibleColumns) {
      setHiddenColumns([]);
    } else {
      setHiddenColumns(
        props.columns
          .filter((x) => !props.visibleColumns?.includes(x.id ?? ""))
          .map((x) => x.id!)
      );
    }
  }, [props.columns, props.visibleColumns, setHiddenColumns]);

  useEffect(() => {
    onRowSelectedRef.current?.(selectedFlatRows.map((x) => x.original));
    onIndexesSelectedRef.current?.(selectedFlatRows.map((x) => x.index));
  }, [selectedFlatRows]);

  useEffect(
    () => setGlobalFilter(props.filter),
    [props.filter, setGlobalFilter, props.columns, searchTerm]
  );

  useEffect(() => {
    if (props.shouldScrollToTop) {
      if (props.rows?.length && props.rows.length > 0) {
        listRef.current?.scrollToItem(0, "start");
        props.scrollCallback?.();
      }
    }
  }, [props, props.shouldScrollToTop]);

  const handleResize = useCallback(() => {
    headerGroups.forEach((headerGroup) =>
      headerGroup.headers.forEach((column) => {
        props.onColumnResize?.(column.id, column.width);
      })
    );
  }, [headerGroups, props]);

  useEffect(() => {
    if (props.useDynamicRowHeight) {
      setInterval(() => listRef.current?.resetAfterIndex(0, true), 100);
    }
  }, [props.useDynamicRowHeight]);

  useEffect(() => {
    document.addEventListener("mouseup", handleResize, true);

    return () => {
      document.removeEventListener("mouseup", handleResize, true);
    };
  }, [handleResize, headerGroups]);

  return (
    <div className="h-full flex flex-col overflow-hidden" {...getTableProps()}>
      {/* --- hidden element used to calculate row height at run-time --- */}
      {props.columns.map((column, index) => (
        <div
          key={index}
          id={`${column.id}-hiddenElement`}
          style={{
            width: columnWidths[column.id!],
            height: 0,
            flexWrap: "wrap",
            wordWrap: "break-word",
            visibility: "hidden",
          }}
          className={`mx-3`}
        >
          placeHolder
        </div>
      ))}
      {/* --- end of hidden element ---*/}
      <div className="bg-gray-500 dark:bg-darkSecondary dark:text-white font-bold border-gray-600 dark:border-gray-750 border-t border-b">
        {headerGroups.map((headerGroup) => (
          <div {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <div
                className="border-gray-600 dark:border-gray-750 border-r select-none h-[35px]"
                {...column.getHeaderProps(
                  column.getSortByToggleProps({ title: "" })
                )}
              >
                <div className="flex h-full items-center px-2">
                  <div className="flex w-full items-center">
                    {column.render("Header")}
                    <div
                      {...column.getResizerProps()}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      className="w-[5px] z-10 absolute h-full -right-[2.5px]"
                    />
                    {column.canSort && column.isSorted && (
                      <span className="ml-2 text-blue-700 text-xs">
                        <span className="flex items-center">
                          {column.isSortedDesc ? (
                            <ArrowDownIcon />
                          ) : (
                            <ArrowUpIcon />
                          )}
                          <span className="ml-1">
                            {Math.max(
                              ...headerGroup.headers.map((x) => x.sortedIndex)
                            ) > 0 && column.sortedIndex + 1}
                          </span>
                        </span>
                      </span>
                    )}
                  </div>

                  {column.Description && column.render("Description")}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="flex-1" {...getTableBodyProps()}>
        <AutoSizer>
          {({ height, width }) => (
            <VariableSizeList
              ref={listRef}
              className="keep-scrolling"
              height={height}
              itemCount={rows.length}
              itemData={{
                rows,
                prepareRow,
                enableCustomContextMenu: props.enableCustomContextMenu,
                sourceTableColumns: props.sourceTableColumns,
                addNewRow: props.addNewRow,
                deleteRow: props.deleteRow,
                duplicateRow: props.duplicateRow,
                updateRow: props.updateRow,
                moveRow: props.moveRow,
                updateIri: props.updateIri,
                isFromExternal: props.isFromExternal,
                newlyAddedRowsIndexes: rowsToHighlight,
                highlightCallback: (index) =>
                  setRowsToHighlight((prev) =>
                    prev?.filter((prevIndex) => prevIndex !== index)
                  ),
              }}
              itemSize={(index) => getRowHeight(rows[index])}
              width={width}
            >
              {TableRow}
            </VariableSizeList>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};
