import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfiguration, msalRequest } from "./msalConfiguration";

const msalInstance = new PublicClientApplication(msalConfiguration);

export const acquireAccessToken = async () => {
  const account = msalInstance.getActiveAccount();

  if (!account) {
    return null;
  }

  try {
    const authResult = await msalInstance.acquireTokenSilent({
      ...msalRequest,
      account,
    });
    return authResult.accessToken;
  } catch (error) {
    return null;
  }
};
