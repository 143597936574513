import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { invalidCellsState } from "../../state/atoms/invalidCellsState";
import { unknownIriState } from "../../state/atoms/unknownIriState";

export const useInvalidSourceTables = () => {
  const [invalidSourceTables, setInvalidSourceTables] =
    useRecoilState(invalidCellsState);
  const [unknownIris, setUnknownIris] = useRecoilState(unknownIriState);

  useEffect(() => {
    const invalidTablesJson = localStorage.getItem("invalidSourceTables");
    if (invalidTablesJson) {
      const invalidTables = JSON.parse(invalidTablesJson) as string[];
      if (invalidTables) setInvalidSourceTables(invalidTables);
    }
  }, [setInvalidSourceTables]);

  useEffect(() => {
    const unknownIrisJson = localStorage.getItem("unknownIris");
    if (unknownIrisJson) {
      const unknownIris = JSON.parse(unknownIrisJson) as string[];
      if (unknownIris) setUnknownIris(unknownIris);
    }
  }, [setUnknownIris]);

  useEffect(() => {
    localStorage.setItem(
      "invalidSourceTables",
      JSON.stringify(invalidSourceTables)
    );
  }, [invalidSourceTables]);

  useEffect(() => {
    localStorage.setItem("unknownIris", JSON.stringify(unknownIris));
  }, [unknownIris]);

  const updateInvalidSourceTables = useCallback(
    (values: string[]) => {
      setInvalidSourceTables(values);
    },
    [setInvalidSourceTables]
  );

  const updateUnknownIris = useCallback(
    (values: string[]) => {
      setUnknownIris(values);
    },
    [setUnknownIris]
  );

  return {
    invalidSourceTables,
    updateInvalidSourceTables,
    unknownIris,
    updateUnknownIris,
  };
};
