import classNames from "classnames";
import { TitleProps } from "./Title.props";

export const Title = (props: TitleProps) => {
  const classes = classNames(
    "text-gray-900 dark:text-white text-[22px] font-medium",
    props.className
  );

  return <h1 className={classes}>{props.text}</h1>;
};
