import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SourceTable, Workspace } from "../../../api/apiClient";
import { workspacesState } from "../../../state/atoms/workspacesState";
import { useRecoilValueOrDefault } from "../../../state/recoil";

export const useEditorActiveData = (sourceTables: SourceTable[]) => {
  const workspaces = useRecoilValueOrDefault(workspacesState, []);
  const params = useParams<{ workspaceId: string; sourceTable: string }>();
  const [activeWorkspace, setActiveWorkspace] = useState<
    Workspace | undefined
  >();
  const [activeSourceTable, setActiveSourceTable] = useState<
    SourceTable | undefined
  >();

  useEffect(() => {
    setActiveWorkspace((prev) => {
      const workspace = workspaces.find((x) => x.id === params.workspaceId);
      return workspace?.id !== prev?.id ? workspace : prev;
    });
  }, [params.workspaceId, workspaces]);

  useEffect(() => {
    setActiveSourceTable((prev) => {
      const sourceTable = sourceTables.find(
        (x) => x.displayName === params.sourceTable
      );
      return sourceTable?.name !== prev?.name ? sourceTable : prev;
    });
  }, [params.sourceTable, sourceTables]);

  return {
    activeWorkspace,
    activeSourceTable,
  };
};
