import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { OntologyProject } from "../../api/apiClient";
import { useOntologyContext } from "../../context/ontologyContext/OntologyContext";

interface OntologyCardProps {
  ontology: OntologyProject;
}

export const OntologyCard = (props: OntologyCardProps) => {
  const { ontology } = props;
  const { updateProjectName } = useOntologyContext();
  const navigate = useNavigate();

  return (
    <div
      className="h-24 bg-gray-500 dark:bg-darkSecondary dark:text-white flex flex-col gap-2 p-2 cursor-pointer hover:bg-blue-800 dark:hover:bg-blue-800 hover:text-white"
      onClick={() => {
        updateProjectName(ontology.name);
        navigate("/workspaces");
      }}
    >
      <div className="flex justify-between">
        <p className="font-bold">{ontology.name}</p>
        <div className="flex gap-1">
          <p className="font-bold">Owner:</p>
          <div className="flex gap-1">
            <p>{ontology.owner}</p>
            {ontology.timestamp && (
              <div className="flex gap-1">
                <p>-</p>
                <p>{`${format(ontology.timestamp, "dd/MM HH:mm:ss")}`}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <p className="line-clamp-2" title={ontology.description}>
        {ontology.description}
      </p>
    </div>
  );
};
