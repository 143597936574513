import { Checkbox, FormControlLabel } from "@mui/material";
import { CheckBoxProps } from "./CheckBox.props";

export const CheckBox = (props: CheckBoxProps) => {
  const { label, value, labelPosition, className, onChange } = props;
  return (
    <label className={`dark:text-white ${className}`}>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={onChange}
            className="hover:bg-transparent dark:text-white"
            disableRipple
            size="small"
          />
        }
        labelPlacement={labelPosition}
        label={label}
      />
    </label>
  );
};
