import {
  AdminPanelSettings,
  ChevronLeft,
  ColorLens,
  DarkMode,
  LightMode,
  Settings,
} from "@mui/icons-material";
import { Switch } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CheckBox } from "../../components/form/inputboxes/checkbox/CheckBox";
import { TextInput } from "../../components/form/inputboxes/inputbox/TextInput";
import { ConfigureExternalTablesSettings } from "../../components/settings/configureExternalTablesSettings/ConfigureExternalTablesSettings";
import { RemoveExternalTablesSettings } from "../../components/settings/removeExternalTableSettings/RemoveExternalTableSettings";
import {
  SettingsPanel,
  SettingsType,
} from "../../components/settings/settingsPanel/SettingsPanel";
import {
  SubSettingsPanel,
  SubSettingsType,
} from "../../components/settings/subSettingsPanel/SubSettingsPanel";
import { useUserRolesContext } from "../../context/UserRolesContext/UserRolesContext";
import { useChangeNote } from "../../hooks/useChangeNote/useChangeNote";
import { useExternalTables } from "../../hooks/useExternalTables/useExternalTables";
import { useTheme } from "../../hooks/useTheme/useTheme";
import { UserRoles } from "../../utils/types/userRoles";
import { SettingsPageProps } from "./SettingsPage.props";

export const SettingsPage = (props: SettingsPageProps) => {
  const { loading, fetchSourceTablesForWorkspace } = props;
  const [settingsType, setSettingsType] = useState<SettingsType>("none");
  const [subSettingsType, setSubSettingsType] =
    useState<SubSettingsType>("none");
  const { theme, toggleTheme } = useTheme();
  const { updateChangeNote, changeNote } = useChangeNote();
  const { externalsEnabled, updateExternalsEnabled } = useExternalTables();
  const userRoles = useUserRolesContext();
  const navigate = useNavigate();

  const renderSettingsContent = () => {
    // Appearance Settings
    if (settingsType === "appearance") {
      return (
        <div className="m-12 w-fit">
          <p className="font-bold dark:text-white flex justify-center pb-1">
            Dark Mode
          </p>
          <div className="flex gap-4">
            <DarkMode
              data-testid="darkModeIcon"
              cursor={"pointer"}
              onClick={() => {
                if (theme === "light") {
                  toggleTheme();
                }
              }}
              className={`${
                theme === "light" ? "text-gray-700" : "text-blue-700"
              }`}
              fontSize="large"
            />
            <Switch
              onMouseDown={() => toggleTheme()}
              color="primary"
              checked={theme === "light"}
            />
            <LightMode
              data-testid="lightModeIcon"
              cursor={"pointer"}
              onClick={() => {
                if (theme === "dark") {
                  toggleTheme();
                }
              }}
              className={`${
                theme === "dark" ? "text-gray-700" : "text-blue-700"
              }`}
              fontSize="large"
            />
          </div>
        </div>
      );
    }
    // Editor Settings
    if (settingsType === "editor") {
      return (
        <div className="m-12 w-[60%]">
          <TextInput
            className="w-80"
            label="Change Note"
            value={changeNote}
            onChange={(e) => updateChangeNote(e.target.value)}
          />
          <hr className="my-8 text-gray-600 dark:text-gray-750" />
          <div className="font-bold">
            <CheckBox
              label="Enable External Tables"
              labelPosition="end"
              value={externalsEnabled}
              onChange={() => updateExternalsEnabled(!externalsEnabled)}
            />
          </div>
        </div>
      );
    }
    // Admin Settings
    if (userRoles.includes(UserRoles.WorkspacesReadWrite)) {
      if (subSettingsType === "configureExternal") {
        return (
          <div className="h-full overflow-auto">
            <ConfigureExternalTablesSettings />
          </div>
        );
      }

      if (subSettingsType === "removeExternal") {
        return (
          <div className="w-[60%]">
            <RemoveExternalTablesSettings
              fetchSourceTablesForWorkspace={fetchSourceTablesForWorkspace}
              loading={loading}
            />
          </div>
        );
      }
    }
  };

  return (
    <div className="flex h-full flex-1">
      {/* sidebar */}
      <div className="h-full w-96 border-r border-r-gray-600 dark:border-r-gray-750 bg-gray-400 dark:bg-darkSecondary">
        {/* <div
          className="p-2 w-fit flex gap-1 items-center text-blue-700 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <ArrowBackIosNew fontSize="inherit" />
          Back
        </div> */}
        <SettingsPanel
          text={"Back"}
          type="back"
          activeType={settingsType}
          icon={<ChevronLeft />}
          onClick={() => navigate(-1)}
        />
        <SettingsPanel
          text={"Appearance"}
          icon={<ColorLens />}
          type="appearance"
          activeType={settingsType}
          onClick={() => setSettingsType("appearance")}
        />
        <SettingsPanel
          text={"Editor"}
          icon={<Settings />}
          type="editor"
          activeType={settingsType}
          onClick={() => setSettingsType("editor")}
        />
        {userRoles.includes(UserRoles.WorkspacesReadWrite) && (
          <SettingsPanel
            text={"Admin"}
            icon={<AdminPanelSettings />}
            type="admin"
            activeType={settingsType}
            onClick={() => {
              setSettingsType("admin");
              setSubSettingsType("configureExternal");
            }}
          />
        )}
        {settingsType === "admin" &&
          userRoles.includes(UserRoles.WorkspacesReadWrite) && (
            <div>
              <SubSettingsPanel
                className={`${
                  subSettingsType === "configureExternal"
                    ? "border-t border-gray-700 dark:border-gray-800"
                    : ""
                }`}
                text="Configure External Table"
                type="configureExternal"
                activeType={subSettingsType}
                onClick={() => setSubSettingsType("configureExternal")}
              />
              <SubSettingsPanel
                text="Remove External Table"
                type="removeExternal"
                activeType={subSettingsType}
                onClick={() => setSubSettingsType("removeExternal")}
              />
            </div>
          )}
      </div>

      {/* settings */}
      <div className="w-full h-full dark:text-white">
        {renderSettingsContent()}
      </div>
    </div>
  );
};
