import {
  ErrorIllustration,
  NoDataIllustration,
  NoWorkspacesIllustration,
} from "../../../assets/images";
import { Button } from "../button/Button";
import { IllustrationProps, IllustrationVariant } from "./Illustration.props";

export const Illustration = (props: IllustrationProps) => {
  const renderIllustration = (variant: IllustrationVariant) => {
    switch (variant) {
      case "error":
        return <ErrorIllustration width={"100%"} />;
      case "information":
        return <NoDataIllustration />;
      case "interaction":
        return <NoWorkspacesIllustration />;
    }
  };

  return (
    <div className={`flex justify-center ${props.className}`}>
      <div className="dark:bg-gray-500 w-fit h-fit p-12 rounded-3xl">
        <div className="flex justify-center items-center">
          {renderIllustration(props.variant)}
        </div>
        <div className="mt-4 flex justify-center text-lg font-semibold">
          {props.text}
        </div>
        {props.variant !== "information" && (
          <div className="mt-4 flex justify-center">
            <Button variant="primary" onClick={props.onButtonClick}>
              {props.variant === "interaction" ? "Create New" : "Reload"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
