import { useModal } from "../../../hooks/useModal/useModal";
import { Button } from "../../form";

type Props = {
  isExternal?: boolean;
  duplicateIds?: { [key: string]: number };
};

export const DuplicateIdsButton = ({ duplicateIds, isExternal }: Props) => {
  const { openModal } = useModal();

  if (!isExternal || !duplicateIds || Object.keys(duplicateIds).length < 1)
    return null;

  return (
    <Button
      variant="danger"
      onClick={() =>
        openModal({
          variant: "duplicateIds",
          duplicateIds: duplicateIds,
        })
      }
    >
      Duplicate IDs - Press for info
    </Button>
  );
};
