import { Resizable } from "re-resizable";
import { WorkspaceState } from "../../api/apiClient";
import { EditorContent, EditorSidebar } from "../../components/editor";
import { useUserRolesContext } from "../../context/UserRolesContext/UserRolesContext";
import { useEditor } from "../../hooks/useEditor/useEditor";
import { UserRoles } from "../../utils/types/userRoles";

export const Editor = () => {
  const {
    activeWorkspace,
    activeSourceTable,
    sourceTables,
    state,
    sourceTableColumns,
    sourceTableRows,
    loadingError,
    addTableRow,
    deleteTableRow,
    deleteTableRows,
    updateTableRow,
    moveTableRows,
    updateRowIri,
    fetchTableRows,
    searchInSourceTables,
    synchronizeSourceTable,
  } = useEditor();
  const userRoles = useUserRolesContext();

  const isReadOnlyWorkspace =
    activeWorkspace?.state === WorkspaceState.Published ||
    activeWorkspace?.state === WorkspaceState.Publishing ||
    !userRoles.includes(UserRoles.WorkspacesReadWrite);

  return (
    <div className="flex h-full flex-1">
      <Resizable
        className="min-w-[300px] max-w-[33%]"
        enable={{
          top: false,
          right: true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
      >
        <div className="h-full">
          <EditorSidebar
            workspace={activeWorkspace}
            sourceTables={sourceTables}
            sourceTable={activeSourceTable}
            isReadOnlyWorkspace={isReadOnlyWorkspace}
            fetchTableRows={fetchTableRows}
            search={searchInSourceTables}
          />
        </div>
      </Resizable>
      <div className="flex w-full h-full">
        <EditorContent
          deleteTableRow={deleteTableRow}
          deleteTableRows={deleteTableRows}
          addTableRow={addTableRow}
          updateTableRow={updateTableRow}
          moveTableRows={moveTableRows}
          updateIri={updateRowIri}
          fetchTableRows={fetchTableRows}
          synchronizeSourceTable={synchronizeSourceTable}
          sourceTableColumns={sourceTableColumns}
          sourceTableRows={sourceTableRows}
          sourceTable={activeSourceTable}
          sourceTables={sourceTables}
          workspace={activeWorkspace}
          state={state}
          hasError={loadingError}
          isExternalTable={activeSourceTable?.isExternal}
          isReadOnlyWorkspace={isReadOnlyWorkspace}
        />
      </div>
    </div>
  );
};
