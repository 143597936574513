import classNames from "classnames";
import { SettingsPanelProps } from "./SettingsPanel.props";

export type SettingsType = "appearance" | "editor" | "admin" | "back" | "none";

export const SettingsPanel = (props: SettingsPanelProps) => {
  const { className, text, icon, type, activeType, onClick } = props;

  const classes = classNames([
    className,
    "w-full h-12 pl-8 flex items-center gap-2 font-bold cursor-pointer",
    "dark:text-white active:bg-gray-600 dark:active:bg-blue-750",
    activeType && type && activeType === type
      ? "bg-gray-600 dark:bg-blue-750"
      : type === "back"
      ? "bg-gray-800 dark:bg-blue-900 text-white"
      : "hover:bg-gray-500 dark:hover:bg-blue-800",
  ]);

  return (
    <div className={classes} onClick={() => onClick()}>
      {icon}
      <p className="overflow-hidden">{text}</p>
    </div>
  );
};
