import { AddBoxOutlined } from "@mui/icons-material";
import { useState } from "react";
import { IIriColumn } from "../../../api/apiClient";
import { TrashCanIcon } from "../../../assets/images";
import { ModalComponentProps } from "../../../utils/types/modal";
import { Button } from "../../form";
import { SelectInput } from "../../form/inputboxes/selectInput/SelectInput";
import { Modal } from "../Modal";

export const CreateIdColumnsModal = ({
  show,
  onClose,
  effect,
  effectDuration,
  tableColumns,
  setIriColumns,
  iriColumns,
}: ModalComponentProps) => {
  const [tempIriColumns, setTempIriColumns] = useState<IIriColumn[]>(
    iriColumns !== undefined
      ? iriColumns
      : [
          {
            labelColumn: tableColumns ? tableColumns[0] : undefined,
            idColumns: tableColumns ? [tableColumns[0]] : undefined,
          },
        ]
  );

  const handleIriColChanged = (
    iriColIndex: number,
    idColIndex: number,
    newCol: string
  ) => {
    const tempIdCols = tempIriColumns.map((prev, index) => {
      if (index !== iriColIndex) {
        return prev;
      }

      return {
        labelColumn: prev.labelColumn,
        idColumns: prev.idColumns?.map((idCol, index) => {
          if (index !== idColIndex) {
            return idCol;
          }
          return newCol;
        }),
      };
    });

    setTempIriColumns(tempIdCols);
  };

  const handleLabelColumnChanged = (
    iriColIndex: number,
    newLabelCol: string
  ) => {
    const tempIdCols = tempIriColumns.map((prev, index) => {
      if (index !== iriColIndex) {
        return prev;
      }

      return {
        labelColumn: newLabelCol,
        idColumns: prev.idColumns,
      };
    });
    setTempIriColumns(tempIdCols);
  };

  const handleRemoveIdCol = (indexIri: number, indexId: number) => {
    setTempIriColumns((prev) =>
      prev.map((iriCol, idx) => {
        if (idx !== indexIri) {
          return iriCol;
        } else {
          return {
            ...iriCol,
            idColumns: iriCol.idColumns?.filter((_, idx) => idx !== indexId),
          };
        }
      })
    );
  };

  const handleRemoveIri = (indexIri: number) => {
    setTempIriColumns((prev) => prev.filter((_, idx) => idx !== indexIri));
  };

  return (
    <Modal
      title="Create IRI Columns"
      onClose={onClose}
      show={show}
      effect={effect}
      effectDuration={effectDuration}
    >
      <div className="p-12 flex flex-col gap-4">
        {tempIriColumns.map((idCol, i) => {
          return (
            <div key={`${i}`} className="flex gap-3 items-center overflow-auto">
              <p className="h-full flex items-end pb-2 font-bold dark:text-white">{`IRI ${
                i + 1
              }:`}</p>
              <div className="min-w-[150px] w-[150px]">
                <SelectInput
                  label="Label Column"
                  defaultValue={tempIriColumns[i].labelColumn}
                  onChange={(e) => handleLabelColumnChanged(i, e.target.value)}
                >
                  {tableColumns?.map((col) => (
                    <option>{col}</option>
                  ))}
                </SelectInput>
              </div>
              {idCol.idColumns?.map((idSubCol, j) => {
                return (
                  <div
                    key={`${idSubCol}-${i}-${j}`}
                    className="min-w-[150px] w-[150px]"
                  >
                    <SelectInput
                      label={`Business Key ${j + 1}`}
                      onChange={(e) =>
                        handleIriColChanged(i, j, e.target.value)
                      }
                      defaultValue={idCol.idColumns?.[j]}
                      labelComponent={
                        j < 1 ? null : (
                          <button onClick={() => handleRemoveIdCol(i, j)}>
                            <TrashCanIcon className="text-red-700 hover:text-red-800" />
                          </button>
                        )
                      }
                    >
                      {tableColumns?.map((col) => (
                        <option>{col}</option>
                      ))}
                    </SelectInput>
                  </div>
                );
              })}

              <div className="h-full flex items-end pb-1 gap-3">
                <button
                  onClick={() => {
                    const currentIdCols = tempIriColumns[i].idColumns;
                    const x = tableColumns?.filter(
                      (col) => !currentIdCols?.includes(col)
                    );

                    const newIdCols = [
                      ...(currentIdCols ?? []),
                      x !== undefined ? x[0] : "",
                    ];

                    const y: IIriColumn = {
                      idColumns: newIdCols,
                      labelColumn: newIdCols[0],
                    };

                    setTempIriColumns((prev) =>
                      prev.map((prevIri, index) => (index === i ? y : prevIri))
                    );
                  }}
                >
                  <AddBoxOutlined
                    className={`text-blue-700 ${
                      false ? "opacity-60" : "hover:text-blue-600"
                    }`}
                  />
                </button>
                {i !== 0 && (
                  <button onClick={() => handleRemoveIri(i)} className="mb-1">
                    <TrashCanIcon className="text-red-700 hover:text-red-800" />
                  </button>
                )}
              </div>
            </div>
          );
        })}

        <button
          className="flex gap-2 group"
          onClick={() =>
            setTempIriColumns((prev) => [
              ...prev,
              {
                idColumns: tableColumns ? [tableColumns[0]] : undefined,
                labelColumn: tableColumns ? tableColumns[0] : undefined,
              },
            ])
          }
        >
          <AddBoxOutlined
            className={`text-blue-700 ${
              false ? "opacity-60" : "group-hover:text-blue-600"
            }`}
          />
          <p className="font-bold dark:text-white">Add Additional IRI</p>
        </button>
      </div>
      <div className="flex flex-row mt-5">
        <Button
          data-testid="create_btn"
          className="w-44"
          onClick={() => {
            setIriColumns?.(tempIriColumns);
            onClose?.();
          }}
        >
          {iriColumns ? "Edit" : "CREATE"}
        </Button>
        <Button
          variant="secondary"
          className="ml-2"
          onClick={() => {
            setTempIriColumns([]);
            onClose?.();
          }}
        >
          CANCEL
        </Button>
      </div>
    </Modal>
  );
};
