import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { ThemeState, ThemeType } from "../../state/atoms/themeState";

export const useTheme = () => {
  const [theme, setTheme] = useRecoilState<ThemeType>(ThemeState);

  useEffect(() => {
    if (
      localStorage.getItem("theme") &&
      localStorage.getItem("theme") === "dark"
    ) {
      setTheme("dark");
    }
  }, [setTheme]);

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = useCallback(() => {
    setTheme(theme === "light" ? "dark" : "light");
  }, [setTheme, theme]);

  return {
    theme,
    toggleTheme,
  };
};
