import { useState } from "react";
import { useDialog } from "../../../hooks/useDialog/useDialog";
import { useSnapshots } from "../../../hooks/useSnapshots/useSnapshots";
import { ModalComponentProps } from "../../../utils/types/modal";
import { Button } from "../../form";
import { CheckBox } from "../../form/inputboxes/checkbox/CheckBox";
import { TextInput } from "../../form/inputboxes/inputbox/TextInput";
import Loader from "../../loader/Loader";
import { Modal } from "../Modal";

export const RefreshTableModal = (props: ModalComponentProps) => {
  const {
    show,
    onClose,
    fetchTableRows,
    fetchExternalTableMetadata,
    refreshExternalTable,
    effect,
    effectDuration,
    workspaceId,
    sourceTableId,
  } = props;
  const [changeNote, setChangeNote] = useState("");
  const { openDialog, closeDialog } = useDialog();
  const [disabled, setDisabled] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const { addSnapshot, loading: busyWithSnapshot } = useSnapshots();
  const [shouldCreateSnapshot, setShouldCreateSnapshot] = useState(false);

  const refresh = async () => {
    if (workspaceId && sourceTableId) {
      setDisabled(true);
      setFetchingData(true);
      await refreshExternalTable?.(workspaceId, sourceTableId, changeNote);
      await Promise.all([
        fetchExternalTableMetadata?.(workspaceId, sourceTableId),
        fetchTableRows?.(),
      ]);
      setFetchingData(false);
      setDisabled(false);
    } else {
      openDialog({
        variant: "error",
        text: "Could not determine WorkspaceID or SourceTableID",
        primaryButtonText: "retry",
        secondaryButtonText: "cancel",
        onPrimaryButtonClick: () => refresh(),
        onSecondaryButtonClick: () => closeDialog(),
      });
    }

    onClose?.();
  };

  const createSnapshot = async () => {
    let success = false;
    if (workspaceId) {
      try {
        await addSnapshot(
          workspaceId,
          "Automated Snapshot prior to table refresh"
        );
        success = true;
      } catch (e) {
        success = false;
      }
    } else {
      openDialog({
        variant: "error",
        text: "Failed to create Snapshot: refresh cancelled",
        primaryButtonText: "Confirm",
        onPrimaryButtonClick: () => {},
      });
      success = false;
    }

    onClose?.();
    return success;
  };

  const handleSubmit = async () => {
    let createdSnapshot = false;
    if (shouldCreateSnapshot) {
      createdSnapshot = await createSnapshot();
    }

    if (createdSnapshot || !shouldCreateSnapshot) await refresh();
  };

  return (
    <Modal
      sideModal={false}
      title="Refresh Table"
      onClose={() => onClose?.()}
      show={show}
      effect={effect}
      effectDuration={effectDuration}
    >
      {fetchingData || busyWithSnapshot ? (
        <div className="my-4 flex flex-col gap-6 items-center font-bold dark:text-white">
          <Loader size="5em" />
          Refreshing Table
        </div>
      ) : (
        <div className="px-12 py-6 flex flex-col gap-6">
          <span className="flex flex-col items-center text-center bg-gray-500 dark:bg-gray-750 dark:text-white p-2">
            <p className="font-bold text-lg">Caution</p>
            <p>
              Refreshing an external table can lead to unforeseen alterations in
              your data.
            </p>
            <p>
              This may include loss of data or modifications to existing IRIs.
            </p>

            <p className="pt-2">
              To safeguard your workspace, it is advisable to make a Snapshot
              before refreshing any external table.
            </p>

            <div data-tip="Leaving this box checked causes an automated snapshot to be created prior to table refresh.<br> Refresh will be aborted in case Snapshot creation fails">
              <CheckBox
                value={shouldCreateSnapshot}
                onChange={() => setShouldCreateSnapshot((prev) => !prev)}
                label="Create Snapshot"
                labelPosition="end"
              />
            </div>
          </span>

          <TextInput
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                await handleSubmit();
              }
            }}
            autoFocus={true}
            label="Refresh Comment"
            placeholder="Specify Refresh Reason"
            onChange={(e) => setChangeNote(e.target.value)}
            value={changeNote}
          />

          <div className="mt-8 flex gap-2">
            <Button
              disabled={disabled}
              onClick={async () => {
                await handleSubmit();
              }}
              data-testid="btn-refresh"
            >
              Refresh Table
            </Button>
            <Button variant="secondary" onClick={() => onClose?.()}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
