import { SelectInputProps } from "./SelectInput.props";

export const SelectInput = (props: SelectInputProps) => {
  const {
    label,
    secondaryLabel,
    className,
    children,
    innerClassName,
    labelComponent,
    ...rest
  } = props;

  return (
    <div
      className={`grid auto-rows-auto grid-cols-1 items-center justify-start ${className}`}
    >
      {(label || labelComponent) && (
        <div className="flex justify-between pb-2">
          <div className="flex gap-1">
            <span className="font-bold dark:text-white">{label}</span>
            {secondaryLabel && (
              <span className="dark:text-white">{secondaryLabel}</span>
            )}
          </div>
          <div>{labelComponent}</div>
        </div>
      )}
      <select
        data-testid="select-input"
        className={`px-2 h-8 border disabled:bg-gray-500 dark:text-gray-600 dark:bg-darkSecondary dark:border-gray-750 ${innerClassName}`}
        {...rest}
      >
        {children}
      </select>
    </div>
  );
};
