import { ArrowForward } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApprovalStatus, ModelStatus } from "../../../api/apiClient";
import { useBuilds } from "../../../hooks/useBuilds/useBuilds";
import { useDialog } from "../../../hooks/useDialog/useDialog";
import { ModalComponentProps } from "../../../utils/types/modal";
import { Button } from "../../form";
import { AreaInput } from "../../form/inputboxes/areaInput/AreaInput";
import { CheckBox } from "../../form/inputboxes/checkbox/CheckBox";
import Loader from "../../loader/Loader";
import { Modal } from "../Modal";

export const BuildWorkspaceModal = (props: ModalComponentProps) => {
  const { show, onClose, workspaceId, ownerId, effect, effectDuration } = props;
  const [description, setDescription] = useState("");
  const { addBuild, loading } = useBuilds();
  const { openDialog, closeDialog } = useDialog();
  const [validationFailed, setValidationFailed] = useState(false);
  const [buildStarted, setBuildStarted] = useState(false);
  const [buildPrepared, setBuildPrepared] = useState(false);
  const [approvalStatuses, setApprovalStatuses] = useState<ApprovalStatus[]>([
    ApprovalStatus.Define,
    ApprovalStatus.Implement,
    ApprovalStatus.Use,
  ]);
  const [modelStatuses, setModelStatuses] = useState<ModelStatus[]>([
    ModelStatus.Development,
    ModelStatus.Testing,
    ModelStatus.Stable,
  ]);
  const navigate = useNavigate();

  const getApprovalStatusesFromLocalStorage = useCallback(() => {
    const resStatuses: ApprovalStatus[] = [];
    const statuses = localStorage.getItem(
      `approvalStatusFilters-${workspaceId}`
    );

    if (statuses === null) {
      return null;
    }

    const statusArray = statuses.split(",");
    statusArray.forEach((status, index) => {
      switch (status) {
        case ApprovalStatus.Define.toString():
          resStatuses[index] = ApprovalStatus.Define;
          break;
        case ApprovalStatus.Implement.toString():
          resStatuses[index] = ApprovalStatus.Implement;
          break;
        case ApprovalStatus.Use.toString():
          resStatuses[index] = ApprovalStatus.Use;
          break;
        default:
          break;
      }
    });

    return resStatuses;
  }, [workspaceId]);

  const getModelStatusesFromLocalStorage = useCallback(() => {
    const resStatuses: ModelStatus[] = [];
    const statuses = localStorage.getItem(`modelStatusFilters-${workspaceId}`);

    if (statuses === null) {
      return null;
    }

    const statusArray = statuses.split(",");
    statusArray.forEach((status, index) => {
      switch (status) {
        case ModelStatus.Development.toString():
          resStatuses[index] = ModelStatus.Development;
          break;
        case ModelStatus.Testing.toString():
          resStatuses[index] = ModelStatus.Testing;
          break;
        case ModelStatus.Stable.toString():
          resStatuses[index] = ModelStatus.Stable;
          break;
        default:
          break;
      }
    });

    return resStatuses;
  }, [workspaceId]);

  useEffect(() => {
    const approvalStatuses = getApprovalStatusesFromLocalStorage();
    if (approvalStatuses) {
      setApprovalStatuses(approvalStatuses);
    }

    const modelStatuses = getModelStatusesFromLocalStorage();
    if (modelStatuses) {
      setModelStatuses(modelStatuses);
    }
  }, [getApprovalStatusesFromLocalStorage, getModelStatusesFromLocalStorage]);

  const buildWorkspace = async () => {
    if (buildStarted) {
      return;
    }

    if (description) {
      if (workspaceId && ownerId) {
        setBuildStarted(true);
        localStorage.setItem(
          `modelStatusFilters-${workspaceId}`,
          modelStatuses.toString()
        );
        localStorage.setItem(
          `approvalStatusFilters-${workspaceId}`,
          approvalStatuses.toString()
        );
        await addBuild(
          workspaceId,
          description,
          approvalStatuses,
          modelStatuses
        );
      } else {
        openDialog({
          variant: "error",
          onPrimaryButtonClick: () => buildWorkspace(),
          onSecondaryButtonClick: () => closeDialog(),
          primaryButtonText: "retry",
          secondaryButtonText: "cancel",
          text: "Could not determine Workspace ID or owner ID",
        });
      }
      setBuildPrepared(true);
    } else {
      setValidationFailed(true);
      setDescription("");
    }
  };

  const renderApprovalCheckboxes = () => {
    return (
      <div className="flex flex-col">
        <p className="font-bold pb-2 dark:text-white">Approval Status</p>
        <CheckBox
          className="-mb-2"
          label={ApprovalStatus.Define}
          labelPosition="end"
          onChange={() =>
            setApprovalStatuses((prev) =>
              !approvalStatuses.includes(ApprovalStatus.Define)
                ? [ApprovalStatus.Define, ...prev]
                : prev.filter((statuses) => statuses !== ApprovalStatus.Define)
            )
          }
          value={approvalStatuses.includes(ApprovalStatus.Define)}
        />
        <CheckBox
          className="-mb-2"
          label={ApprovalStatus.Implement}
          labelPosition="end"
          onChange={() =>
            setApprovalStatuses((prev) =>
              !approvalStatuses.includes(ApprovalStatus.Implement)
                ? [ApprovalStatus.Implement, ...prev]
                : prev.filter(
                    (statuses) => statuses !== ApprovalStatus.Implement
                  )
            )
          }
          value={approvalStatuses.includes(ApprovalStatus.Implement)}
        />
        <CheckBox
          label={ApprovalStatus.Use}
          labelPosition="end"
          onChange={() =>
            setApprovalStatuses((prev) =>
              !approvalStatuses.includes(ApprovalStatus.Use)
                ? [ApprovalStatus.Use, ...prev]
                : prev.filter((statuses) => statuses !== ApprovalStatus.Use)
            )
          }
          value={approvalStatuses.includes(ApprovalStatus.Use)}
        />
      </div>
    );
  };

  const renderModelCheckboxes = () => {
    return (
      <div className="flex flex-col">
        <p className="font-bold pb-2 dark:text-white">Model Status</p>
        <CheckBox
          className="-mb-2"
          label={ModelStatus.Development}
          labelPosition="end"
          onChange={() =>
            setModelStatuses((prev) =>
              !modelStatuses.includes(ModelStatus.Development)
                ? [ModelStatus.Development, ...prev]
                : prev.filter(
                    (statuses) => statuses !== ModelStatus.Development
                  )
            )
          }
          value={modelStatuses.includes(ModelStatus.Development)}
        />
        <CheckBox
          className="-mb-2"
          label={ModelStatus.Testing}
          labelPosition="end"
          onChange={() =>
            setModelStatuses((prev) =>
              !modelStatuses.includes(ModelStatus.Testing)
                ? [ModelStatus.Testing, ...prev]
                : prev.filter((statuses) => statuses !== ModelStatus.Testing)
            )
          }
          value={modelStatuses.includes(ModelStatus.Testing)}
        />
        <CheckBox
          label={ModelStatus.Stable}
          labelPosition="end"
          onChange={() =>
            setModelStatuses((prev) =>
              !modelStatuses.includes(ModelStatus.Stable)
                ? [ModelStatus.Stable, ...prev]
                : prev.filter((statuses) => statuses !== ModelStatus.Stable)
            )
          }
          value={modelStatuses.includes(ModelStatus.Stable)}
        />
      </div>
    );
  };

  useEffect(() => {
    setDescription("");
  }, [show]);

  const renderContent = () => {
    return (
      <div className="p-12 flex flex-col gap-5">
        <div className="flex flex-row gap-12">
          {renderApprovalCheckboxes()}
          {renderModelCheckboxes()}
        </div>
        <AreaInput
          autoFocus={true}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              buildWorkspace();
            }
          }}
          innerClassName={`${
            validationFailed
              ? "outline-red-700 border-red-700 dark:border-red-700"
              : ""
          }`}
          className="w-full"
          label="Description"
          value={description}
          onChange={(e) => {
            setValidationFailed(false);
            setDescription(e.target.value);
          }}
        />
        {validationFailed && (
          <div className="text-red-700 italic">
            Build must have a description.
          </div>
        )}
        <div className="flex flex-row">
          <Button
            data-testid="build_btn"
            className="w-44"
            onClick={() => buildWorkspace()}
          >
            BUILD
          </Button>
          <Button
            variant="secondary"
            className="ml-2"
            onClick={() => onClose?.()}
          >
            CANCEL
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      sideModal={false}
      title="Build Workspace"
      onClose={onClose}
      show={show}
      effect={effect}
      effectDuration={effectDuration}
    >
      {loading ? (
        <div className="my-4 flex flex-col gap-6 items-center font-bold dark:text-white">
          <Loader size="5em" />
          Preparing Build
        </div>
      ) : buildPrepared ? (
        <div className="p-12 dark:text-white">
          <p>Build is scheduled to run.</p>
          <p>This process may take some time.</p>
          <p>
            Once completed, build files will be available in the 'Builds' list
            of this workspace.
          </p>
          <div className="flex gap-2 mt-5">
            <Button
              className="w-32"
              data-testid="btn_ok"
              onClick={() => onClose?.()}
            >
              ok
            </Button>
            <Button
              className="flex gap-2"
              data-testid="build_btn_navigate"
              variant="secondary"
              onClick={() => {
                navigate(`workspaces/editor/${workspaceId}/builds`);
                onClose?.();
              }}
            >
              go to builds
              <ArrowForward fontSize="small" />
            </Button>
          </div>
        </div>
      ) : (
        renderContent()
      )}
    </Modal>
  );
};
