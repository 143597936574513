import { OntologyProject } from "../../api/apiClient";
import { OntologyCard } from "../ontologyCard/OntologyCard";

interface OntologiesListProps {
  ontologies: OntologyProject[];
}

export const OntologiesList = (props: OntologiesListProps) => {
  const { ontologies } = props;

  return (
    <>
      <div className="flex flex-col gap-2">
        {ontologies.map((ontology) => (
          <OntologyCard key={ontology.name} ontology={ontology} />
        ))}
      </div>
    </>
  );
};
