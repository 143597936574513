import { useCallback, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Dialog } from "../../components/modals/dialog/Dialog";
import { DialogProps } from "../../components/modals/dialog/Dialog.props";
import { dialogOpenState, dialogState } from "../../state/atoms/dialogState";
import { DialogSettings } from "../../utils/types/dialog";

export const useDialog = () => {
  const [dialog, setDialog] = useRecoilState(dialogState);
  const dialogOpen = useRecoilValue(dialogOpenState);

  const openDialog = useCallback(
    (settings: DialogSettings) => {
      setDialog(settings);
    },
    [setDialog]
  );

  const closeDialog = useCallback(() => {
    setDialog(null);
  }, [setDialog]);

  const dialogProps = useMemo((): DialogProps | null => {
    if (!dialog) {
      return null;
    }

    return { ...dialog, open: dialogOpen };
  }, [dialog, dialogOpen]);

  return { openDialog, closeDialog, dialogOpen, dialogProps, Dialog };
};
