import { ContentCopy, ContentPaste, Edit } from "@mui/icons-material";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  ContentDuplicateIcon,
  MoveIcon,
  PlusBoxOutlinedIcon,
  TrashCanIcon,
} from "../../../assets/images";
import { useContextMenu } from "../../../hooks/useContextMenu/useContextMenu";
import { useInvalidSourceTables } from "../../../hooks/useInvalidSourceTables/useInvalidSourceTables";
import { EditorContextMenuProps } from "./EditorContextMenu.props";

export const EditorContextMenu = (props: EditorContextMenuProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { closeContextMenu } = useContextMenu();
  const {
    invalidSourceTables,
    updateInvalidSourceTables,
    unknownIris,
    updateUnknownIris,
  } = useInvalidSourceTables();
  const [clipboardContent, setClipboardContent] = useState("");

  const itemClass = "w-48 p-2 font-semibold uppercase flex gap-2 items-center";

  const handleInvalidIRIs = useCallback(
    (rowId: string) => {
      let newInvalids: string[] = [];
      invalidSourceTables.forEach((invalid) => {
        if (!invalid.includes(rowId)) {
          newInvalids = [invalid, ...newInvalids];
        }
      });
      updateInvalidSourceTables(newInvalids);

      let newUnknowns: string[] = [];
      unknownIris.forEach((unknown) => {
        if (!unknown.includes(rowId)) {
          newUnknowns = [unknown, ...newUnknowns];
        }
      });
      updateUnknownIris(newUnknowns);
    },
    [
      invalidSourceTables,
      unknownIris,
      updateInvalidSourceTables,
      updateUnknownIris,
    ]
  );

  useEffect(() => {
    const fetchClipboardData = async () => {
      setClipboardContent(await navigator.clipboard.readText());
    };

    fetchClipboardData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        closeContextMenu();
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    document.addEventListener("contextmenu", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      document.removeEventListener("contextmenu", handleClickOutside, true);
    };
  }, [closeContextMenu]);

  return (
    <div
      className="bg-white dark:bg-darkSecondary dark:border dark:border-gray-750 flex flex-col shadow-lg"
      ref={ref}
    >
      <div className="m-2 bg-white">
        <button
          onClick={() => {
            props.addRow(Number(props.rowId));
            closeContextMenu();
          }}
          className={`${itemClass} group text-gray-900 dark:bg-darkSecondary dark:text-white hover:text-white hover:bg-blue-700 dark:hover:bg-blue-700`}
        >
          <PlusBoxOutlinedIcon className="text-blue-700 group-hover:text-white" />
          Add new row
        </button>
        <button
          onClick={() => {
            props.duplicateRow(Number(props.rowId));
            closeContextMenu();
          }}
          className={`${itemClass} group text-gray-900 dark:bg-darkSecondary dark:text-white hover:text-white hover:bg-blue-700 dark:hover:bg-blue-700`}
        >
          <ContentDuplicateIcon className="text-blue-700 group-hover:text-white" />
          Duplicate row
        </button>
        <button
          onClick={() => {
            props.moveRow();
            closeContextMenu();
          }}
          className={`${itemClass} group text-gray-900 dark:bg-darkSecondary dark:text-white hover:text-white hover:bg-blue-700 dark:hover:bg-blue-700`}
        >
          <MoveIcon className="text-blue-700 group-hover:text-white" />
          Move Row
        </button>
        {props.rowIdOriginal === props.cellValue && (
          <button
            onClick={() => {
              props.updateIri();
              closeContextMenu();
            }}
            className={`${itemClass} group text-gray-900 dark:bg-darkSecondary dark:text-white hover:text-white hover:bg-blue-700 dark:hover:bg-blue-700`}
          >
            <Edit className="text-blue-700 group-hover:text-white" />
            Update IRI
          </button>
        )}
        <button
          onClick={() => {
            if (props.cellValue) {
              navigator.clipboard.writeText(props.cellValue);
            }
            closeContextMenu();
          }}
          className={`${itemClass} group text-gray-900 dark:bg-darkSecondary dark:text-white hover:text-white hover:bg-blue-700 dark:hover:bg-blue-700`}
        >
          <ContentCopy className="text-blue-700 group-hover:text-white" />
          Copy Cell Value
        </button>
        {props.pasteCellValue !== undefined && clipboardContent !== "" && (
          <button
            onClick={() => {
              props.pasteCellValue?.(clipboardContent);
              closeContextMenu();
            }}
            className={`${itemClass} group text-gray-900 dark:bg-darkSecondary dark:text-white hover:text-white hover:bg-blue-700 dark:hover:bg-blue-700`}
          >
            <ContentPaste className="text-blue-700 group-hover:text-white" />
            Paste Value
          </button>
        )}
      </div>
      <hr className="border-gray-600 dark:border-gray-750 my-1" />
      <div className="m-2 bg-white">
        <button
          onClick={() => {
            props.deleteRow();
            if (props.rowIdOriginal) {
              handleInvalidIRIs(props.rowIdOriginal);
            }
            closeContextMenu();
          }}
          className={`${itemClass} text-red-700 dark:bg-darkSecondary hover:text-white hover:bg-red-700 dark:hover:bg-red-700`}
        >
          <TrashCanIcon />
          Delete row
        </button>
      </div>
    </div>
  );
};
