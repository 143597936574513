// @ts-nocheck
// TODO: update once react-table v8 released

import { ArrowBackIosNew, PriorityHigh } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { format } from "date-fns";
import moment from "moment";
import { useCallback, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { Cell, Column } from "react-table";
import ReactTooltip from "react-tooltip";
import { Build, BuildState } from "../../api/apiClient";
import { EditorToolbar } from "../../components/editor";
import { Illustration } from "../../components/form/illustration/Illustration";
import Loader from "../../components/loader/Loader";
import { Table } from "../../components/table/Table";
import { EditorReadonlyCell } from "../../components/table/table-cell/editor-readonly-cell/EditorReadonlyCell";
import { Title } from "../../components/title/Title";
import { useOntologyContext } from "../../context/ontologyContext/OntologyContext";
import { useBuilds } from "../../hooks/useBuilds/useBuilds";
import { useWorkspaces } from "../../hooks/useWorkspaces/useWorkspaces";
import { workspaceState } from "../../state/atoms/workspacesState";
import { useRecoilValueOrDefault } from "../../state/recoil";

export const Builds = () => {
  const {
    builds,
    loading,
    loadingError: buildsError,
    loadBuilds,
    downloadBuildLog,
    downloadBuildDrop,
  } = useBuilds();
  const params = useParams<{ workspaceId: string }>();
  const { setWorkspacesFromApi } = useWorkspaces();
  const workspace = useRecoilValueOrDefault(
    workspaceState(params.workspaceId ?? ""),
    null
  );
  const { projectName } = useOntologyContext();

  useEffect(() => {
    setTimeout(() => ReactTooltip.rebuild(), 500);
  }, [builds]);

  const renderButton = useCallback(
    (id: string | undefined) => {
      const build = builds?.find((x) => x.id === id);
      if (build?.state === BuildState.NotStarted) {
        return <div className="flex justify-center px-4">Build Scheduled</div>;
      } else if (build?.state === BuildState.Completed) {
        return (
          <div className="flex gap-4 px-4 justify-center">
            <button
              className="text-blue-700 underline"
              onClick={() =>
                downloadBuildLog(params.workspaceId ?? "", build?.id ?? "")
              }
            >
              Build Logs
            </button>
            {moment(moment.now()).diff(build.createdTimestamp, "days") < 180 &&
              !moment(build.createdTimestamp).isBefore(
                "2023-03-01",
                "days"
              ) && (
                <button
                  className="text-blue-700 underline"
                  onClick={() =>
                    downloadBuildDrop(params.workspaceId ?? "", build?.id ?? "")
                  }
                >
                  Build Files
                </button>
              )}
          </div>
        );
      } else {
        return (
          <div className="flex gap-2 items-center justify-center">
            <CircularProgress color="inherit" size="16px" />
            Building
          </div>
        );
      }
    },
    [builds, downloadBuildDrop, downloadBuildLog, params.workspaceId]
  );

  const columns = useMemo<Column<Build>[]>(
    () => [
      {
        Header: "Date",
        width: 200,
        minWidth: 100,
        Cell: (cell: Cell<Build>) => (
          <EditorReadonlyCell
            cell={cell}
            customTitle={format(
              cell.row.original.createdTimestamp,
              "dd.MM.yyyy - HH:mm"
            )}
          />
        ),
        accessor: (x) => (
          <div className="flex gap-2 items-center">
            {format(x.createdTimestamp, "dd.MM.yyyy - HH:mm")}
            {(moment(moment.now()).diff(x.createdTimestamp, "days") > 180 ||
              moment(x.createdTimestamp).isBefore("2023-03-01", "days")) && (
              <div
                className="w-5 h-5 text-xs flex justify-center items-center border-2 rounded-full border-gray-700 text-gray-700"
                data-tip="Build has expired. Build files will not be available"
              >
                <PriorityHigh fontSize="inherit" />
              </div>
            )}
          </div>
        ),
      },
      {
        Header: "Description",
        width: 500,
        minWidth: 200,
        Cell: (cell: Cell<Build>) => <EditorReadonlyCell cell={cell} />,
        accessor: (x) => x.description,
      },
      {
        id: "build",
        width: 180,
        minWidth: 180,
        Cell: (cell: Cell<Build>) => (
          <EditorReadonlyCell cell={cell} customTitle="" />
        ),
        accessor: (x) => renderButton(x.id),
      },
    ],
    [renderButton]
  );

  const renderBuilds = () => {
    if (buildsError) {
      return (
        <Illustration
          className="h-full"
          variant="error"
          text="Loading Builds caused an error to occur"
          onButtonClick={() => loadBuilds(params.workspaceId ?? "")}
        />
      );
    }

    if (builds?.length === 0) {
      return (
        <Illustration
          className="h-full"
          variant="information"
          text="You have no Builds listed"
        />
      );
    }

    if (loading) {
      return (
        <div className="h-full">
          <Loader size="5em" />
        </div>
      );
    }

    return (
      <div className="overflow-auto h-full">
        <Table rows={builds} selectable={false} columns={columns} />
      </div>
    );
  };

  useEffect(() => {
    const fetch = async () => {
      await setWorkspacesFromApi();
    };

    fetch();
  }, [setWorkspacesFromApi]);

  useEffect(() => {
    if (params.workspaceId && projectName && !builds) {
      loadBuilds(params.workspaceId);
    }
  }, [loadBuilds, params.workspaceId, builds, projectName]);

  return (
    <div className="flex h-full flex-1 flex-col">
      <Link to="/workspaces" className=" w-14 ml-4 mt-4 text-blue-700">
        <div className="flex gap-1 items-center">
          <ArrowBackIosNew fontSize="inherit" />
          Back
        </div>
      </Link>
      <Title text={`Builds - ${workspace?.title}`} className="pl-4 pt-2 pb-6" />
      {renderBuilds()}
      <EditorToolbar itemsCount={(builds ?? []).length} selectedRows={[]} />
    </div>
  );
};
