import { EditorReadonlyCellProps } from "./EditorReadonlyCell.props";

export const EditorReadonlyCell = <T extends object>(
  props: EditorReadonlyCellProps<T>
) => {
  const { cell, customTitle, className } = props;

  return (
    <div title={customTitle ?? cell.value} className={`truncate ${className}`}>
      {cell.value}
    </div>
  );
};
