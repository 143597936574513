import {
  RecoilValue,
  useRecoilRefresher_UNSTABLE,
  useRecoilValueLoadable,
} from "recoil";

export const useRecoilRefresher = useRecoilRefresher_UNSTABLE;

export const useRecoilValueOrDefault = <T>(
  node: RecoilValue<T>,
  fallback: T
) => {
  const loadable = useRecoilValueLoadable(node);
  switch (loadable.state) {
    case "hasValue":
      return loadable.contents;
    case "hasError":
      throw loadable.contents;
    case "loading":
      return fallback;
  }
};
