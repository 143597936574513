import { atom, selector, selectorFamily } from "recoil";
import { Workspace } from "../../api/apiClient";

const workspacesStateAtom = atom<Workspace[]>({
  key: "workspaceState",
  default: Array<Workspace>(),
});

export const workspacesState = selector<Workspace[]>({
  key: "workspacesState",
  get: ({ get }) => get(workspacesStateAtom),
  set: ({ set }, newValue) => set(workspacesStateAtom, newValue),
});

export const workspaceState = selectorFamily({
  key: "workspaceState",
  get:
    (id: string) =>
    ({ get }) =>
      get(workspacesState).find((x) => x.id === id),
});

export const workspaceOwnerState = selector<string[]>({
  key: "workspaceOwnerState",
  get: ({ get }) =>
    Array.from(new Set(get(workspacesStateAtom).map((item) => item.owner))),
});
