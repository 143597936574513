import classNames from "classnames";
import { SubSettingsPanelProps } from "./SubSettingsPanel.props";

export type SubSettingsType = "configureExternal" | "removeExternal" | "none";

export const SubSettingsPanel = (props: SubSettingsPanelProps) => {
  const { className, text, type, activeType, onClick } = props;

  const classes = classNames([
    "w-full h-fit min-h-6 pl-8 flex items-center gap-4 font-bold cursor-pointer",
    "dark:text-white active:bg-gray-600 dark:active:bg-blue-750",
    activeType && type && activeType === type
      ? "bg-gray-600 dark:bg-blue-750"
      : "hover:bg-gray-500 dark:hover:bg-blue-800",
    className,
  ]);

  return (
    <div className={classes} onClick={() => onClick()}>
      <p className="flex-wrap">{text}</p>
    </div>
  );
};
