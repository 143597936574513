import { useCallback, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { GenericModal } from "../../components/modals/generic-modal/GenericModal";
import { GenericModalComponentProps } from "../../components/modals/generic-modal/GenericModalComponent.props";
import { modalOpenState, modalState } from "../../state/atoms/modalState";
import { ModalSettings } from "../../utils/types/modal";

export const useModal = () => {
  const [modal, setModal] = useRecoilState(modalState);
  const modalOpen = useRecoilValue(modalOpenState);

  const openModal = useCallback(
    (settings: ModalSettings) => {
      setModal(settings);
    },
    [setModal]
  );

  const closeModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  const modalProps = useMemo((): GenericModalComponentProps | null => {
    if (!modal) {
      return null;
    }

    return { ...modal, open: modalOpen };
  }, [modal, modalOpen]);

  return { openModal, closeModal, modalOpen, modalProps, GenericModal };
};
