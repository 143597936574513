import { useEffect } from "react";
import Loader from "../../components/loader/Loader";
import { OntologiesList } from "../../components/ontologiesList/OntologiesList";
import { Title } from "../../components/title/Title";
import { useOntologies } from "../../hooks/useOntologies/useOntologies";

export const Ontologies = () => {
  const { loading, ontologies, fetchOntologies } = useOntologies();

  useEffect(() => {
    fetchOntologies();
  }, [fetchOntologies]);

  return (
    <div className="w-full h-full flex justify-center">
      <div className="w-1/2 my-20 flex flex-col gap-5">
        <Title text="Ontologies" />
        {loading ? (
          <div>
            <Loader size="10em" />
          </div>
        ) : (
          <OntologiesList ontologies={ontologies} />
        )}
      </div>
    </div>
  );
};
