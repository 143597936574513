export type ButtonVariant = "primary" | "secondary" | "danger" | "success";

export const getButtonVariantClasses = (variant: ButtonVariant) => {
  switch (variant) {
    case "secondary":
      return [
        "bg-white dark:bg-dark border-blue-700 dark:border-gray-750 text-blue-700 dark:text-white",
        "hover:bg-blue-700 hover:border-blue-700 hover:text-white dark:hover:bg-gray-750",
        "active:bg-blue-800 active:border-blue-800 active:text-white",
        "focus:bg-blue-700 focus:text-white",
      ];

    case "success":
      return [
        "bg-green-700 border-green-700 text-white",
        "hover:bg-green-800 hover:border-green-800",
        "active:bg-green-900 active:border-green-900",
        "focus:bg-green-800",
      ];

    case "danger":
      return [
        "bg-red-700 border-red-700 text-white",
        "hover:bg-red-800 hover:border-red-800",
        "active:bg-red-900 active:border-red-900",
        "focus:bg-red-800",
      ];

    default:
      return [
        "bg-blue-700 border-blue-700 text-white",
        "hover:bg-blue-800 hover:border-blue-800",
        "active:bg-blue-900 active:border-blue-900",
        "focus:bg-blue-800",
      ];
  }
};
