import { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { api } from "../../api";
import {
  ExternalTableMetaData,
  IriColumn,
  LoadExternalTableRequest,
  SourceTable,
} from "../../api/apiClient";
import { enableExternalsState } from "../../state/atoms/enableExternalsState";
import { useNotifications } from "../useNotifications/useNotifications";

const dataRoot = "external_tables";

export const useExternalTables = () => {
  const [externalTables, setExternalTables] = useState<SourceTable[]>([]);
  const [externalTableMetadata, setExternalTableMetadata] =
    useState<ExternalTableMetaData>();
  const { addNotification } = useNotifications();
  const [loading, setLoading] = useState(false);
  const [loadingTables, setLoadingTables] = useState(false);
  const [loadingColumns, setLoadingColumns] = useState(false);
  const [loadingMetaData, setLoadingMetaData] = useState(false);
  const [externalsEnabled, setExternalsEnabled] =
    useRecoilState(enableExternalsState);

  useEffect(() => {
    const externalsEnabled = localStorage.getItem("externalsEnabled");
    if (externalsEnabled) {
      setExternalsEnabled(JSON.parse(externalsEnabled));
    }
  }, [setExternalsEnabled]);

  useEffect(() => {
    localStorage.setItem("externalsEnabled", JSON.stringify(externalsEnabled));
  }, [externalsEnabled]);

  const updateExternalsEnabled = useCallback(
    (enabled: boolean) => {
      setExternalsEnabled(enabled);
    },
    [setExternalsEnabled]
  );

  const fetchExternalTables = useCallback(async () => {
    try {
      setLoadingTables(true);
      setExternalTables(await api.getExternalTables());
    } catch (error) {
      addNotification({
        message: `Error occurred while loading external tables. Error message: ${
          (error as Error).message
        }.`,
        variant: "error",
      });
    } finally {
      setLoadingTables(false);
    }
  }, [addNotification]);

  const fetchExternalTableColumns = useCallback(
    async (sourceTableName: string) => {
      try {
        setLoadingColumns(true);
        return await api.getExternalTableColumns(sourceTableName);
      } catch (error) {
        addNotification({
          message: `Error occurred while loading external table columns. Error message: ${
            (error as Error).message
          }.`,
          variant: "error",
        });
      } finally {
        setLoadingColumns(false);
      }
    },
    [addNotification]
  );

  const fetchExternalTableMetadata = useCallback(
    async (workspaceId: string, sourceTableName: string) => {
      try {
        setLoadingMetaData(true);
        setExternalTableMetadata(
          await api.getExternalTableMetaData(workspaceId, sourceTableName)
        );
      } catch (error) {
        addNotification({
          message: `Error occurred while loading external table metadata. Error message: ${
            (error as Error).message
          }.`,
          variant: "error",
        });
      } finally {
        setLoadingMetaData(false);
      }
    },
    [addNotification]
  );

  const fetchExternalTableData = useCallback(
    async (
      workspaceId: string,
      sourceTableName: string,
      namespacePrefix: string,
      approvalStatus: string,
      dataQuery: string,
      changeNote: string,
      labelColumn: string,
      idColumns: string[]
    ) => {
      try {
        setLoading(true);
        await api.loadExternalTables(
          workspaceId,
          dataRoot + "|" + sourceTableName,
          namespacePrefix,
          approvalStatus,
          dataQuery,
          changeNote,
          labelColumn,
          idColumns
        );
        addNotification({
          message: "Successfully loaded external table into workspace.",
          variant: "success",
        });
      } catch (error) {
        addNotification({
          message: `Error occurred while loading external table data. Error message: ${
            (error as Error).message
          }.`,
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [addNotification]
  );

  const fetchExternalTableDataV2 = useCallback(
    async (
      workspaceId: string,
      sourceTableName: string,
      namespacePrefix: string,
      approvalStatus: string,
      dataQuery: string,
      changeNote: string,
      iriColumns: IriColumn[]
    ) => {
      try {
        setLoadingTables(true);
        await api.loadExternalTablesV2(
          new LoadExternalTableRequest({
            approvalStatus: approvalStatus,
            changeNote: changeNote,
            dataQuery: dataQuery,
            namespacePrefix: namespacePrefix,
            sourceTableId: dataRoot + "|" + sourceTableName,
            workspaceId: workspaceId,
            iriColumns: iriColumns,
          })
        );
        addNotification({
          message: "Successfully loaded external table into workspace.",
          variant: "success",
        });
      } catch (error) {
        addNotification({
          message: `Error occurred while loading external table data. Error message: ${
            (error as Error).message
          }.`,
          variant: "error",
        });
      } finally {
        setLoadingTables(false);
      }
    },
    [addNotification]
  );

  const refreshExternalTable = useCallback(
    async (workspaceId: string, sourceTableId: string, changeNote: string) => {
      try {
        setLoading(true);
        await api.refreshExternalTables(
          workspaceId,
          "external_tables|" + sourceTableId,
          changeNote
        );
        addNotification({
          message: "Successfully refreshed external table in workspace.",
          variant: "success",
        });
      } catch (error) {
        addNotification({
          message: `Error occurred while loading external table data. Error message: ${
            (error as Error).message
          }.`,
          variant: "error",
        });
      } finally {
        setLoading(false);
      }
    },
    [addNotification]
  );

  const deleteExternalTable = useCallback(
    async (oid: string, workspaceId: string, sourceTableId: string) => {
      try {
        await api.deleteSourceTable(oid, workspaceId, sourceTableId);
        addNotification({
          message: `Successfully removed external table ${sourceTableId} from workspace.`,
          variant: "success",
        });
      } catch (error) {
        addNotification({
          message: `Error occurred while removing external table ${sourceTableId} in workspace ${workspaceId}. Error message: ${
            (error as Error).message
          }.`,
          variant: "error",
        });
      }
    },
    [addNotification]
  );

  const deleteExternalTables = useCallback(
    async (oid: string, workspaceId: string, sourceTableIds: string[]) => {
      setLoading(true);
      await Promise.all(
        sourceTableIds.map((id) =>
          deleteExternalTable(oid, workspaceId, "external_tables|" + id)
        )
      );
      setLoading(false);
    },
    [deleteExternalTable]
  );

  return {
    loading,
    loadingTables,
    loadingColumns,
    loadingMetaData,
    externalTables,
    externalTableMetadata,
    externalsEnabled,
    fetchExternalTables,
    fetchExternalTableData,
    fetchExternalTableDataV2,
    fetchExternalTableColumns,
    fetchExternalTableMetadata,
    refreshExternalTable,
    updateExternalsEnabled,
    deleteExternalTables,
  };
};
