import { useCallback, useState } from "react";
import { api } from "../../api";
import { Snapshot, SnapshotRequest } from "../../api/apiClient";
import { useOntologyContext } from "../../context/ontologyContext/OntologyContext";
import { useDialog } from "../useDialog/useDialog";
import { useNotifications } from "../useNotifications/useNotifications";

export const useSnapshots = () => {
  const [snapshots, setSnapshots] = useState<Snapshot[]>();
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const { openDialog, closeDialog } = useDialog();
  const { addNotification } = useNotifications();
  const { projectName } = useOntologyContext();

  const renderDialog = useCallback(
    (text: string, retryFunction: () => void) => {
      openDialog({
        variant: "error",
        onPrimaryButtonClick: retryFunction,
        onSecondaryButtonClick: () => closeDialog(),
        text: text,
        primaryButtonText: "retry",
        secondaryButtonText: "close",
      });
    },
    [closeDialog, openDialog]
  );

  const loadSnapshots = useCallback(
    async (workspaceId: string) => {
      try {
        setLoading(true);
        setLoadingError(false);
        const response = await api.getSnapshots(workspaceId);
        setSnapshots(response);
      } catch (e) {
        addNotification({
          message: `Error occured while loading Snapshots. Error message: ${
            (e as Error).message
          }.`,
          variant: "error",
        });
        setLoadingError(true);
      } finally {
        setLoading(false);
      }
    },
    [addNotification, setSnapshots]
  );

  const addSnapshot = useCallback(
    async (workspaceId: string, description: string) => {
      try {
        const request: SnapshotRequest = new SnapshotRequest({
          description: description,
        });
        setLoading(true);
        await api.createSnapshotV2(projectName, workspaceId, request);
        loadSnapshots(workspaceId);
        addNotification({
          variant: "success",
          message: "Successfully Created Snapshot",
        });
      } catch (e) {
        renderDialog(
          `Error occured while creating snapshot. Error Message: ${
            (e as Error).message
          }`,
          () => addSnapshot(workspaceId, description)
        );
      } finally {
        setLoading(false);
      }
    },
    [addNotification, loadSnapshots, projectName, renderDialog]
  );

  const deleteSnapshot = useCallback(
    async (workspaceId: string, id: string) => {
      try {
        setLoading(true);
        await api.deleteSnapshot(workspaceId, id);
        loadSnapshots(workspaceId);
        addNotification({
          variant: "success",
          message: "Successfully Deleted Snapshot",
        });
      } catch (e) {
        renderDialog(
          `Error occured while deleting snapshot. Error Message: ${
            (e as Error).message
          }`,
          () => deleteSnapshot(workspaceId, id)
        );
      } finally {
        setLoading(false);
      }
    },
    [addNotification, loadSnapshots, renderDialog]
  );

  const deleteSnapshots = useCallback(
    async (workspaceId: string, ids: string[]) => {
      try {
        setLoading(true);
        await Promise.all(ids.map((id) => api.deleteSnapshot(workspaceId, id)));
        loadSnapshots(workspaceId);
      } catch (e) {
        renderDialog(
          `Error occured while deleting snapshots. Error Message: ${
            (e as Error).message
          }`,
          () => deleteSnapshots(workspaceId, ids)
        );
      } finally {
        setLoading(false);
      }
    },
    [loadSnapshots, renderDialog]
  );

  const applySnapshot = useCallback(
    async (workspaceId: string, snapshotId: string) => {
      try {
        setLoading(true);
        await api.loadSnapshotV2(projectName, workspaceId, snapshotId);
        addNotification({
          variant: "success",
          message: "Successfully Applied Snapshot",
        });
      } catch (e) {
        renderDialog(
          `Error occured while loading snapshot. Error Message: ${
            (e as Error).message
          }`,
          () => applySnapshot(workspaceId, snapshotId)
        );
      } finally {
        setLoading(false);
      }
    },
    [addNotification, projectName, renderDialog]
  );

  return {
    snapshots,
    loading,
    loadingError,
    loadSnapshots,
    addSnapshot,
    deleteSnapshot,
    deleteSnapshots,
    applySnapshot,
  };
};
