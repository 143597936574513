import { SourceTableDataType } from "../../api/apiClient";

const isIntegerType = (dataType?: SourceTableDataType) => {
  switch (dataType) {
    case SourceTableDataType.XsdInt:
    case SourceTableDataType.XsdInteger:
    case SourceTableDataType.XsdShort:
    case SourceTableDataType.XsdLong:
      return true;

    default:
      return false;
  }
};

export const IntegerValidator = (
  input: string,
  dataType?: SourceTableDataType
) => {
  if (isIntegerType(dataType) && !Number.isInteger(Number(input))) {
    return false;
  }

  return true;
};
