import { useState } from "react";
import { ModalComponentProps } from "../../../utils/types/modal";
import { Button } from "../../form";
import { TextInput } from "../../form/inputboxes/inputbox/TextInput";
import Loader from "../../loader/Loader";
import { Modal } from "../Modal";

export const UpdateIriModal = (props: ModalComponentProps) => {
  const { show, onClose, effect, effectDuration, rowId, UpdateIri } = props;
  const [loading, setLoading] = useState(false);
  const [newIri, setNewIri] = useState("");
  const [validationFailed, setValidationFailed] = useState(false);

  const updateIri = async () => {
    if (
      UpdateIri &&
      rowId &&
      !!newIri &&
      newIri.trim().length > 0 &&
      !newIri.includes(" ")
    ) {
      setLoading(true);
      await UpdateIri(rowId, newIri);
      setLoading(false);
      onClose?.();
    }

    setValidationFailed(true);
  };

  const renderContent = () => {
    return (
      <div className="mx-32 my-8 text-gray-900">
        <div className="flex gap-2 dark:text-white">
          <span>Current IRI: </span>
          <span className="italic">{rowId}</span>
        </div>
        <TextInput
          className="mt-4"
          autoFocus={true}
          label="New IRI"
          innerClassName={`${
            validationFailed
              ? "outline-red-700 border-red-700 dark:border-red-700"
              : ""
          }`}
          value={newIri}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              updateIri();
            }
          }}
          onChange={(e) => {
            setNewIri(e.target.value);
            setValidationFailed(false);
          }}
        />
        {validationFailed && (
          <div className="text-red-700 italic">
            IRI cannot be empty, and cannot contain whitespaces
          </div>
        )}
        <div className="mt-8 flex">
          <Button onClick={() => updateIri()}>Update IRI</Button>
          <Button
            variant="secondary"
            className="ml-2"
            onClick={() => onClose?.()}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      sideModal={false}
      title="Update IRI"
      onClose={() => {
        setValidationFailed(false);
        onClose?.();
      }}
      show={show}
      effect={effect}
      effectDuration={effectDuration}
    >
      {loading ? (
        <div className="my-4 flex flex-col gap-6 items-center font-bold dark:text-white">
          <Loader size="5em" />
          Updating IRI
        </div>
      ) : (
        renderContent()
      )}
    </Modal>
  );
};
