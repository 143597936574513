import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useOntologyContext } from "../../../context/ontologyContext/OntologyContext";
import { useWorkspaces } from "../../../hooks/useWorkspaces/useWorkspaces";
import { workspacesState } from "../../../state/atoms/workspacesState";
import { ModalComponentProps } from "../../../utils/types/modal";
import { Button } from "../../form";
import { TextInput } from "../../form/inputboxes/inputbox/TextInput";
import Loader from "../../loader/Loader";
import { Modal } from "../Modal";

export const CreateWorkspaceModal = (props: ModalComponentProps) => {
  const { show, onClose, effect, effectDuration } = props;
  const [workspaceName, setWorkspaceName] = useState("");
  const [validationFailed, setValidationFailed] = useState(false);
  const [validationReason, setValidationReason] = useState("");
  const { addWorkspace, loading } = useWorkspaces();
  const workspaces = useRecoilValue(workspacesState);
  const [creating, setCreating] = useState(false);
  const { projectName } = useOntologyContext();
  const navigate = useNavigate();

  const createWorkspace = async () => {
    if (creating) {
      return;
    }

    if (!!workspaceName && workspaceName.trim().length > 0) {
      if (!workspaces.some((w) => w.title === workspaceName)) {
        setCreating(true);
        const newWorkspace = await addWorkspace(workspaceName);
        setWorkspaceName("");
        onClose?.();
        if (newWorkspace) {
          navigate(`/editor/${newWorkspace.id}`);
        }
      } else {
        setValidationFailed(true);
        setValidationReason("A workspace with this name already exists");
      }
    } else {
      setValidationFailed(true);
      setValidationReason("Workspace must have a name");
      setWorkspaceName("");
    }
  };

  const renderContent = () => {
    return (
      <div className="mx-32 my-8 text-gray-900">
        <div className="flex gap-2 dark:text-white">
          <span>Project: </span>
          <span className="font-bold italic">{projectName}</span>
        </div>
        <TextInput
          className="mt-4"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              createWorkspace();
            }
          }}
          autoFocus={true}
          label="Workspace Name"
          innerClassName={`${
            validationFailed
              ? "outline-red-700 border-red-700 dark:border-red-700"
              : ""
          }`}
          value={workspaceName}
          onChange={(e) => {
            setWorkspaceName(e.target.value);
            setValidationFailed(false);
          }}
        />
        {validationFailed && (
          <div className="text-red-700 italic">{validationReason}</div>
        )}

        <div className="mt-8 flex">
          <Button onClick={() => createWorkspace()} data-testid="wk-crt-btn">
            Create Workspace
          </Button>
          <Button
            variant="secondary"
            className="ml-2"
            onClick={() => onClose?.()}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      sideModal={false}
      title="Create New Workspace"
      onClose={() => {
        setValidationFailed(false);
        onClose?.();
      }}
      show={show}
      effect={effect}
      effectDuration={effectDuration}
    >
      {loading ? (
        <div className="my-4 flex flex-col gap-6 items-center font-bold dark:text-white">
          <Loader size="5em" />
          Creating Workspace
        </div>
      ) : (
        renderContent()
      )}
    </Modal>
  );
};
