import { KeyboardArrowDown } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IndeterminateCheckbox } from "../../indeterminate-checkbox/IndeterminateCheckbox";
import { TextInput } from "../inputbox/TextInput";
import { MultiSelectInputProps } from "./MultiSelectInput.props";

export const MultiSelectInput = <T,>(props: MultiSelectInputProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const onChangeRef = useRef(props.onChange);
  const { title, options, icon, error, display, searchText } = props;
  const [searchFilter, setSearchFilter] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [checkedOptions, setCheckedOptions] = useState<T[]>(options);
  const [effect, setEffect] = useState(false);
  const effectDuration = 200;

  const allChecked = useMemo(
    () => checkedOptions.length === options.length,
    [checkedOptions, options]
  );

  const handleChecked = useCallback((item: T) => {
    setCheckedOptions((prevValue) =>
      prevValue.includes(item)
        ? prevValue.filter((x) => x !== item)
        : [...prevValue, item]
    );
  }, []);

  const handleCheckAll = () => setCheckedOptions(!allChecked ? options : []);

  const filteredOptions = useMemo(
    () =>
      searchText
        ? options.filter((x) =>
            searchText(x).toLowerCase().includes(searchFilter.toLowerCase())
          )
        : options,
    [options, searchFilter, searchText]
  );

  const handleClickOutside = async (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setEffect(false);
      await setTimeout(() => setShowDropdown(false), effectDuration);
    }
  };

  useEffect(() => {
    setCheckedOptions(options);
  }, [options]);

  useEffect(() => {
    onChangeRef.current?.(checkedOptions);
  }, [checkedOptions]);

  useEffect(() => {
    if (showDropdown) {
      setEffect(true);
    } else {
      setEffect(false);
    }
  }, [showDropdown]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () =>
      document.removeEventListener("click", handleClickOutside, true);
  }, []);

  return (
    <div ref={ref} className="relative z-10">
      <button
        className={`px-2 border h-8 min-w-[8rem] dark:text-gray-600 dark:bg-darkSecondary dark:border-gray-750 ${
          error ? "border-red-700" : ""
        }`}
        onClick={async () => {
          if (!showDropdown) {
            setShowDropdown(true);
            return;
          }
          setEffect(false);
          await setTimeout(() => setShowDropdown(false), effectDuration);
        }}
      >
        <div className="flex justify-between items-center gap-6">
          <div className="flex items-center gap-2 dark:text-gray-600">
            {icon}
            {title}{" "}
            {checkedOptions.length !== options.length &&
              `(${checkedOptions.length}/${options.length})`}
          </div>
          <KeyboardArrowDown />
        </div>
      </button>

      {showDropdown && (
        <div
          className={`transform ease-in-out duration-${effectDuration}  ${
            effect ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
            className={`origin-top-right absolute bg-white dark:bg-darkSecondary border border-gray-400 dark:border-gray-750 shadow-lg ${
              props.growLeft ? "right-0" : ""
            }`}
          >
            <div className="m-4 min-w-[20rem]">
              {searchText && (
                <TextInput
                  className="mb-4"
                  placeholder="Filter"
                  onChange={(e) => setSearchFilter(e.target.value)}
                />
              )}
              <label className="flex gap-2 items-center dark:text-white">
                <IndeterminateCheckbox
                  indeterminate={
                    checkedOptions.length < options.length &&
                    checkedOptions.length > 0
                  }
                  onChange={() => handleCheckAll()}
                  checked={allChecked}
                />
                All
              </label>
              <hr className="border-gray-600 dark:border-gray-750 my-2" />
              {options && (
                <div className="max-h-[15rem] overflow-auto">
                  {filteredOptions.map((option, index) => (
                    <label
                      key={index}
                      className="flex gap-2 items-center dark:text-white"
                    >
                      <input
                        type="checkbox"
                        key={index}
                        checked={checkedOptions.includes(option)}
                        onChange={() => handleChecked(option)}
                      />
                      {display(option)}
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
