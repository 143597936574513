import { Circle } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { SearchTermState } from "../../../state/atoms/searchTermState";
import {
  EditorSidebarListItemState,
  EditorSidebarListProps,
} from "./EditorSidebarList.props";

export const EditorSidebarList = (props: EditorSidebarListProps) => {
  const [searchTerm] = useRecoilState(SearchTermState);
  const switchItemState = (state: EditorSidebarListItemState) => {
    switch (state) {
      case "error":
        return "text-red-700";
      case "saved":
        return "text-green-700";
      default:
        return "text-yellow-900";
    }
  };

  if (!props.workspace) {
    return null;
  }

  return (
    <div className="mt-4 flex flex-nowrap overflow-auto">
      <div className="flex flex-col gap-4">
        {searchTerm && props.tablesInSearch === 0 && (
          <div className="dark:text-gray-600 ml-4 italic text-xs">
            Search returned no matches
          </div>
        )}
        {props.items && props.items?.length > 0 && (
          <div>
            {props.items.map((item) => {
              return (
                <Link
                  to={`/editor/${props.workspace!.id}/${item.name}`}
                  key={item.name}
                  className="ml-4 flex flex-nowrap"
                >
                  <div className={switchItemState(item.state)}>
                    <Circle style={{ fontSize: 7 }} />
                  </div>
                  <span
                    className={`pl-2 whitespace-nowrap dark:text-white ${
                      props.sourceTable?.displayName === item.name
                        ? "font-bold"
                        : null
                    }`}
                  >
                    {item.name}
                  </span>
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
