import { forwardRef, useEffect, useRef } from "react";
import { useCombinedRefs } from "../../../hooks/useCombinedRefs/useCombinedRefs";

export const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  { indeterminate?: boolean } & React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
>(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef(null);
  const combinedRef = useCombinedRefs(ref, defaultRef);

  useEffect(() => {
    if (combinedRef?.current) {
      combinedRef.current.indeterminate = indeterminate ?? false;
    }
  }, [combinedRef, indeterminate]);

  return <input type="checkbox" ref={combinedRef} {...rest} />;
});
