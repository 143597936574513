import { Circle } from "@mui/icons-material";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { EditorSidebarExternalListProps } from "./EditorSidebarExternalList.props";

export const EditorSidebarExternalList = (
  props: EditorSidebarExternalListProps
) => {
  const { tables, className, workspaceId, sourceTable } = props;
  const classes = classNames(
    "mb-4 max-h-[700px] min-h-[200px] overflow-auto",
    className
  );

  const schemaNames = Array.from(
    new Set(tables.map((t) => t.name.split("|")[0]))
  );

  const getTablesFromSchema = (schema: string) =>
    tables.filter((table) => table.name.split("|")[0] === schema);

  return (
    <div className={classes}>
      {schemaNames.map((schema, index) => (
        <div
          key={schema}
          className="ml-4 flex flex-col flex-nowrap whitespace-nowrap dark:text-white text-gray-700"
        >
          <div>
            <p className="font-bold">{schema}</p>
            {getTablesFromSchema(schema).map((table) => (
              <Link
                to={`/editor/${workspaceId}/${table.displayName}`}
                key={table.name}
              >
                <div
                  key={table.name}
                  className={`text-black dark:text-white flex flex-nowrap items-center gap-2 ${
                    sourceTable?.name === table.name ? "font-bold" : ""
                  }`}
                >
                  <Circle style={{ fontSize: 7 }} className="text-gray-700" />
                  {table.displayName}{" "}
                </div>
              </Link>
            ))}
          </div>
          {index !== schemaNames.length - 1 && (
            <hr className="mr-4 my-1 text-gray-600 dark:text-gray-750" />
          )}
        </div>
      ))}
    </div>
  );
};
