import { useCallback, useState } from "react";
import { useSetRecoilState } from "recoil";
import { api } from "../../api";
import {
  ProjectMetadata,
  WorkspacePublishRequest,
  WorkspaceRequest,
} from "../../api/apiClient";
import { useOntologyContext } from "../../context/ontologyContext/OntologyContext";
import { workspacesState } from "../../state/atoms/workspacesState";
import { useRecoilRefresher } from "../../state/recoil";
import { useDialog } from "../useDialog/useDialog";
import { useNotifications } from "../useNotifications/useNotifications";

export const useWorkspaces = () => {
  const fetchWorkspaces = useRecoilRefresher(workspacesState);
  const setWorkspaces = useSetRecoilState(workspacesState);
  const { openDialog, closeDialog } = useDialog();
  const { addNotification } = useNotifications();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { projectName } = useOntologyContext();

  const renderDialog = useCallback(
    (text: string, retryFunction: () => void) => {
      openDialog({
        variant: "error",
        onPrimaryButtonClick: retryFunction,
        onSecondaryButtonClick: () => closeDialog(),
        text: text,
        primaryButtonText: "retry",
        secondaryButtonText: "close",
      });
    },
    [closeDialog, openDialog]
  );

  const setWorkspacesFromApi = useCallback(async () => {
    try {
      setError(false);
      setLoading(true);
      if (projectName === "") {
        return;
      }
      const workspaces = await api.getWorkspacesV2(projectName);
      setWorkspaces(workspaces);
    } catch (e) {
      setError(true);
      addNotification({
        message: `Error occured while loading workspaces. Error message: ${
          (e as Error).message
        }.`,
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [addNotification, projectName, setWorkspaces]);

  const addWorkspace = useCallback(
    async (title: string) => {
      try {
        const request: WorkspaceRequest = new WorkspaceRequest({
          title: title,
        });
        setLoading(true);
        const response = await api.createWorkspaceV2(projectName, request);
        const metaData = await api.getMetadata(projectName, response.id);
        response.projectMetadata = metaData;

        setWorkspaces((p) => [response, ...p]);
        addNotification({
          variant: "success",
          message: `Successfully created new workspace: ${title}`,
        });
        return response;
      } catch (e) {
        renderDialog(
          `Error occured while adding workspace. Error Message: ${
            (e as Error).message
          }`,
          () => addWorkspace(title)
        );
      } finally {
        setLoading(false);
      }
    },
    [addNotification, projectName, renderDialog, setWorkspaces]
  );

  const deleteWorkspace = async (workspaceId: string) => {
    try {
      setLoading(true);
      await api.deleteWorkspaceV2(workspaceId, projectName);
      // TODO - consider reloading workspaces to guarantee synchronication with client
      setWorkspaces((prevValue) =>
        prevValue.filter((x) => x.id !== workspaceId)
      );
      addNotification({
        variant: "success",
        message: "Successfully Deleted Workspace",
      });
    } catch (e) {
      renderDialog(
        `Error occured while deleting workspace. Error Message: ${
          (e as Error).message
        }`,
        () => deleteWorkspace(workspaceId)
      );
    } finally {
      setLoading(false);
    }
  };

  const updateWorkspace = async (workspaceId: string, newName: string) => {
    try {
      const request: WorkspaceRequest = new WorkspaceRequest({
        title: newName,
      });
      await api.editWorkspaceV2(projectName, workspaceId, request);
      fetchWorkspaces();
      addNotification({
        variant: "success",
        message: "Successfully Renamed Workspace",
      });
    } catch (e) {
      renderDialog(
        `Error occured while updating workspace. Error Message: ${
          (e as Error).message
        }`,
        () => updateWorkspace(workspaceId, newName)
      );
    } finally {
      setLoading(false);
    }
  };

  const publishWorkspace = async (
    workspaceId: string,
    releaseNote: string,
    projectMetadata?: ProjectMetadata
  ): Promise<boolean> => {
    try {
      setLoading(true);

      const request: WorkspacePublishRequest = new WorkspacePublishRequest({
        description: releaseNote,
        projectMetadata: projectMetadata,
      });

      await api.publishWorkspaceV2(projectName, workspaceId, request);

      addNotification({
        variant: "success",
        message: "Successfully Published Workspace.",
      });
      return true;
    } catch (e) {
      renderDialog(
        `Error occured while publishing workspace. Error Message: ${
          (e as Error).message
        }`,
        () => publishWorkspace(workspaceId, releaseNote)
      );
      return false;
    } finally {
      setLoading(false);
    }
  };

  const unlockWorkspace = async (workspaceId: string): Promise<boolean> => {
    try {
      setLoading(true);
      await api.unlockWorkspaceV2(projectName, workspaceId);
      return true;
    } catch (e) {
      renderDialog(
        `Error occured while unlocking workspace. Error Message: ${
          (e as Error).message
        }`,
        () => unlockWorkspace(workspaceId)
      );
      return false;
    } finally {
      setLoading(false);
    }
  };

  const IriExistsInWorkspace = async (workspaceId: string, iri: string) => {
    try {
      const response = await api.getIriEntity(workspaceId, iri);
      if (response.iri) {
        return true;
      }

      return false;
    } catch (e) {
      addNotification({
        message: `Error occured while checking IRI existence. Error message: ${
          (e as Error).message
        }.`,
        variant: "error",
      });
      return false;
    }
  };

  return {
    loadWorkspaces: fetchWorkspaces,
    addWorkspace,
    deleteWorkspace,
    updateWorkspace,
    publishWorkspace,
    setWorkspacesFromApi,
    unlockWorkspace,
    IriExistsInWorkspace,
    loading,
    error,
  };
};
