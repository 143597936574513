import { useCallback, useState } from "react";
import { api } from "../../api";
import { OntologyProject } from "../../api/apiClient";
import { useNotifications } from "../useNotifications/useNotifications";

export const useOntologies = () => {
  const [loading, setLoading] = useState(false);
  const [ontologies, setOntologies] = useState<OntologyProject[]>([]);
  const { addNotification } = useNotifications();

  const fetchOntologies = useCallback(async () => {
    try {
      setLoading(true);
      setOntologies(await api.getOntologies());
    } catch (error) {
      addNotification({
        message: `Error occurred while loading ontologies. Error message: ${
          (error as Error).message
        }.`,
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [addNotification]);

  return {
    loading,
    ontologies,
    fetchOntologies,
  };
};
