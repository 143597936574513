import { useEffect, useState } from "react";
import { useDialog } from "../../../hooks/useDialog/useDialog";
import { useSnapshots } from "../../../hooks/useSnapshots/useSnapshots";
import { ModalComponentProps } from "../../../utils/types/modal";
import { Button } from "../../form";
import { AreaInput } from "../../form/inputboxes/areaInput/AreaInput";
import Loader from "../../loader/Loader";
import { Modal } from "../Modal";

export const CreateSnapshotModal = (props: ModalComponentProps) => {
  const { show, onClose, workspaceId, effect, effectDuration } = props;
  const { addSnapshot, loading } = useSnapshots();
  const [description, setDescription] = useState("");
  const { openDialog, closeDialog } = useDialog();
  const [validationFailed, setValidationFailed] = useState(false);
  const [createStarted, setCreateStarted] = useState(false);

  const createSnapshot = async () => {
    if (createStarted) {
      return;
    }

    if (!!description && description.trim().length > 0) {
      if (workspaceId) {
        setCreateStarted(true);
        await addSnapshot(workspaceId, description);
      } else {
        openDialog({
          variant: "error",
          text: "Could not determine Workspace or owner ID",
          primaryButtonText: "retry",
          secondaryButtonText: "cancel",
          onPrimaryButtonClick: () => createSnapshot(),
          onSecondaryButtonClick: () => closeDialog(),
        });
      }

      onClose?.();
    } else {
      setValidationFailed(true);
      setDescription("");
    }
  };

  useEffect(() => {
    setDescription("");
  }, [show]);

  const renderContent = () => {
    return (
      <div className="p-12 flex flex-col">
        <AreaInput
          autoFocus={true}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              createSnapshot();
            }
          }}
          innerClassName={`${
            validationFailed
              ? "outline-red-700 border-red-700 dark:border-red-700"
              : ""
          }`}
          className="w-full"
          label="Description"
          value={description}
          onChange={(e) => {
            setValidationFailed(false);
            setDescription(e.target.value);
          }}
        />
        {validationFailed && (
          <div className="text-red-700 italic">
            Snapshot must have a description.
          </div>
        )}
        <div className="flex flex-row mt-5">
          <Button
            data-testid="create_btn"
            className="w-44"
            onClick={() => createSnapshot()}
          >
            CREATE
          </Button>
          <Button
            variant="secondary"
            className="ml-2"
            onClick={() => onClose?.()}
          >
            CANCEL
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      sideModal={false}
      title="Create snapshot"
      onClose={() => onClose?.()}
      show={show}
      effect={effect}
      effectDuration={effectDuration}
    >
      {loading ? (
        <div className="my-4 flex flex-col gap-6 items-center font-bold dark:text-white">
          <Loader size="5em" />
          Creating Snapshot
        </div>
      ) : (
        renderContent()
      )}
    </Modal>
  );
};
