import { atom, selector } from "recoil";
import { ModalSettings } from "../../utils/types/modal";

export const modalState = atom<ModalSettings | null>({
  key: "modalState",
  default: null,
});

export const modalOpenState = selector<boolean>({
  key: "modalOpenState",
  get: ({ get }) => !!get(modalState),
});
