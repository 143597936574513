import { ContextMenuProps } from "./ContextMenu.props";

export const ContextMenu = (props: ContextMenuProps) => {
  if (!props.open) {
    return null;
  }

  return (
    <div className="fixed z-10" style={{ top: props.posY, left: props.posX }}>
      {props.menu}
    </div>
  );
};
