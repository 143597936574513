import { atom, selector } from "recoil";
import { ContextMenuSettings } from "../../utils/types/contextMenu";

export const contextMenuState = atom<ContextMenuSettings | null>({
  key: "contextMenuState",
  default: null,
});

export const contextMenuOpenState = selector<boolean>({
  key: "contextMenuOpenState",
  get: ({ get }) => !!get(contextMenuState),
});
