import { useCallback } from "react";
import { api } from "../../api";
import { useNotifications } from "../useNotifications/useNotifications";

export const useSourceTableColumns = () => {
  const { addNotification } = useNotifications();

  const getSourceTableColumns = useCallback(
    async (workspaceId: string, sourceTableId: string) => {
      try {
        return await api.getSourceTableColumns(workspaceId, sourceTableId);
      } catch (error) {
        addNotification({
          message: `Error occurred while loading sourceTable columns. Error message: ${
            (error as Error).message
          }.`,
          variant: "error",
        });
      }
    },
    [addNotification]
  );

  return {
    getSourceTableColumns,
  };
};
