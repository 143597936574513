import { VersionNumberProps } from "./VersionNumber.props";

export const VersionNumber = (props: VersionNumberProps) => {
  const { title, value, minValue, setValue } = props;

  return (
    <div className="w-fit items-center">
      <p className="dark:text-white pb-2">{title}</p>
      <div className="flex h-6 bg-gray-500 w-fit items-center justify-between">
        <button
          onClick={() => setValue((prev) => Math.max(minValue, prev - 1))}
          className="px-2 hover:bg-gray-600"
        >
          -
        </button>
        <input
          className={`h-[80%] text-center ${
            value > 99 ? (value > 99999 ? "w-24" : "w-12") : "w-8"
          }`}
          type="text"
          inputMode="numeric"
          pattern="[0-9]"
          value={value}
          onChange={(e) => {
            if (e.target.value === "" || /^[0-9\b]+$/.test(e.target.value)) {
              const num = Number(e.target.value);
              setValue(Math.max(minValue, num));
            }
          }}
        ></input>
        <button
          onClick={() => setValue((prev) => Math.max(minValue, prev + 1))}
          className="px-2 hover:bg-gray-600"
        >
          +
        </button>
      </div>
    </div>
  );
};
