import jwtDecode from "jwt-decode";
import React, { createContext, useContext, useEffect, useState } from "react";
import { acquireAccessToken } from "../../utils/msal/accessToken";

const UserRolesContext = createContext<string[]>([]);

const useUserRolesContext = () => useContext(UserRolesContext);

type Props = {
  children?: React.ReactNode;
};

const UserRolesContextProvider: React.FC<Props> = ({ children }) => {
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [accessToken, setAccessToken] = useState<string | null>();

  useEffect(() => {
    const fetchToken = async () => {
      setAccessToken(await acquireAccessToken());
    };

    fetchToken();
  }, []);

  useEffect(() => {
    if (accessToken) {
      const userRoles = jwtDecode<{ roles?: string[] }>(accessToken);
      if (userRoles.roles) {
        setUserRoles(userRoles.roles);
      }
    }
  }, [accessToken]);

  return (
    <UserRolesContext.Provider value={userRoles}>
      {children}
    </UserRolesContext.Provider>
  );
};

export { UserRolesContext, UserRolesContextProvider, useUserRolesContext };
