import { useState } from "react";
import { ModalComponentProps } from "../../../utils/types/modal";
import { Button } from "../../form";
import { TextInput } from "../../form/inputboxes/inputbox/TextInput";
import { Modal } from "../Modal";

export interface EditWorkspaceProps extends ModalComponentProps {
  workspaceName?: string;
  onEditComplete?: (newTitle?: string) => void;
}

export const EditWorkspaceModal = (props: EditWorkspaceProps) => {
  const { show, onClose, effect, effectDuration } = props;
  const [workspaceName, setWorkspaceName] = useState(
    props.workspaceName ?? "No-name"
  );

  return (
    <Modal
      sideModal={false}
      title="Update Workspace"
      onClose={onClose}
      show={show}
      effect={effect}
      effectDuration={effectDuration}
    >
      <div className="mx-32 my-8 text-gray-900">
        <TextInput
          autoFocus={true}
          onKeyDown={(e) => {
            if (e.key === "Enter" && workspaceName.trim().length > 0) {
              props.onEditComplete?.(workspaceName);
              props.onClose?.();
            }
          }}
          label="Workspace Name"
          value={workspaceName}
          onChange={(e) => setWorkspaceName(e.target.value)}
        />

        <div className="mt-8 flex">
          <Button
            className={`${
              workspaceName.trim().length > 0
                ? ""
                : "bg-gray-700 border-gray-700 hover:bg-gray-700 hover:border-gray-700"
            }`}
            disabled={workspaceName.trim().length <= 0}
            onClick={() => {
              props.onEditComplete?.(workspaceName);
              props.onClose?.();
            }}
            data-testid="wk-upd-btn"
          >
            Update Workspace
          </Button>
          <Button
            variant="secondary"
            className="ml-2"
            onClick={() => onClose?.()}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
