interface LoaderProps {
  /**
     Default = 2rem
    */
  size?: string;
  className?: string;
}

/*
 * Original styles
  border: 5px solid #f3f3f3; Light grey
  border-top: 5px solid var(--themecolor); // Blue
  border-radius: 50%;
  animation: spin 2s linear infinite;
  width: 25px;
  height: 25px;
  margin: auto;
*/
export const Loader = (props: LoaderProps) => {
  return (
    <div
      title="Please wait.."
      style={{ width: props.size ?? "10rem", height: props.size ?? "2rem" }}
      className={`${props.className} animate-spin border-8 border-gray-300 border-t-blue-800 rounded-full opacity-75 m-auto`}
    ></div>
  );
};

export default Loader;
