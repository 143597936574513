import { Configuration, SilentRequest } from "@azure/msal-browser";

export const msalConfiguration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID ?? "",
    authority: process.env.REACT_APP_MSAL_AUTHORITY,
  },
};

export const msalRequest: SilentRequest = {
  scopes:
    process.env.REACT_APP_ENVIRONMENT === "test"
      ? ["api://onto.grundfos.com/t/oeapi/.default"]
      : process.env.REACT_APP_ENVIRONMENT === "production"
      ? ["api://onto.grundfos.com/p/oeapi/.default"]
      : ["api://onto.grundfos.com/s/oeapi/.default"],
};
