import { useEffect, useRef, useState } from "react";
import { useNotifications } from "../../../hooks/useNotifications/useNotifications";
import { Notification } from "./../notification/Notification";

export const NotificationList = () => {
  const { notifications, clear } = useNotifications();
  const intervalRef = useRef<NodeJS.Timer>();
  const [expiredIds, setExpiredIds] = useState<number[]>([]);

  useEffect(() => {
    if (!notifications.length) {
      if (intervalRef.current)
        clearInterval(intervalRef.current as NodeJS.Timer);
      return;
    }

    intervalRef.current = setInterval(() => {
      const expiredNotifications = notifications.filter((x) => {
        const compareDate = new Date(x.created);
        compareDate.setMilliseconds(compareDate.getMilliseconds() + x.delay);
        return compareDate < new Date();
      });

      expiredNotifications.forEach((x) => {
        if (!expiredIds.includes(x.id)) {
          setExpiredIds((prev) => [x.id, ...prev]);
        }
      });
    }, 100);

    return () => clearInterval(intervalRef.current as NodeJS.Timer);
  }, [clear, expiredIds, notifications]);

  return (
    <div className="absolute right-4 top-1 z-40">
      {notifications.map((notification) => (
        <Notification
          id={notification.id}
          key={notification.id}
          message={notification.message}
          variant={notification.variant}
          onClose={() => clear(notification.id)}
          expiredNotifications={expiredIds}
          expiredCallBack={() =>
            setExpiredIds((prev) => prev.filter((x) => x !== notification.id))
          }
          className="my-4"
        />
      ))}
    </div>
  );
};
