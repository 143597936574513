import { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  ApprovalStatus,
  ModelStatus,
  SourceTableColumnType,
  SourceTableDataType,
} from "../../../../api/apiClient";
import { useInvalidSourceTables } from "../../../../hooks/useInvalidSourceTables/useInvalidSourceTables";
import { useWorkspaces } from "../../../../hooks/useWorkspaces/useWorkspaces";
import { SearchTermState } from "../../../../state/atoms/searchTermState";
import { GovernanceColumns } from "../../../../utils/types/governanceColumns";
import { DecimalValidator } from "../../../../utils/validators/DecimalValidator";
import {
  IRIValidator,
  isIRIType,
} from "../../../../utils/validators/IRIValidator";
import { IntegerValidator } from "../../../../utils/validators/IntegerValidator";
import { EditorTableCellProps } from "./EditorTableCell.props";

export const EditorTableCell = (props: EditorTableCellProps) => {
  const {
    cell,
    column,
    className,
    readOnlyMode,
    labelValue,
    workspaceId,
    sourceTableName,
    rowId,
    isExcluded,
    isExternal,
    onCellChanged,
  } = props;
  const [cellValue, setCellValue] = useState(cell.value);
  const {
    invalidSourceTables,
    unknownIris,
    updateInvalidSourceTables,
    updateUnknownIris,
  } = useInvalidSourceTables();
  const [searchTerm] = useRecoilState(SearchTermState);
  const [hasSearchTerm, setHasSearchTerm] = useState(false);
  const { IriExistsInWorkspace } = useWorkspaces();
  const [statusDescription, setStatusDescription] = useState<string>();
  const [initialStatusValue, setInitialStatusValue] = useState<string>();

  const approvalStatusOptions = Object.keys(ApprovalStatus);
  const modelStatusOptions = Object.keys(ModelStatus);

  const cellIdentifier = workspaceId + sourceTableName + rowId + column.id;

  const isValid = useCallback(
    (value: string) => {
      let values: string[] = [];
      if (column.columnType === SourceTableColumnType.List) {
        values = value.split(",").map((v) => v.trim());
      } else {
        values = [value];
      }

      const isValid = values.every(
        (value) =>
          (values.length > 1 ? value.length > 0 : true) &&
          IRIValidator(value, column.dataType) &&
          IntegerValidator(value, column.dataType) &&
          DecimalValidator(value, column.dataType)
      );

      if (!isValid) {
        if (!invalidSourceTables.some((e) => e.includes(cellIdentifier))) {
          updateInvalidSourceTables([cellIdentifier, ...invalidSourceTables]);
        }
        return false;
      }

      if (invalidSourceTables.some((e) => e.includes(cellIdentifier))) {
        updateInvalidSourceTables(
          invalidSourceTables.filter((e) => !e.includes(cellIdentifier))
        );
      }
      return true;
    },
    [
      cellIdentifier,
      column.columnType,
      column.dataType,
      invalidSourceTables,
      updateInvalidSourceTables,
    ]
  );

  const containsUnknownIRI = useCallback(
    async (value: string) => {
      let values: string[] = [];
      if (column.columnType === SourceTableColumnType.List) {
        values = value.split(",").map((v) => v.trim());
      } else {
        values = [value];
      }

      let existenceChecks = Array<Promise<boolean>>();
      values.forEach((iri) => {
        if (iri.length > 0 && isIRIType(column.dataType)) {
          existenceChecks = [
            ...existenceChecks,
            IriExistsInWorkspace(workspaceId, iri),
          ];
        }
      });

      const isKnown = (await Promise.all(existenceChecks)).every(
        (known) => known
      );

      if (!isKnown) {
        if (!unknownIris.some((e) => e.includes(cellIdentifier))) {
          updateUnknownIris([cellIdentifier, ...unknownIris]);
        }
        return false;
      }

      if (unknownIris.some((e) => e.includes(cellIdentifier))) {
        updateUnknownIris(
          unknownIris.filter((e) => !e.includes(cellIdentifier))
        );
      }
      return true;
    },
    [
      IriExistsInWorkspace,
      cellIdentifier,
      column.columnType,
      column.dataType,
      unknownIris,
      updateUnknownIris,
      workspaceId,
    ]
  );

  const invalidReason = () => {
    if (isExcluded) {
      return "Row excluded from build.\nInclude in build by selecting row and pressing 'Include In Build'.";
    }

    switch (column.dataType) {
      case SourceTableDataType.OwlClass:
      case SourceTableDataType.OwlObjectProperty:
      case SourceTableDataType.OwlDataTypeProperty:
      case SourceTableDataType.OwlNamedIndividual:
      case SourceTableDataType.OwlAnnotationProperty:
      case SourceTableDataType.RdfsDatatype:
      case SourceTableDataType.OttrIRI:
        return "Column should contain IRI value.\nValue should not contain whitespace.\nIt is not recommended to manually input this value";
      case SourceTableDataType.XsdInt:
      case SourceTableDataType.XsdInteger:
      case SourceTableDataType.XsdShort:
      case SourceTableDataType.XsdLong:
        return "Column contains Integer type.\nValue should be a whole number.";
      case SourceTableDataType.XsdDecimal:
      case SourceTableDataType.XsdFloat:
        return "Column contains Decimal type.\nValue should only contain numbers or decimal numbers";
      default:
        return "Error in input.";
    }
  };

  const addSpaceAfterComma = (value: string) => {
    const splits = value.split(",");

    const newSplits = splits.map((s, index) => {
      s = s.trim();
      if (index > 0) {
        s = " " + s;
      }

      return s;
    });

    return newSplits.join(",");
  };

  const handleBlur = (value: string) => {
    if (value !== cell.value) {
      let formattedValue = value.replace(/(\r\n|\n|\r|\s+)/gm, " ").trim();
      if (column.columnType === SourceTableColumnType.List) {
        // remove trailing comma and whitespace
        while (formattedValue.slice(-1) === ",") {
          formattedValue = formattedValue.slice(0, -1).trimEnd();
        }
        // ensure space after comma (for readability)
        formattedValue = addSpaceAfterComma(formattedValue);
      }
      setCellValue(formattedValue);
      isValid(formattedValue);
      containsUnknownIRI(formattedValue);
      onCellChanged?.(formattedValue, cell.row.original, column.id);
    }
  };

  useEffect(() => {
    if (searchTerm && cellValue) {
      const value = (cellValue as string).toLowerCase();
      if (value.includes(searchTerm.toLowerCase())) {
        setHasSearchTerm(true);
      } else {
        setHasSearchTerm(false);
      }
    } else {
      setHasSearchTerm(false);
    }
  }, [cellValue, hasSearchTerm, searchTerm]);

  useEffect(() => {
    setInitialStatusValue(cellValue);
    let val = cellValue as string;
    if (val && val.length > 0) {
      val = val.trim();
    }
    if (column.columnType === SourceTableColumnType.GovernanceApprovalStatus) {
      setInitialStatusValue(approvalStatusOptions[0]);

      if (val === "") {
        val = approvalStatusOptions[0];
      }

      switch (val) {
        case approvalStatusOptions[0]:
          setStatusDescription(
            "Information is typed in, impact analysis, information is approved"
          );
          break;
        case approvalStatusOptions[1]:
          setStatusDescription(
            "Consuming systems starts to implement new state, Information consequences in consuming systems are being implemented"
          );
          break;
        case approvalStatusOptions[2]:
          setStatusDescription("In general use");
          break;
        case approvalStatusOptions[3]:
          setStatusDescription(
            "Reevaluate if the information is still valid to use (if not, go to Define)"
          );
          break;
        case approvalStatusOptions[4]:
          setStatusDescription("Do not use");
          break;
        default:
          setStatusDescription("");
          break;
      }
    }

    if (column.columnType === SourceTableColumnType.GovernanceModelStatus) {
      setInitialStatusValue(modelStatusOptions[0]);

      if (val === "") {
        val = modelStatusOptions[0];
      }

      switch (val) {
        case modelStatusOptions[0]:
          setStatusDescription(
            "This stage is where the development of the ontology patterns takes place."
          );
          break;
        case modelStatusOptions[1]:
          setStatusDescription(
            "Modelling finished, selected trusted users are testing if the modelling works."
          );
          break;
        case modelStatusOptions[2]:
          setStatusDescription("Modelling finished and approved.");
          break;
        case modelStatusOptions[3]:
          setStatusDescription(
            "It is not used any more, it has been superseded by a new model, but there was a time when it was correct."
          );
          break;
        case modelStatusOptions[4]:
          setStatusDescription("Has been withdrawn because it was wrong.");
          break;
      }
    }
  }, [approvalStatusOptions, cellValue, column.columnType, modelStatusOptions]);

  if (column.readOnly || readOnlyMode) {
    return (
      <textarea
        title={
          isExcluded
            ? "Row excluded from build.\nInclude in build by selecting row and pressing 'Include In Build'."
            : labelValue
            ? labelValue
            : ""
        }
        defaultValue={initialStatusValue}
        value={initialStatusValue}
        className={`h-full px-2 -mx-2 py-1 resize-none overflow-hidden text-gray-700 ${
          hasSearchTerm ? "bg-blue-700 bg-opacity-20" : "bg-transparent"
        }  ${className}`}
      />
    );
  }

  if (
    column.columnType === SourceTableColumnType.GovernanceModelStatus ||
    column.columnType === SourceTableColumnType.GovernanceApprovalStatus ||
    (column.id === GovernanceColumns.ModelStatus && isExternal)
  ) {
    return (
      <div
        className={`w-full h-full ${
          hasSearchTerm ? "bg-blue-700 bg-opacity-20" : "bg-transparent"
        }`}
      >
        <select
          title={statusDescription}
          className="outline-none w-full bg-transparent dark:text-gray-600 focus:dark:bg-darkSecondary"
          value={cellValue}
          onChange={(e) => {
            setCellValue(e.target.value);
            onCellChanged?.(e.target.value, cell.row.original, column.id);
          }}
        >
          {column.columnType === SourceTableColumnType.GovernanceApprovalStatus
            ? approvalStatusOptions.map((option) => (
                <option key={option}>{option}</option>
              ))
            : modelStatusOptions.map((option) => (
                <option key={option}>{option}</option>
              ))}
        </select>
      </div>
    );
  }
  return (
    <textarea
      title={
        !invalidSourceTables.some((e) => e.includes(cellIdentifier))
          ? !unknownIris.some((e) => e.includes(cellIdentifier))
            ? labelValue
              ? labelValue
              : ""
            : "Cell contains unknown IRI"
          : invalidReason()
      }
      defaultValue={cellValue}
      className={`h-full px-2 -mx-2 py-1 dark:my-[2px] my-[1px] focus:outline-1 outline-blue-700 dark:outline-gray-800 resize-none overflow-hidden ${className} ${
        hasSearchTerm ? "bg-blue-700 bg-opacity-20" : "bg-transparent"
      }
      ${
        !invalidSourceTables.some((e) => e.includes(cellIdentifier))
          ? !unknownIris.some((e) => e.includes(cellIdentifier))
            ? ""
            : "text-yellow-900 bg-yellow-900 bg-opacity-10"
          : "text-red-700 bg-red-750 bg-opacity-10"
      }
      `}
      onBlur={(e) => handleBlur(e.target.value)}
    />
  );
};
