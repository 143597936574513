import { AccountCircle } from "@mui/icons-material";
import { Breadcrumbs } from "@mui/material";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { GrundfosLogo } from "../../assets/images";
import { useContextMenu } from "../../hooks/useContextMenu/useContextMenu";
import { workspacesState } from "../../state/atoms/workspacesState";
import { useRecoilValueOrDefault } from "../../state/recoil";
import { AccountContextMenu } from "../form/accountContextMenu/AccountContextMenu";
import { NavbarProps } from "./Navbar.props";

export const Navbar = (props: NavbarProps) => {
  const { openContextMenu } = useContextMenu();
  const currentPath = window.location.pathname;
  const workspaces = useRecoilValueOrDefault(workspacesState, []);
  const [workspaceName, setWorkspaceName] = useState<string | undefined>();

  const workspacesLink = () => (
    <Link to="/workspaces">
      <p className="text-white hover:underline">Workspaces</p>
    </Link>
  );

  const isInWorkspace = useMemo(() => {
    const pathElements = currentPath.split("/");
    if (pathElements.length === 4 && pathElements[1] === "editor") {
      const workspaceName =
        workspaces.find((w) => w.id === pathElements[2])?.title ?? undefined;
      setWorkspaceName(workspaceName);
      return true;
    }

    setWorkspaceName(undefined);
    return false;
  }, [currentPath, workspaces]);

  return (
    <div className="text-white flex justify-between items-center bg-blue-800 h-12 px-4 flex-shrink-0">
      <div className="flex gap-5">
        <Link to="/">
          <GrundfosLogo />
        </Link>
        <Breadcrumbs color={"white"}>
          <Link to="/">
            <p className="text-white hover:underline">Ontologies</p>
          </Link>
          {(currentPath.includes("/workspaces") ||
            currentPath.includes("/editor")) &&
            workspacesLink()}
          {isInWorkspace && workspaceName && (
            <p className="text-gray-700">{workspaceName}</p>
          )}
        </Breadcrumbs>
      </div>
      <button
        className="flex items-center"
        onClick={() =>
          openContextMenu({
            variant: "accountContext",
            posY: "40px",
            posX: window.innerWidth - 220 + "px",
            menu: <AccountContextMenu />,
          })
        }
      >
        <span data-testid="username" className="text-sm mr-2">
          {props.username}
        </span>
        <AccountCircle data-testid="avatar" />
      </button>
    </div>
  );
};
