import {
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
} from "@azure/msal-browser";
import {
  useAccount,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import { useEffect } from "react";
import { msalRequest } from "../../utils/msal/msalConfiguration";

export const useAuthentication = () => {
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount();
  const { accounts, instance, inProgress } = useMsal();
  const { login, error } = useMsalAuthentication(
    InteractionType.Redirect,
    msalRequest
  );

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Redirect, msalRequest);
    }
  }, [error, login]);

  useEffect(() => {
    instance.setActiveAccount(accounts[0]);
  }, [accounts, instance]);

  return {
    isAuthenticated,
    account,
    error,
    loading: inProgress !== InteractionStatus.None,
  };
};
