import CircularProgress from "@mui/material/CircularProgress";
import { format } from "date-fns";
import { ExternalTableMetaData } from "../../../api/apiClient";

type Props = {
  externalMetadata?: ExternalTableMetaData;
  isLoading: boolean;
};

export const MetadataPanel = ({ externalMetadata, isLoading }: Props) => {
  if (isLoading || externalMetadata === undefined) {
    return (
      <div className="p-2 flex justify-between gap-3 items-center dark:text-white font-bold bg-gray-500 dark:bg-gray-750">
        <p>Loading MetaData</p>
        <div className="pt-1.5">
          <CircularProgress size={15} color="inherit" />
        </div>
      </div>
    );
  }

  if (
    !isLoading &&
    externalMetadata !== undefined &&
    externalMetadata.lastRefreshTimestamp !== undefined
  ) {
    return (
      <div className="p-2 px-3 bg-gray-500 dark:bg-gray-750 dark:text-white max-w-[1000px]">
        <div className="flex justify-between">
          <p className="capitalize font-bold">query</p>
          {format(externalMetadata.lastRefreshTimestamp, "dd/MM HH:mm:ss")}
        </div>
        {externalMetadata!.dataQuery}
      </div>
    );
  }

  return null;
};
