import { MsalProvider } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import "./index.css";
import { App } from "./pages/app/App";
import reportWebVitals from "./reportWebVitals";
import "./utils/fonts";
import { msalInstance } from "./utils/msal/msalApplication";

Sentry.init({
  dsn: "https://a0af121bda9341ca888d1a13feba5285@o1240104.ingest.sentry.io/6392025",
  enabled: process.env.REACT_APP_ENVIRONMENT === "production",
});

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <RecoilRoot>
          <Sentry.ErrorBoundary>
            <App />
          </Sentry.ErrorBoundary>
        </RecoilRoot>
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
