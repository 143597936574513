import { DarkMode, LightMode } from "@mui/icons-material";
import { Switch } from "@mui/material";
import { useState } from "react";
import { useChangeNote } from "../../../hooks/useChangeNote/useChangeNote";
import { useTheme } from "../../../hooks/useTheme/useTheme";
import { ModalComponentProps } from "../../../utils/types/modal";
import { Button } from "../../form";
import { TextInput } from "../../form/inputboxes/inputbox/TextInput";
import { Modal } from "../Modal";

export const SettingsModal = (props: ModalComponentProps) => {
  const { show, onClose, effect, effectDuration } = props;
  const { changeNote, updateChangeNote } = useChangeNote();
  const [tempChangeNote, setTempChangeNote] = useState(changeNote);
  const { theme, toggleTheme } = useTheme();

  return (
    <Modal
      sideModal={false}
      title="Settings"
      onClose={onClose}
      show={show}
      effect={effect}
      effectDuration={effectDuration}
    >
      <div className="mx-32 my-8 text-gray-900">
        <div className="flex justify-center gap-4">
          <DarkMode
            data-testid="darkModeIcon"
            cursor={"pointer"}
            onClick={() => {
              if (theme === "light") {
                toggleTheme();
              }
            }}
            className={`${
              theme === "light" ? "text-gray-700" : "text-blue-700"
            }`}
            fontSize="large"
          />
          <Switch
            onMouseDown={() => toggleTheme()}
            color="primary"
            checked={theme === "light"}
          />
          <LightMode
            data-testid="lightModeIcon"
            cursor={"pointer"}
            onClick={() => {
              if (theme === "dark") {
                toggleTheme();
              }
            }}
            className={`${
              theme === "dark" ? "text-gray-700" : "text-blue-700"
            }`}
            fontSize="large"
          />
        </div>
        <hr className="my-8 text-gray-600 dark:text-gray-750" />
        <TextInput
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              updateChangeNote(tempChangeNote);
              onClose?.();
            }
          }}
          label="Change Note"
          value={tempChangeNote}
          onChange={(e) => setTempChangeNote(e.target.value)}
        />
        <hr className="my-8 text-gray-600 dark:text-gray-750" />
        <div className="mt-8 flex">
          <Button
            onClick={() => {
              updateChangeNote(tempChangeNote);
              onClose?.();
            }}
          >
            Save Settings
          </Button>
          <Button
            variant="secondary"
            className="ml-2"
            onClick={() => onClose?.()}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
