import { LockOpen } from "@mui/icons-material";
import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { Workspace, WorkspaceState } from "../../../api/apiClient";
import { useDialog } from "../../../hooks/useDialog/useDialog";
import { ReadOnlyWorkspaceReason } from "../../../utils/types/readOnlyWorkspaceReason";

export interface InfoPanelProps {
  workspace: Workspace;
  unlockWorkspace: (workspaceId: string) => Promise<boolean>;
}

export const InfoPanel = (props: InfoPanelProps) => {
  const { unlockWorkspace, workspace } = props;
  const { openDialog, closeDialog } = useDialog();

  const readOnlyReason =
    workspace.state === WorkspaceState.Draft
      ? ReadOnlyWorkspaceReason.Unauthorized
      : ReadOnlyWorkspaceReason.Published;

  const handleUnlock = async () => {
    const success = await unlockWorkspace(workspace.id);
    if (success) {
      window.location.reload();
    }
  };

  useEffect(() => {
    // delay to allow table to fully render before rebuilding tooltip
    setTimeout(() => ReactTooltip.rebuild(), 500);
  }, []);

  return (
    <div className="px-2 py-1 text-white bg-blue-750 rounded-b-md">
      {readOnlyReason === ReadOnlyWorkspaceReason.Published && (
        <div className="flex gap-1.5 capitalize">
          <p>Published workspace is Read Only</p>
          <button
            data-place="bottom"
            data-tip="Press to unlock workspace,<br> this will abandon the pull request currently in review."
            className="flex align-top"
            onClick={() =>
              openDialog({
                variant: "confirm",
                primaryButtonText: "Unlock",
                secondaryButtonText: "Cancel",
                text: "Are you sure you want to unlock this workspace? This will abandon the Publish action currently in review for this workspace.",
                onPrimaryButtonClick: () => handleUnlock(),
                onSecondaryButtonClick: () => closeDialog(),
              })
            }
          >
            <LockOpen fontSize="small" />
          </button>
        </div>
      )}
      {readOnlyReason !== ReadOnlyWorkspaceReason.Published &&
        readOnlyReason === ReadOnlyWorkspaceReason.Unauthorized && (
          <div>
            <p className="capitalize">
              Workspace is in Read Only mode - Read Only User {readOnlyReason}
            </p>
          </div>
        )}
    </div>
  );
};
