import { SourceTableDataType } from "../../api/apiClient";
import { useWorkspaces } from "../../hooks/useWorkspaces/useWorkspaces";

export const isIRIType = (dataType?: SourceTableDataType) => {
  switch (dataType) {
    case SourceTableDataType.OwlClass:
    case SourceTableDataType.OwlObjectProperty:
    case SourceTableDataType.OwlDataTypeProperty:
    case SourceTableDataType.OwlNamedIndividual:
    case SourceTableDataType.OwlAnnotationProperty:
    case SourceTableDataType.RdfsDatatype:
    case SourceTableDataType.OttrIRI:
      return true;

    default:
      return false;
  }
};

export const IRIValidator = (input: string, dataType?: SourceTableDataType) => {
  if (isIRIType(dataType) && input.includes(" ")) {
    return false;
  }

  return true;
};

export const IRIExtistenceValidator = (
  workspaceId: string,
  iri: string,
  dataType: SourceTableDataType
) => {
  const { IriExistsInWorkspace } = useWorkspaces();
  if (isIRIType(dataType) && !IriExistsInWorkspace(workspaceId, iri)) {
    return true;
  }

  return false;
};
